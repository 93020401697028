const { Duration, DateTime } = require('luxon');

exports.localizeDateTimeline = (ts, loc) => {
  const localDate = DateTime.fromMillis(Math.floor(ts)).setLocale(loc);
  return { date: localDate.toFormat('dd MMM'), year: localDate.toFormat('yyyy') };
};
exports.clusterInterval = (ms, loc) => {
  const absMs = Math.abs(ms)
  let duration = Duration.fromObject({ days: 0,hours: 0, minutes: 0, seconds: 0, milliseconds: absMs, locale: loc }).normalize();

  let text = '';
  let desc = '';
  let obj = {};
  switch (loc) {
    case 'ru':
      if (duration.as('milliseconds') === 0) {
      } else {
        if (duration.as('seconds') < 1) {
          text = 'менее 1 сек';
          desc = duration.as('milliseconds') * 1e3 + ' мкс';
          obj['milliseconds'] = duration.as('milliseconds');
        } else {
          if (duration.seconds) {
            text = duration.seconds + ((Math.floor(duration.milliseconds / 1e2) > 0) ? ('.'+ Math.floor(duration.milliseconds / 1e2)) : '') + ' сек';
            obj['microseconds'] = duration.milliseconds * 1e3;
            obj['seconds'] = duration.seconds;
          }
          if (duration.minutes) {
            text = duration.minutes + ' мин ' + text;
            obj['minutes'] = duration.minutes;
          }
          if (duration.hours) {
            text = duration.hours + ' ч ' + text;
            obj['hours'] = duration.hours;
          }
          if (duration.days) {
            text = duration.days + ' дн. ' + text;
            obj['days'] = duration.days;
          }
          desc = duration.toFormat('hh:mm:ss') + '.' + duration.milliseconds * 1e3;
        }
      }
      break;
    case 'en':
      if (duration.as('milliseconds') === 0) {
      } else {
        if (duration.as('seconds') < 1) {
          text = 'less than 1 s';
          desc = duration.as('milliseconds') * 1e3 + ' mcs';
          obj['milliseconds'] = duration.as('milliseconds');
        } else {
          if (duration.seconds) {
            text = duration.seconds + ((Math.floor(duration.milliseconds / 1e2) > 0) ? ('.'+ Math.floor(duration.milliseconds / 1e2)) : '') + ' s';
            obj['microseconds'] = duration.milliseconds * 1e3;
            obj['seconds'] = duration.seconds;
          }
          if (duration.minutes) {
            text = duration.minutes + ' m ' + text;
            obj['minutes'] = duration.minutes;
          }
          if (duration.hours) {
            text = duration.hours + ' h ' + text;
            obj['hours'] = duration.hours;
          }
          if (duration.days) {
            text = duration.days + ' day(s) ' + text;
            obj['days'] = duration.days;
          }
          desc = duration.toFormat('hh:mm:ss') + '.' + duration.milliseconds * 1e3;
        }
      }
      break;
    default:
  }
  return { text, desc, obj };
};
exports.clusterDuration = (ms, loc) => {
  const absMs = Math.abs(ms)
  let duration = Duration.fromObject({ hours: 0, minutes: 0, seconds: 0, milliseconds: absMs, locale: loc }).normalize();

  let text = '';
  let desc = '';
  let obj = {};
  switch (loc) {
    case 'ru':
      if (duration.as('milliseconds') === 0) {
      } else {
        if (duration.as('seconds') < 1) {
          text = 'менее 1 сек';
          desc = duration.as('milliseconds') * 1e3 + ' мкс';
          obj['milliseconds'] = duration.as('milliseconds');
        } else {
          if (duration.seconds) {
            text = duration.seconds + ((Math.floor(duration.milliseconds / 1e2) > 0) ? ('.'+ Math.floor(duration.milliseconds / 1e2)) : '') + ' сек';
            obj['microseconds'] = duration.milliseconds * 1e3;
            obj['seconds'] = duration.seconds;
          }
          if (duration.minutes) {
            text = duration.minutes + ' мин ' + text;
            obj['minutes'] = duration.minutes;
          }
          if (duration.hours) {
            text = duration.hours + ' ч ' + text;
            obj['hours'] = duration.hours;
          }
          desc = duration.toFormat('hh:mm:ss') + '.' + duration.milliseconds * 1e3;
        }
      }
      break;
    case 'en':
      if (duration.as('milliseconds') === 0) {
      } else {
        if (duration.as('seconds') < 1) {
          text = 'less than 1 s';
          desc = duration.as('milliseconds') * 1e3 + ' mcs';
          obj['milliseconds'] = duration.as('milliseconds');
        } else {
          if (duration.seconds) {
            text = duration.seconds + ((Math.floor(duration.milliseconds / 1e2) > 0) ? ('.'+ Math.floor(duration.milliseconds / 1e2)) : '') + ' s';
            obj['microseconds'] = duration.milliseconds * 1e3;
            obj['seconds'] = duration.seconds;
          }
          if (duration.minutes) {
            text = duration.minutes + ' m ' + text;
            obj['minutes'] = duration.minutes;
          }
          if (duration.hours) {
            text = duration.hours + ' h ' + text;
            obj['hours'] = duration.hours;
          }
          desc = duration.toFormat('hh:mm:ss') + '.' + duration.milliseconds * 1e3;
        }
      }
      break;
    default:
  }
  return { text, desc, obj };
};
