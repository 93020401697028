<template>
  <div id="clusters-table">
    <a href="?p=wheel" class="wh-back d-none"
      >{{ $t('Back to') }} <span class="fal fa-asterisk"></span> {{ $t('Chart') }}</a
    >
    <div class="wh-page">
      <h2 v-if="archive && !isNaN(archive.tstart) && !isNaN(archive.tend)" class="events-archive__title">
        {{ $t('Events log from $1 to $2', [`${nanoTstart}`, `${nanoTend}`]) }}
      </h2>

      <clusters-timeline
        v-if="tl"
        :fields="fields"
        :off-types="offTypes"
        :url="url"
        :length-menu="lengthMenu"
        :archive="archive"
        ref="eventsTable"
        @pageUpdate="pageChange"
      >
      </clusters-timeline>

      <clusters-table
        v-else
        :fields="fields"
        :off-types="offTypes"
        :url="url"
        :length-menu="lengthMenu"
        :archive="archive"
        ref="eventsTable"
        @pageUpdate="pageChange"
      >
      </clusters-table>
    </div>
  </div>
</template>

<script>
import ArchiveEventsModal from './ArchiveEventsModal';
import ExportEventsModal from './ExportEventsModal';
import DateNano from '../utils/format.date.time';
import ClustersTable from './DataTable/ClustersTable';
import ClustersTimeline from './DataTable/ClustersTimeline';

export default {
  name: 'clusters-page',
  components: { ClustersTable, ClustersTimeline },
  props: {
    page: { type: Number, default: 1 },
    archive: Boolean,
    tl: Boolean,
    eventsSearchStr: String,
  },
  data: () => {
    return {
      d: undefined,
      lang: undefined,
      v: false,
      vv: false,
      search: '',
      quickSearch: '',
      lengthMenu: [7, 17, 50, 100, 300, 500, 1000, 5000, 10000, 25000],
      offTypes: [],
    };
  },
  computed: {
    loc() {
      return this.$i18n.locale;
    },
    url() {
      return this.archive
        ? `/projects/${this.$tpws.projectName}/archive-clusters-exp?tstart=${this.nanoTstart}&tend=${this.archive.tend}`
        : `/projects/${this.$tpws.projectName}/clusters-exp`;
    },
    headerVisible() {
      return this.archive && !isNaN(this.archive.tstart) && !isNaN(this.archive.tend);
    },

    fields() {
      return [
        {
          label: '',
          name: 'clusterDT',
          data: 'clusterDT',
          visible: false,
        },
        {
          label: '',
          name: 'leftDT',
          data: 'leftDT',
          visible: false,
        },
        {
          label: this.$i18n.t('cluster-columns.Date & Time'),
          name: 'timeDT',
          data: 'timeDT',
          sortable: true,
          action: 'showProfile',
          visible: true,
        },
        {
          label: this.$i18n.t('cluster-columns.Event types'),
          name: 'evTypes',
          data: 'evTypes',
          render: (d) => {
            const typeTitles = {
              CM: 'Communication',
              CS: 'Cyber Security',
              CF: 'Configuration',
              PR: 'Process Event',
              TS: 'Time Synchronization',
              SE: 'Service Event',
            };
            let ht = [];
            d.forEach((el) => {
              ht.push(
                `<span title="${
                  typeTitles[el] ? this.$t(typeTitles[el]) : this.$t('Unknown Type')
                }" class="fal wh-event-type-${el.toLowerCase()}"></span>&nbsp;${this.$t(el)}`
              );
            });
            return ht.join(', ');
          },
          renderList: (d) => {
            const typeTitles = {
              CM: {
                title: 'CM',
                description: 'Communication',
                icon: 'types-cm-icon',
              },
              CS: {
                title: 'CS',
                description: 'Cyber Security',
                icon: 'types-cs-icon',
              },
              CF: {
                title: 'CF',
                description: 'Configuration',
                icon: 'types-cf-icon',
              },
              PR: {
                title: 'PR',
                description: 'Process Event',
                icon: 'types-pr-icon',
              },
              TS: {
                title: 'TS',
                description: 'Time Synchronization',
                icon: 'types-ts-icon',
              },
              SE: {
                title: 'SE',
                description: 'Service Event',
                icon: 'types-se-icon',
              },
            };
            let ht = [];
            d.forEach((el) => {
              ht.push({
                title: this.$t('type.title.' + typeTitles[el].title),
                description: this.$t('type.description.' + typeTitles[el].description),
                icon: typeTitles[el].icon,
              });
            });
            return ht;
          },

          sortable: true,
        },
        {
          label: this.$i18n.t('cluster-columns.Device names'),
          name: 'evNames',
          data: 'evNames',
          render: (d) => {
            return d.join(', ');
          },
          renderList: (d) => {
            return d;
          },
          sortable: true,
        },
        {
          label: '',
          name: 'devCount',
          data: 'devCount',
          visible: false,
        },
        {
          label: this.$i18n.t('cluster-columns.Cluster Name'),
          name: 'nameDT',
          data: 'nameDT',
          action: 'showProfile',
          sortable: true,
        },
        {
          label: this.$i18n.t('cluster-columns.Count'),
          name: 'evCount',
          sortable: true,
          action: 'showEvents',
          render: (d, r) => {
            return r.filteredEvCount + (r.evCount !== r.filteredEvCount ? ' (' + r.evCount + ')' : '');
          },
        },
      ];
    },

    nanoTstart() {
      return new DateNano(this.archive.tstart).customFormat();
    },
    nanoTend() {
      return new DateNano(this.archive.tend).customFormat();
    },
    doneTodosCount() {
      return this.$store.state.todos.filter((todo) => todo.done).length;
    },
  },

  mounted() {
    // console.log('$main', JSON.stringify(this.$main));
    // console.log('$tpws', this.$tpws);
    // // console.log('main', window.main);
    // // console.log('main', main);
    // console.log('process.env.NODE_ENV', process.env.NODE_ENV);
    window.addEventListener('user', () => {
      console.log('ev:user');
      this.user = this.$main.user;
    });

    console.log(this.$route.query.search);

    // document.onreadystatechange = () => {
    //   if (document.readyState === 'complete') {
    //     this.$main.setDatatimePicker('.archive-events-modal');
    //     this.$main.setDatatimePicker('.export-events-modal');
    //
    //     // this.lang = this.$main.dataTablesTranslate(.user.language);
    //     // this.v = true;
    //   }
    // };
    this.$nextTick(() => {});
    try {
      let onTypes = this.eventTypes && this.eventTypes.map((e) => e.name);
      let ss = this.$route.query;
      let ssArr = this.$qs.parse(ss);
      let types = ssArr['search']
        .split(',')
        .filter((e) => e.indexOf('!') > -1)
        .map((e) => e.substring(1));
      console.log('ss', ssArr, onTypes);
      onTypes.forEach((el) => {
        if (types.indexOf(el) < 0) {
          this.onTypes.push(el);
        }
      });
    } catch (e) {
      this.onTypes = this.eventTypes && this.eventTypes.map((e) => e.name);
    }

    //
    this.offTypes =
      this.eventTypes && this.eventTypes.filter((e) => this.onTypes.indexOf(e.name) < 0).map((e) => e.name);
  },
  methods: {
    pageChange() {
      let page = this.$refs.eventsTable.currentPage,
        order = this.$refs.eventsTable.order,
        search = this.$refs.eventsTable.search.value;
      history.pushState(
        {
          page: page,
          order: order,
          search: this.$qs.stringify(search),
        },
        `page ${page}`,
        `?p=${
          this.archive ? 'archive' : this.tl ? 'events-exp-tl' : 'events-exp'
        }&page=${page}&search=${search}&column=${order['column']}&dir=${order['dir']}`
      );
    },
  },
};
</script>

<style>
.tl-layout {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.tl-sidebar {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 240px;
  -ms-flex: 0 0 240px;
  flex: 0 0 240px;
  padding-top: 20px;
  padding-right: 20px;
}

.tl-main {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
</style>
<style lang="scss"></style>
