<template>
  <div id="events-table">
    <a href="?p=wheel" class="wh-back d-none"
      >{{ $t('Back to') }} <span class="fal fa-asterisk"></span> {{ $t('Chart') }}</a
    >
    <div class="wh-page">
      <h2 v-if="archive && !isNaN(archive.tstart) && !isNaN(archive.tend)" class="events-archive__title">
        {{ $t('Events log from $1 to $2', [`${nanoTstart}`, `${nanoTend}`]) }}
      </h2>
      <events-table
        v-if="visibleTable"
        :fields="fields"
        :off-types="offTypes"
        :url="url"
        :length-menu="lengthMenu"
        :archive="archive"
        ref="eventsTable"
        @pageUpdate="pageChange"
      >
        <template slot="filters"> </template>
      </events-table>
    </div>
  </div>
</template>

<script>
import ArchiveEventsModal from './ArchiveEventsModal';
import ExportEventsModal from './ExportEventsModal';
import DateNano from '../utils/format.date.time';
import EventsTable from './DataTable/EventsTable';

export default {
  name: 'events-page',
  components: { EventsTable },
  props: {
    page: { type: Number, default: 1 },
    archive: Boolean,
    eventsSearchStr: String,
  },
  data: () => {
    return {
      d: undefined,
      lang: undefined,
      v: false,
      vv: false,
      search: '',
      quickSearch: '',
      lengthMenu: [7, 17, 50, 100, 300, 500, 1000, 5000, 10000, 25000],
      visibleTable: true,
    };
  },
  computed: {
    url() {
      return this.archive
        ? `/projects/${this.$tpws.projectName}/archive?tstart=${this.nanoTstart}&tend=${this.archive.tend}`
        : `/projects/${this.$tpws.projectName}/events2`;
    },
    // _eq() {
    //   return {
    //     GO: this.$i18n.t('Goose'),
    //     SV: this.$i18n.t('SV'),
    //     SRV: this.$i18n.t('Service events'),
    //     ARP: this.$i18n.t('ARP'),
    //     MMS: this.$i18n.t('MMS'),
    //     USER: this.$i18n.t('User events'),
    //   };
    // },
    //
    // __eq() {
    //   let eq = {};
    //   for (let t in this._eq) {
    //     eq[this._eq[t]] = t;
    //   }
    //   return eq;
    // },
    headerVisible() {
      return this.archive && !isNaN(this.archive.tstart) && !isNaN(this.archive.tend);
    },

    fields() {
      return [
        {
          label: this.$i18n.t('Date & Time'),
          name: 'timeDT',
          data: 'timeDT',
          sortable: true,
        },
        {
          label: this.$i18n.t('Type'),
          name: 'typeDT',
          data: 'typeDT',
          sortable: true,
        },
        {
          label: this.$i18n.t('IED'),
          name: 'iedNameDT',
          data: 'iedNameDT',
          sortable: true,
        },
        {
          label: this.$i18n.t('Control Block Name'),
          name: 'controlBlockDT',
          data: 'controlBlockDT',
          sortable: true,
        },
        {
          label: this.$i18n.t('Description'),
          name: 'descriptionsDT',
          data: 'descriptionsDT',
          sortable: true,
        },
      ];
    },

    nanoTstart() {
      return new DateNano(this.archive.tstart).customFormat();
    },
    nanoTend() {
      return new DateNano(this.archive.tend).customFormat();
    },
    doneTodosCount() {
      return this.$store.state.todos.filter((todo) => todo.done).length;
    },
  },

  mounted() {
    // console.log('$main', JSON.stringify(this.$main));
    // console.log('$tpws', this.$tpws);
    // // console.log('main', window.main);
    // // console.log('main', main);
    // console.log('process.env.NODE_ENV', process.env.NODE_ENV);
    window.addEventListener('user', () => {
      console.log('ev:user');
      this.user = this.$main.user;
    });

    console.log(this.$route.query.search);

    // document.onreadystatechange = () => {
    //   if (document.readyState === 'complete') {
    //     this.$main.setDatatimePicker('.archive-events-modal');
    //     this.$main.setDatatimePicker('.export-events-modal');
    //
    //     // this.lang = this.$main.dataTablesTranslate(.user.language);
    //     // this.v = true;
    //   }
    // };

    //
  },
  methods: {
    onTouchStart() {
      document.querySelector('body').style.overflow = 'hidden';
    },
    onTouchEnd() {
      document.querySelector('body').style.overflow = 'auto';
    },
    pageChange() {
      let page = this.$refs.eventsTable.currentPage,
        order = this.$refs.eventsTable.order,
        search = this.$refs.eventsTable.search.value;
      history.pushState(
        {
          page: page,
          order: order,
          search: this.$qs.stringify(search),
        },
        `page ${page}`,
        `?p=${this.archive ? 'archive' : 'events'}&page=${page}&search=${search}&column=${order['column']}&dir=${
          order['dir']
        }`
      );
    },
  },
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
