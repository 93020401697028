import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/projects/:projectId/prepared-reports',
      props: {
        cluster: '',
      },
      name: 'reports-page',
    },
    {
      path: '/projects/:projectId/clusters-index',
      props: {
        cluster: '',
      },
      name: 'clusters-index',
    },
    {
      path: '/projects/:projectId/clusters-profile',
      name: 'clusters-profile',
    },
    {
      path: '/projects/:projectId/space',
      name: 'project',
    },
  ],
});

router.beforeEach((to, from, next) => {
  console.log('beforeEach', to);
  localStorage.setItem('lastRoute', from.name);
  const lastRouteName = localStorage.getItem('lastRoute');

  const shouldRedirect = Boolean(to.name === 'home' && lastRouteName);

  if (shouldRedirect) next({ name: lastRouteName });
  else next();
});

router.afterEach((from) => {});

export default router;
