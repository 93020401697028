<template>
  <modal-dialog :modal="modal"> </modal-dialog>
</template>

<script>
import ModalDialog from './ModalDialog';
import RadioButtonGroup from './RadioButtonGroup';
import DatetimePeriodPicker from './DatetimePeriodPicker';

export default {
  name: 'export-events-modal',
  props: {},
  components: { ModalDialog, RadioButtonGroup, DatetimePeriodPicker },
  data: () => {
    return {
      modal: {
        name: 'export-events',
        title: 'Export',
        onSubmit: 'exportEvents()',
        submitBtn: 'OK',
      },
      timePresets: [
        { value: 'five-min', label: 'Last 5 minutes', active: true },
        { value: 'today', label: 'Today' },
        { value: 'this-week', label: 'This week' },
        { value: 'last-week', label: 'Last week' },
        { value: 'this-month', label: 'This month' },
        { value: 'last-month', label: 'Last month' },
        { value: 'custom-period', label: 'Set custom period' },
      ],
      modalText: 'Export events for selected time period',
    };
  },
  mounted() {},
  methods: {},
  watch: {},
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
