<template>
  <div class="d-none" :class="[parentElem + '__datetime-period-picker', 'datetime-period-picker']">
    <div v-for="row in rows" class="form-inline" :key="row.name">
      <div class="form-group datetime-period-picker__row">
        <label class="datetime-period-picker__col" :id="$t(row.label)">
          {{ $t(row.label) }}
        </label>
        <div class="datetime-period-picker__col">
          <input
            type="date"
            class="form-control datetime-period-picker__input"
            :class="parentElem + '__' + row.name + '-date-input'"
            :value="datetime[row.name]['date']"
            required
          />
        </div>
        <div class="datetime-period-picker__col">
          <input
            type="time"
            class="form-control datetime-period-picker__input"
            :class="parentElem + '__' + row.name + '-time-input'"
            step="1"
            :value="datetime[row.name]['time']"
            required
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'datetime-period-picker',
  props: ['parentElem', 'datetime'],
  components: {},
  data: () => {
    return {
      rows: [
        { label: 'From', name: 'start' },
        { label: 'To', name: 'end' },
      ],
    };
  },
  mounted() {},
  methods: {},
  watch: {},
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
