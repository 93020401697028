<template>
  <modal-dialog
    :modal="modal"
    v-show="visibleModal"
    :visible.sync="visibleModal"
    ref="mod"
    @closeModal="closeModal"
    @showArchive="showArchive"
  >
    <template slot="content">
      <p>
        {{ $t(modalText) }}
      </p>
      <radio-button-group
        :data.sync="timePresets"
        :parent-elem="modal.name + '-modal'"
        group-name="time-preset"
        @selected="selectPeriod"
      ></radio-button-group>
      <datetime-period-picker :parent-elem="modal.name + '-modal'" :datetime="dateTime"></datetime-period-picker>
      <div class="alert alert-danger d-none" :class="modal.name + '-modal__error'"></div>
    </template>
    <template slot="buttons">
      <button
        class="btn btn-secondary"
        type="button"
        data-dismiss="modal"
        aria-label="Cancel"
        @click="$refs.mod.$emit('closeModal')"
      >
        Отмена</button
      ><button
        class="btn btn-primary"
        :class="[modal.name + '__submit-btn']"
        :type="modal.typeSubmit ? 'submit' : 'button'"
        :aria-label="modal.submitBtn ? modal.submitBtn : 'Save'"
        :onclick="modal.onSubmit"
        @click="$refs.mod.$emit(modal.onSubmit)"
        :form="modal.typeSubmit ? `${modal.name}-modal__form` : ''"
      >
        {{ $t(modal.submitBtn ? modal.submitBtn : 'Save') }}
      </button>
    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from './ModalDialog';
import RadioButtonGroup from './RadioButtonGroup';
import DatetimePeriodPicker from './DatetimePeriodPicker';

export default {
  name: 'archive-events-modal',
  props: {
    visibleModal: {
      type: Boolean,
      default: false,
    },
    archive: {
      type: Boolean,
      default: false,
    },
  },
  components: { ModalDialog, RadioButtonGroup, DatetimePeriodPicker },
  data: () => {
    return {
      modal: {
        name: 'archive-events-vue',
        title: 'Archive',
        onSubmit: 'showArchive',
        submitBtn: 'OK',
      },
      timePresets: [
        { value: 'five-min', label: 'Last 5 minutes' },
        { value: 'today', label: 'Today' },
        { value: 'this-week', label: 'This week' },
        { value: 'last-week', label: 'Last week' },
        { value: 'this-month', label: 'This month' },
        { value: 'last-month', label: 'Last month' },
        { value: 'custom-period', label: 'Set custom period' },
      ],
      modalText: 'Show events log for selected period',
      dateTime: {},
    };
  },
  mounted() {},
  methods: {
    showArchive() {
      console.log('showArchive');
      this.$emit('update:archive', true);
    },
    closeModal() {
      console.log('closeModal');
      this.$emit('update:visibleModal', false);
    },
    selectPeriod(p) {
      console.log(p);
      this.timePresets
        .filter((e) => e.active)
        .forEach((el) => {
          let ell = el;
          let idx = this.timePresets.indexOf(el);
          delete ell['active'];
          // this.timePresets.$set(idx, ell);
          this.$set(this.timePresets, idx, ell);
        });
      console.log(this.timePresets.filter((e) => e.value === p.value));
      let el = this.timePresets.find((e) => e.value === p.value);
      let idx = this.timePresets.indexOf(el);

      el.active = true;
      // this.timePresets.$set(idx, el);
      this.$set(this.timePresets, idx, el);
      this.setTimePeriod(p.value);
    },
    setTimePeriod(value) {
      let now = new Date();
      let start = new Date();
      let end = new Date();
      switch (value) {
        case 'five-min':
          start.setMinutes(now.getMinutes() - 5);
          break;
        case 'today':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
          break;
        case 'this-week':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1);
          break;
        case 'last-week':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 6);
          end = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1);
          break;
        case 'this-month':
          start = new Date(now.getFullYear(), now.getMonth(), 1);
          break;
        case 'last-month':
          start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
          end = new Date(now.getFullYear(), now.getMonth(), 1);
          break;
        default:
          break;
      }
      this.dateTime = {
        start: {
          date: start.customFormat('#YYYY#-#MM#-#DD#'),
          time: start.customFormat('#hhhh#:#mm#:#ss#'),
        },
        end: {
          date: end.customFormat('#YYYY#-#MM#-#DD#'),
          time: end.customFormat('#hhhh#:#mm#:#ss#'),
        },
      };
    },
  },
  watch: {
    visibleModal: function (val) {
      console.log('visibleModal', val);
      if (!val) {
        this.$emit('update:visibleModal', val);
      }
    },
  },
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
