<template>
  <div class="tl-row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col text-left" v-html="renderData(rowData, getField(fields, 'timeDT'))"></div>
            <div class="col text-left">
              <template v-if="gap"
                ><span :title="$t('Prior cluster interval')"
                  ><i class="fal fa-arrow-up" aria-hidden="true"></i>&nbsp;{{ gap }}</span
                ></template
              >
            </div>
            <div class="col text-left">
              <template v-if="len"
                ><span :title="$t('Cluster duration')"
                  ><i class="fal fa-arrows-v" aria-hidden="true"></i>&nbsp;{{ len }}</span
                ></template
              >
            </div>
            <div class="col text-right">
              <a class="text-info pointer" @click="execAction(getField(fields, 'nameDT').action, $event, rowData)">{{
                $t('Name') + ': ' + renderData(rowData, getField(fields, 'nameDT'))
              }}</a>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div
              class="col text-left"
              v-html="$t('Types') + ': ' + renderData(rowData, getField(fields, 'evTypes'))"
            ></div>
            <div
              v-if="renderData(rowData, getField(fields, 'devCount')) > 0"
              class="col text-left"
              v-html="
                $t('Devices') +
                ': ' +
                renderData(rowData, getField(fields, 'evNames')) +
                (renderData(rowData, getField(fields, 'devCount')) > 1
                  ? '&nbsp;<span class=\'badge badge-light bot\'>' +
                    renderData(rowData, getField(fields, 'devCount')) +
                    '</span>'
                  : '')
              "
            ></div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col text-left"></div>
            <div class="col text-right">
              <a class="text-info pointer" @click="execAction(getField(fields, 'evCount').action, $event, rowData)">{{
                $t('Events') + ': ' + renderData(rowData, getField(fields, 'evCount'))
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'timeline-row',
  props: {
    rowData: Object,
    fields: Array,
    className: Array,
    gap: String,
    len: String,
  },
  methods: {
    getField(fields, name) {
      return fields.find((el) => el.name === name);
    },
    execAction(a, e, d) {
      console.log('execAction', a, e);
      if (a) {
        e.stopImmediatePropagation();
        this.$emit('colAction', a, e, d);
      }

      // if (a) {
      //   e.stopImmediatePropagation();
      //   this[a](e);
      // }
    },
    // showProfile(e) {
    //   e.stopImmediatePropagation();
    //   this.$emit('colAction', e);
    // },
    renderData(row, f) {
      if (f.render && typeof f.render === 'function') {
        return f.render(row[f.data], row, row);
      } else {
        return row[f.data];
      }
    },
  },
};
</script>

<style>
.badge.bot {
  vertical-align: text-bottom;
}
</style>

<style scoped>
.data-table.stripe tbody tr.odd,
.data-table.display tbody tr.odd {
  background-color: #fbfbfb;
}
.data-table.display tbody td {
  border-top: 1px solid #ddd;
}
.data-table.display tbody tr:hover:not(.child) {
  background-color: #f6f6f6;
}
.data-table.display tbody tr.selected:hover:not(.child) {
  background-color: #aab7d1;
}
.data-table tbody td {
  padding: 8px 10px;
}
.data-table tbody .high-row td {
  padding: 22px 10px;
}
.data-table tbody td.show-gap {
  position: relative;
}
.data-table tbody td.show-gap::before {
  content: attr(data-gap);
  width: 130px;
  height: 3px;
  line-height: 3px;
  color: #007bff;
  text-align: center;
  font-size: 16px;
  background-color: white;
  position: absolute;
  left: 50%;
  margin-left: -65px;
  margin-top: -2px;
  top: 0%;
}
.data-table tbody td.pointer {
  cursor: pointer;
}
a.pointer {
  cursor: pointer;
}
</style>
