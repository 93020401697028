<template>
  <div>
    <el-tag v-for="tag in tags" :key="tag.name" closable :type="tag.type" effect="plain">
      {{ tag.name }}
    </el-tag>
    <el-button class="button-new-tag" type="success" icon="el-icon-plus" plain circle size="small"></el-button>
  </div>
</template>

<script>
export default {
  name: 'tags-list',
  data: () => {
    return {
      tags: [
        { name: 'Загружена новая версия проекта', type: '' },
        { name: 'Изменение данных в GOOSE', type: 'success' },
        { name: 'MAC-адреса источников данных сброшены', type: 'info' },
        // { name: 'Tag 4', type: 'warning' },
        // { name: 'Tag 5', type: 'danger' },
      ],
    };
  },
};
</script>

<style>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px !important;
}
</style>
