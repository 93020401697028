<template>
  <tr role="row" class="child">
    <td :colspan="colCount">
      <slot></slot>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'detail-row',
  props: {
    rowData: Object,
    fields: Array,
    className: Object,
    gap: String,
  },
  computed: {
    colCount() {
      return this.fields.reduce((counter, current) => {
        let visible = true;
        if (current.visible !== undefined) visible = current.visible;
        counter += Number(visible ? 1 : 0);
        return counter;
      }, 0);
    },
  },
  methods: {
    execAction(a, e) {
      console.log('execAction', a, e);
      e.stopImmediatePropagation();
      this.$emit('colAction', a, e);

      // if (a) {
      //   e.stopImmediatePropagation();
      //   this[a](e);
      // }
    },
    // showProfile(e) {
    //   e.stopImmediatePropagation();
    //   this.$emit('colAction', e);
    // },
    renderData(row, f) {
      if (f.render && typeof f.render === 'function') {
        return f.render(row[f.data], row, row);
      } else {
        return row[f.data];
      }
    },
  },
};
</script>

<style scoped>
.data-table.stripe tbody tr.odd,
.data-table.display tbody tr.odd {
  background-color: #fbfbfb;
}
.data-table.display tbody td {
  border-top: 1px solid #ddd;
}
.data-table.display tbody tr:hover:not(.child) {
  background-color: #f6f6f6;
}
.data-table tbody td {
  padding: 8px 10px;
}
.data-table tbody .high-row td {
  padding: 22px 10px;
}
.data-table tbody td.show-gap {
  position: relative;
}
.data-table tbody td.show-gap::before {
  content: attr(data-gap);
  width: 130px;
  height: 3px;
  line-height: 3px;
  color: #007bff;
  text-align: center;
  font-size: 16px;
  background-color: white;
  position: absolute;
  left: 50%;
  margin-left: -65px;
  margin-top: -2px;
  top: 0%;
}
</style>
