<template>
  <div class="sidebar" v-sticky="{ zIndex: 10, stickyTop: 0 }">
    <div class="sidebar__header">
      <div class="sidebar__header-icon">
        <div class="tk-icon">
          <img
            src="https://uploads-ssl.webflow.com/5f91d5a10b8e3e32addb2532/5f990dbb19c42e0def95138d_filter.svg"
            loading="lazy"
            alt=""
          />
        </div>
      </div>
      <div class="sidebar__header-title">{{ $i18n.t('Filter') }}</div>
      <div class="sidebar__header-action">
        <div class="tk-icon tk-icon--big" @click="$emit('update:filterVisible', false)">
          <img
            src="https://uploads-ssl.webflow.com/5f91d5a10b8e3e32addb2532/5f990cf25c0be98353644ae9_crossline.svg"
            loading="lazy"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="sidebar__body">
      <simplebar class="wrr">
        <slot name="text-search"></slot>
        <filter-group v-if="filters && filters['clusterSizeBounds']" :name="$i18n.t('Cluster size')">
          <!--          <el-slider :value="v" range :min="10" :max="20"> </el-slider>-->
          <range-filter
            v-if="clusterSize"
            :range="range"
            :value="clusterSize"
            @change="clusterSizeChange"
          ></range-filter>
        </filter-group>
        <filter-group
          v-if="filters.clusterDateBounds && filters.clusterDateBounds['min'] && filters.clusterDateBounds['max']"
          :name="$i18n.t('Period')"
        >
          <period-picker
            v-if="clusterDate"
            :bounds="filters['clusterDateBounds']"
            :value.sync="clusterDate"
            @change="changeDateBounds"
          ></period-picker>
        </filter-group>
        <filter-group v-if="filters && filters['protocols']" :name="$i18n.t('Protocols')">
          <filter-list
            :items="filters['protocols']"
            :active-items="filterValues['protocols']"
            @checked="chEl('protocols', $event)"
          ></filter-list>
        </filter-group>
        <filter-group v-if="filters && filters['types']" :name="$i18n.t('Types')">
          <filter-list
            :items="filters['types']"
            :active-items="filterValues['types']"
            @checked="chEl('types', $event)"
          ></filter-list>
        </filter-group>
        <filter-group v-if="filters && filters['devices']" :name="$i18n.t('Devices')">
          <filter-list
            :searchable="true"
            :items="filters['devices']"
            :active-items="filterValues['devices']"
            @checked="chEl('devices', $event)"
          ></filter-list>
        </filter-group>
      </simplebar>
    </div>
  </div>
</template>

<script>
import VueSticky from 'vue-sticky';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import FilterGroup from './FilterGroup';
import FilterList from './FilterList';
import RangeFilter from './RangeFilter';
import PeriodPicker from './PeriodPicker';
import { mapGetters } from 'vuex';
export default {
  name: 'clusters-filter',
  directives: {
    sticky: VueSticky,
  },
  components: {
    FilterGroup,
    FilterList,
    RangeFilter,
    PeriodPicker,
    simplebar,
  },
  props: {
    filterVisible: {
      type: Boolean,
    },
    // filters: {
    //   type: Object,
    // },
    activeFilters: {
      type: Object,
    },
  },
  data: () => {
    return {
      range: true,
    };
  },
  computed: {
    ...mapGetters([
      'filters',
      'filterValues',
      // ...
    ]),
    clusterSize: {
      get() {
        if (this.filterValues) {
          return this.filterValues.clusterSize;
        } else {
          return { min: 0, max: 0 };
        }
      },
      set(e) {
        console.log('clusterSize set', e);
        this.$store.dispatch('setClusterSize', e);
      },
    },
    clusterDate: {
      get() {
        if (this.filterValues) {
          return this.filterValues.clusterDate;
        } else {
          return { min: 0, max: 0 };
        }
      },
      set(e) {
        console.log('clusterDate set', e);
        this.$store.dispatch('setClusterDate', e);
      },
    },
    clusterSizeBounds() {
      return this.filters.clusterSizeBounds;
    },
    clusterDateBounds() {
      return this.filters.clusterDateBounds;
    },
  },
  mounted() {},
  methods: {
    changeDateBounds(v) {
      console.log('changeDateBounds', v);
      console.log(
        'changeDateBounds',
        v.min,
        this.filters.clusterDateBounds.min,
        v.min !== this.filters.clusterDateBounds.min,
        v.max !== this.filters.clusterDateBounds.max
      );
      if (v.min > v.max) {
        const l = v.max;
        v.max = v.min;
        v.min = l;
      }
      this.clusterDate = v;
      if (v.min !== this.filters.clusterDateBounds.min || v.max !== this.filters.clusterDateBounds.max) {
        this['activeFilters']['clusterDates'] = [v.min, v.max];
      } else {
        this['activeFilters']['clusterDates'] = [
          this.filters.clusterDateBounds.min,
          this.filters.clusterDateBounds.max,
        ];
      }
      this.$nextTick(() => {
        this.$emit('filter-changed', this.activeFilters);
      });
    },
    clusterSizeChange(e) {
      console.log('clusterSizeChange', e);
      this.clusterSize = e;
      this['activeFilters']['clusterSize'] = e;
      console.log('clusterSizeChange', JSON.stringify(e));
      this.$nextTick(() => {
        this.$emit('filter-changed', this.activeFilters);
      });
    },
    chEl(type, e) {
      const action = e['action'];
      const value = e['value'];
      // console.log('cf chEl', arr, this[arr], this['activeFilters'], this['activeFilters.types'], action, value);
      if (action === 'add') {
        this.$store.dispatch('pushItemValue', { type, value });

        // this['activeFilters'][arr].push(value);
      } else {
        this.$store.dispatch('shiftItemValue', { type, value });
        // const elIdx = this['activeFilters'][arr].findIndex((el) => el === value);
        // this['activeFilters'][arr].splice(elIdx, 1);
      }
      this.$emit('filter-changed', this.activeFilters);
    },
  },
  watch: {
    // selectedFilters: {
    //   deep: true,
    //   handler(v1, v2) {
    //     // console.log('selectedFilters', v1, v2);
    //     // this.$emit('filter-changed', this.selectedFilters);
    //   },
    // },
  },
};
</script>

<style scoped></style>
