<template>
  <modal-dialog
    :modal="modal"
    v-show="visibleModal"
    :visible.sync="visibleModal"
    ref="modEx"
    @closeModal="closeModal"
    @doExport="doExport"
  >
    <template slot="content">
      <p>
        {{ $t(modalText) }}
      </p>
      <div style="margin-top: 20px">
        <el-form ref="form" label-width="120px">
          <el-form-item label-width="150px" :label="$t('Export format')">
            <el-radio-group v-model="selectedFormat" size="small">
              <el-radio-button v-for="expForm in exportFormats" :label="expForm.value" :key="expForm.value">{{
                $t(expForm.label)
              }}</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label-width="150px" :label="$t('Events sorting')">
            <el-radio-group v-model="selectedSort" size="small">
              <el-radio-button v-for="expSort in exportSort" :label="expSort.value" :key="expSort.value">{{
                $t(expSort.label)
              }}</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template slot="buttons">
      <button
        class="btn btn-secondary"
        type="button"
        data-dismiss="modal"
        aria-label="Cancel"
        @click="$refs.modEx.$emit('closeModal')"
      >
        Отмена</button
      ><button
        class="btn btn-primary"
        :class="[modal.name + '__submit-btn']"
        :type="modal.typeSubmit ? 'submit' : 'button'"
        :aria-label="modal.submitBtn ? modal.submitBtn : 'Save'"
        :onclick="modal.onSubmit"
        @click="$refs.modEx.$emit(modal.onSubmit)"
        :form="modal.typeSubmit ? `${modal.name}-modal__form` : ''"
      >
        {{ $t(modal.submitBtn ? modal.submitBtn : 'Save') }}
      </button>
    </template>
  </modal-dialog>
</template>

<script>
import ModalDialog from './ModalDialog';

export default {
  name: 'export2-events-modal',
  props: {
    visibleModal: {
      type: Boolean,
      default: false,
    },
    archive: {
      type: Boolean,
      default: false,
    },
  },
  components: { ModalDialog },
  data: () => {
    return {
      modal: {
        name: 'export-events',
        title: 'Export',
        onSubmit: 'doExport',
        submitBtn: 'OK',
      },
      exportFormats: [
        { value: 'pdf', label: 'PDF', active: true },
        { value: 'xlsx', label: 'Excel' },
        { value: 'csv', label: 'CSV' },
      ],
      exportSort: [
        { value: 'asc', label: 'from new to old', active: true },
        { value: 'desc', label: 'from old to new' },
      ],
      selectedFormat: 'pdf',
      selectedSort: 'asc',
      modalText: 'Export events information',
      dateTime: {},
    };
  },
  mounted() {},
  methods: {
    doExport() {
      console.log('doExport');
      this.$emit('doExport', { format: this.selectedFormat, sort: this.selectedSort });
      this.$emit('update:visibleModal', false);
    },
    closeModal() {
      console.log('closeModal');
      this.$emit('update:visibleModal', false);
    },
    selectPeriod(p) {
      console.log(p);
      this.timePresets
        .filter((e) => e.active)
        .forEach((el) => {
          let ell = el;
          let idx = this.timePresets.indexOf(el);
          delete ell['active'];
          // this.timePresets.$set(idx, ell);
          this.$set(this.timePresets, idx, ell);
        });
      console.log(this.timePresets.filter((e) => e.value === p.value));
      let el = this.timePresets.find((e) => e.value === p.value);
      let idx = this.timePresets.indexOf(el);

      el.active = true;
      // this.timePresets.$set(idx, el);
      this.$set(this.timePresets, idx, el);
      this.setTimePeriod(p.value);
    },
    setTimePeriod(value) {
      let now = new Date();
      let start = new Date();
      let end = new Date();
      switch (value) {
        case 'five-min':
          start.setMinutes(now.getMinutes() - 5);
          break;
        case 'today':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
          break;
        case 'this-week':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1);
          break;
        case 'last-week':
          start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 6);
          end = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1);
          break;
        case 'this-month':
          start = new Date(now.getFullYear(), now.getMonth(), 1);
          break;
        case 'last-month':
          start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
          end = new Date(now.getFullYear(), now.getMonth(), 1);
          break;
        default:
          break;
      }
      this.dateTime = {
        start: {
          date: start.customFormat('#YYYY#-#MM#-#DD#'),
          time: start.customFormat('#hhhh#:#mm#:#ss#'),
        },
        end: {
          date: end.customFormat('#YYYY#-#MM#-#DD#'),
          time: end.customFormat('#hhhh#:#mm#:#ss#'),
        },
      };
    },
  },
  watch: {
    visibleModal: function (val) {
      console.log('visibleModal', val);
      if (!val) {
        this.$emit('update:visibleModal', val);
      }
    },
  },
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
