<template>
  <div class="data-table">
    <div class="data-table-loading-wrapper">
      <loading v-if="!firstLoad" :active.sync="loading" :can-cancel="false" :is-full-page="false"> </loading>

      <table class="data-table display" :class="className">
        <thead>
          <tr role="row">
            <th v-for="f in fields" :key="f.name" :class="columnSortClass(f)" @click="changeSort(f)">
              {{ $t(f.label) }}
            </th>
          </tr>
        </thead>

        <tbody>
          <data-row
            v-for="(rowData, idx) in rowsData"
            :row-data="rowData"
            :fields="fields"
            :key="rowData._id"
            :class-name="[{ even: idx % 2 === 0, odd: idx % 2 === 1, selected: isSelected(idx) }]"
            @select="selectRow(idx)"
          ></data-row>
        </tbody>
        <tfoot>
          <tr role="row">
            <th v-for="f in fields" :key="f.name">{{ $t(f.label) }}</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import DataRow from './DataRow';
// import loading from 'vue-loading';
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

import ArchiveEventsModal from './../ArchiveEventsModal';
import ExportEventsModal from './../ExportEventsModal';
import Export2EventsModal from './../Export2EventsModal';

import axios from 'axios';
import { Zone, DateTime } from 'luxon';
import { mapGetters } from 'vuex';

export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    className: {
      type: String,
    },
    // buttons: {
    //   type: Array,
    // },
    fields: {
      type: Array,
    },
    lengthMenu: {
      type: Array,
    },
    // offTypes: {
    //   type: Array,
    // },
    archive: Boolean,
    noSearch: {
      defauld: false,
    },
    extButtons: {
      type: Array,
    },
    query: {
      type: Object,
    },
  },
  components: { ArchiveEventsModal, ExportEventsModal, Export2EventsModal, DataRow, Loading },
  // directives: { loading },
  name: 'events-table-lite',
  data: () => {
    return {
      sortables: [],
      currentSortField: '',
      currentSortDir: -1,
      currentPage: 1,
      pageStart: 0,
      pageLength: 10,
      searchString: '',
      // searchTypes: [],
      rowsData: [],
      recordsTotal: 0,
      recordsFiltered: 0,
      loading: false,
      firstLoad: true,
      draw: 0,
      errors: [],
      onTypes: [],
      offTypes: [],
      selectedRows: [],
      visibleArchiveDialog: false,
      visibleExportDialog: false,
      buttons: [
        {
          name: 'print',
          label: 'Print',
        },
        {
          name: 'events-export',
          label: 'Export',
          action: 'exportDialog',
        },
        // {
        //   name: 'export-sub',
        //   label: 'Export',
        //   children: [
        //     {
        //       name: 'pdf',
        //       label: 'PDF',
        //       action: 'exportPdf',
        //     },
        //     {
        //       name: 'csv',
        //       label: 'CSV',
        //       action: 'exportCsv',
        //     },
        //     {
        //       name: 'xls',
        //       label: 'Excel',
        //       action: 'exportXls',
        //     },
        //   ],
        // },
        {
          name: 'archive',
          label: 'Archive',
          action: 'switchArchive',
        },
        {
          name: 'view-selected',
          label: 'View selected',
          action: 'openSelected',
        },
      ],
      thisEvTable: undefined,
    };
  },
  computed: {
    ...mapGetters(['hasPendingReports']),

    rowsDataSelected() {
      return this.rowsData.filter((e, i) => this.selectedRows.indexOf(this.pageStart + i) > -1);
    },
    rowsDataSelectedTs() {
      return this.rowsDataSelected.map((e) => e.ts).sort();
    },
    rowsDataSelectedTime() {
      if (this.rowsDataSelectedTs.length > 0) {
        let timestamp = (this.rowsDataSelectedTs[0] + this.rowsDataSelectedTs[this.rowsDataSelectedTs.length - 1]) / 2;
        let timeBefore = timestamp - this.rowsDataSelectedTs[0] + (this.rowsDataSelectedTs.length > 1 ? 0 : 1e7);
        let timeAfter = timestamp - this.rowsDataSelectedTs[0] + (this.rowsDataSelectedTs.length > 1 ? 0 : 1e7);
        return {
          timestamp,
          timeBefore,
          timeAfter,
        };
      }
    },
    enableView() {
      return this.rowsDataSelected.filter((e) => e.hasRef).length > 1;
    },
    recordLeft() {
      return this.pageStart + 1;
    },
    recordRight() {
      return Math.min(this.pageStart + this.pageLength, this.recordsTotal);
    },
    minPage() {
      return 1;
    },
    maxPage() {
      return Math.max(Math.ceil(this.recordsTotal / this.pageLength), 1);
    },
    pages() {
      let pages = [];
      pages.push(this.currentPage);
      pages.push(this.minPage);
      pages.push(this.maxPage);
      if (this.currentPage - 1 > this.minPage) {
        pages.push(this.currentPage - 1);
      }
      if (this.currentPage + 1 < this.maxPage) {
        pages.push(this.currentPage + 1);
      }
      if (this.currentPage - this.minPage === 3) {
        pages.push(this.currentPage - 2);
      }
      if (this.maxPage - this.currentPage === 3) {
        pages.push(this.currentPage + 2);
      }
      if (this.currentPage - this.minPage < 3) {
        for (let i = this.minPage; i < this.minPage + 5 && this.maxPage > i; i++) {
          pages.push(i);
        }
      }
      if (this.maxPage - this.currentPage < 3) {
        for (let i = this.maxPage; i > this.maxPage - 5 && this.minPage < i; i--) {
          pages.push(i);
        }
      }
      pages = [...new Set(pages)];
      return pages.sort(function (a, b) {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });
    },
    order() {
      return {
        column: this.fields.map((e) => e.name).indexOf(this.currentSortField),
        dir: this.currentSortDir === 1 ? 'asc' : 'desc',
      };
    },
    pagesList() {
      let list = [];
      for (let i = 0; i < this.pages.length; i++) {
        list.push(this.pages[i]);
        if (i + 1 < this.pages.length && this.pages[i] + 1 !== this.pages[i + 1]) {
          list.push(null);
        }
      }
      return list;
    },
    eventTypes() {
      return [
        { name: 'GO', label: this.$i18n.t('Goose') },
        { name: 'SV', label: this.$i18n.t('SV') },
        { name: 'SRV', label: this.$i18n.t('Service events') },
        { name: 'ARP', label: this.$i18n.t('ARP') },
        { name: 'MMS', label: this.$i18n.t('MMS') },
        { name: 'USER', label: this.$i18n.t('User events') },
      ];
    },

    offTypesArray() {
      return this.offTypes && this.offTypes.map((e) => '!' + e);
    },
    search() {
      let searchArray = this.offTypesArray;
      if (this.searchString !== '') {
        searchArray = [...this.offTypesArray, this.searchString];
      }
      return {
        value: searchArray.join(','),
        regex: false,
      };
    },
    queryString() {
      let order = [];
      let search = this.search;
      order.push(this.order);
      let searchObj = {
        draw: this.draw,
        order,
        search,
        start: this.pageStart,
        length: this.pageLength,
      };

      if (this.query) {
        console.log('que', JSON.stringify(this.query));
        return this.$qs.stringify({...searchObj, ...this.query, removeUnfiltered: true });
      } else {
        return this.$qs.stringify(searchObj);
      }
    },
  },
  beforeMount() {
    try {
      let onTypes = this.eventTypes.map((e) => e.name);
      let ss = this.$route.query;
      let ssArr = this.$qs.parse(ss);
      let types = ssArr['search']
        .split(',')
        .filter((e) => e.indexOf('!') > -1)
        .map((e) => e.substring(1));
      console.log('ss', ssArr, onTypes);
      onTypes.forEach((el) => {
        if (types.indexOf(el) < 0) {
          this.onTypes.push(el);
        }
      });
      this.offTypes = this.eventTypes.filter((e) => this.onTypes.indexOf(e.name) < 0).map((e) => e.name);
      this.$nextTick(() => {
        this.visibleTable = true;
      });
    } catch (e) {
      this.onTypes = this.eventTypes.map((e) => e.name);
      this.offTypes = this.eventTypes.filter((e) => this.onTypes.indexOf(e.name) < 0).map((e) => e.name);
      this.visibleTable = true;
    }
    if (this.extButtons) {
      this.buttons = this.extButtons;
    }
  },
  mounted() {
    console.log('mnt', this.$tpws, this.$tpws1);
    this.setDefaultSort();
    this.loadData();
    if (this.$route.query.page) {
      this.setCurrentPage(Number(this.$route.query.page));
    }
    window.addEventListener('events-table', (t, e) => {
      console.log('ev:events-table', t, e);
      this.thisEvTable = t.detail;
    });

    console.log('r', this.$route);

    this.$nextTick(() => {});
  },
  methods: {
    handleSizeChange(e) {
      this.pageLength = e;
      console.log('set size', e);
    },
    handleCurrentChange(e) {
      // this.currentPage = e;
      console.log('set page', e);
    },
    doExport(e) {
      console.log('doExport', e);

      let exportFormat = e.format;
      let exportSort = e.sort;
      let fileDateTime = DateTime.local().toFormat('yyyyLLdd');
      let exportFilename =
        `Теквел Парк - проект ${this.$tpws.displayName} - список событий - ${fileDateTime}.` + exportFormat;

      // this.$('#export2-events-modal').modal('hide');

      // const loader = this.$('#loading_file');
      // loader.show();
      axios
        .post(`/projects/${this.$tpws.projectName}/events-file-export`, {
          search: this.search,
          draw: 1,
          exportFilename: exportFilename,
          exportFormat: exportFormat,
          exportSort: exportSort,
        })
        .then((response) => {})
        .catch((e) => {
          console.log('err', e);
        })
        .then(function () {
          // loader.hide();
        });

      /*
      this.$.ajax({
        url: `/projects/${this.$tpws.projectName}/events-file-export`,
        type: 'post',
        dataType: 'json',

        data: {
          ...table.ajax.params(),
          draw: 1,
          exportFilename: exportFilename,
          exportFormat: exportFormat,
          exportSort: exportSort,
        },
        error: function (err) {

        },
        success: function (response) {
        },
        complete: function () {

        },
      });
*/
    },
    switchType(type) {
      console.log('t', type);
      if (this.onTypes.indexOf(type.name) < 0) {
        this.onTypes.push(type.name);
      } else {
        this.onTypes = this.onTypes.filter((e) => e != type.name);
      }
      this.offTypes = this.eventTypes.filter((e) => this.onTypes.indexOf(e.name) < 0).map((e) => e.name);
      this.$nextTick(() => {
        // this.pageChange();
        this.$emit('pageUpdate');
      });
    },

    execAction(b, e) {
      if (b.extAction) {
        this.$emit('ext-action', b.extAction, e);
      }
      if (b.action) {
        this[b.action](e);
      }
    },
    selectAll() {
      for (let i = 0; i < this.pageLength - 2; i++) {
        const rowInx = this.pageStart + i;
        if (this.selectedRows.indexOf(rowInx) < 0) {
          this.selectedRows.push(rowInx);
        } else {
          // this.selectedRows = this.selectedRows.filter((e) => e !== rowInx);
        }
      }
    },
    exportXls(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl = this.archive
        ? `/projects/${this.$tpws.projectName}/archive-xls?tstart=${this.archive.tstart}&tend=${this.archive.tend}`
        : `/projects/${this.$tpws.projectName}/events2-xls`;
      axios
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.$tpws.displayName} ` + this.$i18n.t('analysis report') + `.xlsx`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    exportPdf(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl = this.archive
        ? `/projects/${this.$tpws.projectName}/archive-pdf?tstart=${this.archive.tstart}&tend=${this.archive.tend}`
        : `/projects/${this.$tpws.projectName}/events2-pdf`;
      axios
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.$tpws.displayName} ` + this.$i18n.t('analysis report') + `.pdf`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    exportCsv(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl = this.archive
        ? `/projects/${this.$tpws.projectName}/archive-csv?tstart=${this.archive.tstart}&tend=${this.archive.tend}`
        : `/projects/${this.$tpws.projectName}/events2-csv`;
      axios
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.$tpws.displayName} ` + this.$i18n.t('analysis report') + `.csv`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    switchArchive(e) {
      this.visibleArchiveDialog = true;
    },
    exportDialog(e) {
      this.visibleExportDialog = true;
    },
    openSelected(e) {
      console.log('openSelected', e);
      const ts = this.rowsDataSelectedTime;
      if (e.ctrlKey || e.shiftKey || e.altKey)
        window.open(`?p=signals&time=${ts.timestamp}&timeBefore=${ts.timeBefore}&timeAfter=${ts.timeAfter}`, '_blank');
      else location.href = `?p=signals&time=${ts.timestamp}&timeBefore=${ts.timeBefore}&timeAfter=${ts.timeAfter}`;
    },
    selectRow(i) {
      const rowInx = this.pageStart + i;
      console.log('sr', this.selectedRows.indexOf(rowInx), rowInx);
      if (this.selectedRows.indexOf(rowInx) < 0) {
        this.selectedRows.push(rowInx);
      } else {
        this.selectedRows = this.selectedRows.filter((e) => e !== rowInx);
      }
    },
    isSelected(idx) {
      const rowInx = this.pageStart + idx;
      return this.selectedRows.indexOf(rowInx) > -1;
    },
    setCurrentPage(p) {
      if (p) {
        this.currentPage = p;
      }
    },
    loadData() {
      this.draw++;
      console.log('sq', this.queryString);
      let dataUrl = this.url + '?' + this.queryString;
      this.selectedRows = [];
      this.loading = true;

      this.$emit('pageUpdate');

      axios
        .get(dataUrl)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.rowsData = response.data.data;
          this.recordsTotal = response.data.recordsTotal;
          this.recordsFiltered = response.data.recordsFiltered;

          this.$emit('loadedRows', this.rowsData.length);
          this.firstLoad = false;
          this.loading = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    setDefaultSort() {
      if (this.currentSortField === '') {
        this.currentSortField = this.fields[0].name;
      }
      if (this.currentSortDir !== -1) {
        this.currentSortDir = -1;
      }
    },
    columnSortClass(f) {
      if (f.name === this.currentSortField) {
        if (this.currentSortDir === 1) {
          return 'sorting_asc';
        }
        if (this.currentSortDir === -1) {
          return 'sorting_desc';
        }
      } else {
        return f.sortable ? 'sorting' : '';
      }
    },
    changeSort(f) {
      console.log('f', f);
      const prevSortField = this.currentSortField;
      this.currentSortField = f.name;
      if (prevSortField !== this.currentSortField) {
        this.currentSortDir = 1;
      } else {
        if (this.currentSortDir === 1) {
          this.currentSortDir = -1;
        } else {
          this.currentSortDir = 1;
        }
      }
      this.loadData();
      this.$emit('pageUpdate');
    },
  },
  watch: {
    offTypes: function (val, old) {
      if (val !== old) {
        this.loadData();
      }
    },
    searchString: function (val, old) {
      if (val !== old) {
        this.loadData();
      }
    },
    currentPage: function (val, old) {
      if (val !== old) {
        this.pageStart = (this.currentPage - 1) * this.pageLength;
      }
    },
    pageLength: function (val, old) {
      if (val !== old) {
        this.loadData();
      }
    },
    pageStart: function (val, old) {
      if (val !== old) {
        this.loadData();
      }
    },
  },
};
</script>

<style scoped>
.data-table-loading-wrapper {
  position: relative;
}
.data-table {
  width: 100%;
}
.data-table thead .sorting_asc {
  background-image: url(../../../assets/sort_asc.png);
}
.data-table thead .sorting_desc {
  background-image: url(../../../assets/sort_desc.png);
}
.data-table thead .sorting {
  background-image: url(../../../assets/sort_both.png);
}
.data-table .event-types-block {
  display: flex;
}
.data-table .event-types-block {
  margin: 10px 0;
}
.data-table thead .sorting,
.data-table thead .sorting_asc,
.data-table thead .sorting_desc,
.data-table thead .disabled {
  background-repeat: no-repeat;
  background-position: center right;
}
.data-table thead .sorting,
.data-table thead .sorting_asc,
.data-table thead .sorting_desc {
  cursor: pointer;
  *cursor: hand;
}
.data-table .dataTables_filter {
  float: right;
  text-align: right;
}
.data-table thead .sorting {
}

.data-table thead th,
.data-table thead td {
  user-select: none;
  padding: 10px 18px;
  border-bottom: 1px solid #111;
}

.data-table tfoot th,
.data-table tfoot td {
  padding: 10px 18px;
  border-top: 1px solid #111;
}
.data-table thead th,
.data-table tfoot th {
  font-weight: bold;
}

.data-table.display tbody tr.selected {
  background-color: #acbad4;
}
</style>
