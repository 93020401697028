<template>
  <div id="signals-table" class="container">
    <h1 class="event-profile__title mt-5">{{ $t('Event Profile for', [dateNow]) }}</h1>
    <small v-if="dateBefore && dateAfter">{{
      $t(`Showing event summary for time interval from {0} to {1}`, [dateBefore, dateAfter])
    }}</small>
    <small v-else>{{ $t(`Showing event summary for time {0}`, [dateNow]) }}</small>

    <a v-if="buttonsVidible && dygraphVidible" class="ml-2 event-profile__show-tabs-btn" href="#" @click="showTabs"
      >Показать вкладки</a
    >
    <a
      v-if="buttonsVidible && !dygraphVidible"
      class="ml-2 event-profile__show-graphs-btn"
      href="#"
      @click="showDygraph"
      >Показать графики</a
    >
    <div class="event-profile__content container" id="event-profile__">
      <div v-show="!dygraphVidible" class="collapse show">
        <ul class="nav nav-tabs card-header-tabs mt-4">
          <li class="nav-item" @click="switchTab('signals')">
            <div
              class="signal-btn btn nav-link"
              id="signals-btn2"
              :class="{ active: currentTab === 'signals', disabled: signalsCount === 0 }"
            >
              <h2>
                {{ $t('signals-tab.Signals') }} &nbsp;<small class="badge badge-primary">{{ signalsCount }}</small>
              </h2>
            </div>
          </li>
          <li class="nav-item" @click="switchTab('svr')">
            <div
              class="signal-btn btn nav-link"
              id="svr-btn2"
              :class="{ active: currentTab === 'svr', disabled: svrCount === 0 }"
            >
              <h2>
                {{ $t('signals-tab.Sampled values records') }} &nbsp;<small class="badge badge-primary">{{
                  svrCount
                }}</small>
              </h2>
            </div>
          </li>
          <li class="nav-item" @click="switchTab('pcaps')">
            <div
              class="signal-btn btn nav-link"
              id="pcaps-btn2"
              :class="{ active: currentTab === 'pcaps', disabled: pcapsCount === 0 }"
            >
              <h2>
                {{ $t('signals-tab.PCAPs') }} &nbsp;<small class="badge badge-primary">{{ pcapsCount }}</small>
              </h2>
            </div>
          </li>
        </ul>
        <br />
        <signals-meta
          v-if="user && signalsQuery.prev && signalsQuery.next"
          ref="signalsMeta"
          v-show="currentTab === 'signals'"
          :no-search="true"
          :fields="fields"
          :off-types="offTypes"
          :url="signalsUrl"
          :query="signalsQuery"
          :length-menu="lengthMenu"
          @loadedRows="loadedSignals"
          @showDygraph="swDygraph"
        >
        </signals-meta>
        <svr-meta
          v-if="user && oscsQuery.prev && oscsQuery.next"
          ref="svrMeta"
          v-show="currentTab === 'svr'"
          :no-search="true"
          :fields="svrFields"
          :off-types="offTypes"
          :url="oscsUrl"
          :query="oscsQuery"
          :length-menu="lengthMenu"
          :hide-bottom="true"
          @loadedRows="loadedSvr"
          @showDygraph="swDygraph"
          @open-selected="swSvr"
        >
        </svr-meta>
        <pcaps-meta
          v-if="user && pcapsQuery.timebefore && pcapsQuery.timeafter"
          ref="pcapsMeta"
          v-show="currentTab === 'pcaps'"
          :no-search="true"
          :fields="pcapsFields"
          :off-types="offTypes"
          :url="pcapsUrl"
          :query="pcapsQuery"
          :hide-bottom="true"
          :length-menu="lengthMenu"
          @loadedRows="loadedPcaps"
          @showDygraph="swDygraph"
        >
        </pcaps-meta>
      </div>
      <a class="up-btn fal fa-angle-up" href="#signals" style="display: none"></a>
      <div v-show="dygraphVidible" class="mt-4 row" id="signals-dygraph">
        <div id="timer-form" style="padding: 5px 0 5px 50px">
          <div id="timer-back"></div>
          <form class="form-inline">
            <div class="input-group col-md-3" id="timer-input-t1-group">
              <div class="input-group-prepend">
                <span class="input-group-text">T1</span>
              </div>
              <input class="form-control" id="timer-input-t1" type="text" placeholder="0" readonly="" />
            </div>
            <div class="input-group col-md-3" id="timer-input-t2-group">
              <div class="input-group-prepend">
                <span class="input-group-text">T2</span>
              </div>
              <input class="form-control" id="timer-input-t2" type="text" placeholder="0" readonly="" />
            </div>
            <div class="input-group col-md-2" id="timer-input-dt-group">
              <div class="input-group-prepend">
                <span class="input-group-text">ΔT</span>
              </div>
              <input class="form-control" id="timer-input-dt" type="text" placeholder="0" readonly="" />
            </div>
            <div class="input-group col-md-1">
              <button
                class="btn btn-default"
                id="timer-reset-button"
                type="button"
                onclick="tpui.faultprofile.timer.reset()"
              >
                Сброс
              </button>
            </div>
          </form>
        </div>
        <div id="signals-dygraph-canvas" style="position: relative; width: 100%">
          <div class="position-relative" id="graphs"></div>
          <div class="mb-2 d-none" id="timestamp-type-toggle">
            <div class="timestamp-type-toggle__background"></div>
            <div class="timestamp-type-toggle__title mr-2">Тип метки времени:</div>
            <div class="btn-group btn-group-toggle" data-toggle="buttons">
              <label class="btn btn-sm btn-secondary"
                >Процесс<input
                  type="radio"
                  name="options"
                  v-model="timestampType"
                  value="process"
                  id="timestamp-type-toggle__process"
                  autocomplete="off"
                  onchange="eventProfile.toggleTimestampType()" /></label
              ><label class="btn btn-sm btn-secondary active"
                >Сетевая<input
                  type="radio"
                  name="options"
                  v-model="timestampType"
                  value="network"
                  id="timestamp-type-toggle__network"
                  autocomplete="off"
                  onchange="eventProfile.toggleTimestampType()"
              /></label>
            </div>
          </div>
          <div class="position-relative" id="svr-dygraph"></div>
        </div>
      </div>
      <div class="loading" style="display: none">
        <div class="wrapper"><div class="cssload-loader"></div></div>
      </div>
    </div>

    <a href="?p=wheel" class="wh-back d-none"
      >{{ $t('Back to') }} <span class="fal fa-asterisk"></span> {{ $t('Chart') }}</a
    >
    <div class="wh-page">
      <h2 v-if="archive && !isNaN(archive.tstart) && !isNaN(archive.tend)" class="events-archive__title">
        {{ $t('Events log from $1 to $2', [`${nanoTstart}`, `${nanoTend}`]) }}
      </h2>
    </div>
  </div>
</template>

<script>
// import ArchiveEventsModal from './ArchiveEventsModal';
// import ExportEventsModal from './ExportEventsModal';
import DateNano from '../utils/format.date.time';
import SignalsMeta from './DataTable/SignalsMeta';
import SvrMeta from './DataTable/SvrMeta';
import PcapsMeta from './DataTable/PcapsMeta';

import axios from 'axios';
import $ from 'jquery';

export default {
  name: 'signals-page',
  components: { SignalsMeta, SvrMeta, PcapsMeta },
  props: {
    page: { type: Number, default: 1 },
    archive: Boolean,
    eventsSearchStr: String,
  },
  data: () => {
    return {
      d: undefined,
      lang: undefined,
      v: false,
      vv: false,
      search: '',
      quickSearch: '',
      onTypes: [],
      offTypes: [],
      lengthMenu: [7, 17, 50, 100, 300, 500, 1000, 5000, 10000, 25000],
      tpui: undefined,
      user: undefined,
      signalsCount: 0,
      timestampType: 'network',
      svrCount: 0,
      pcapsCount: 0,
      currentTab: 'signals',
      dygraphVidible: false,
      buttonsVidible: false,
      errors: [],
    };
  },
  computed: {
    offset() {
      return this.user && this.user.preferences && this.user.preferences.timezoneOffset;
    },
    dateNow() {
      try {
        return new DateNano(this.$route.query.time)
          .setTimezoneOffset(this.offset)
          .customFormat('#hhhh#:#mm#:#ss#.#ms##us# #DD#.#MM#.#YYYY#');
      } catch (e) {}
    },
    dateBefore() {
      try {
        return new DateNano(this.$route.query.time - this.$route.query.timeBefore)
          .setTimezoneOffset(this.offset)
          .customFormat('#hhhh#:#mm#:#ss#.#ms##us# #DD#.#MM#.#YYYY#');
      } catch (e) {}
    },
    dateAfter() {
      try {
        return new DateNano(Number(this.$route.query.time) + Number(this.$route.query.timeAfter))
          .setTimezoneOffset(this.offset)
          .customFormat('#hhhh#:#mm#:#ss#.#ms##us# #DD#.#MM#.#YYYY#');
      } catch (e) {}
    },
    signalsUrl() {
      return 'getsignalsmeta';
    },
    signalsTimeBefore() {
      let routeTimeBefore = Number(this.$route.query.timeBefore);
      const projectTimeBefore = this.user && this.user.preferences && this.user.preferences.timeBefore;
      const generalTimeBefore = this.user && this.user.generalPreferences && this.user.generalPreferences.timeBefore;
      if (!routeTimeBefore || isNaN(routeTimeBefore)) {
        routeTimeBefore = 0;
      }
      console.log(
        'routeTimeBefore',
        routeTimeBefore,
        JSON.stringify(projectTimeBefore),
        JSON.stringify(generalTimeBefore)
      );
      if (projectTimeBefore > 0) {
        return routeTimeBefore + projectTimeBefore * 1e6;
      } else if (generalTimeBefore > 0) {
        return routeTimeBefore + generalTimeBefore * 1e6;
      }
      return this.$tpui && this.$tpui.faultprofile.tbefore * 1e6;
    },
    signalsTimeAfter() {
      let routeTimeAfter = Number(this.$route.query.timeAfter);
      const projectTimeAfter = this.user && this.user.preferences && this.user.preferences.timeAfter;
      const generalTimeAfter = this.user && this.user.generalPreferences && this.user.generalPreferences.timeAfter;
      if (!routeTimeAfter || isNaN(routeTimeAfter)) {
        routeTimeAfter = 0;
      }
      if (projectTimeAfter > 0) {
        return routeTimeAfter + projectTimeAfter * 1e6;
      } else if (generalTimeAfter > 0) {
        return routeTimeAfter + generalTimeAfter * 1e6;
      }
      return this.$tpui && this.$tpui.faultprofile.tafter * 1e6;
    },
    signalsQuery() {
      return {
        t: this.$route.query.time,
        prev: this.signalsTimeBefore,
        next: this.signalsTimeAfter,
      };
    },
    oscsQuery() {
      return {
        t: parseInt(this.$route.query.time),
        prev: parseInt(this.signalsTimeBefore),
        next: parseInt(this.signalsTimeAfter),
      };
    },
    pcapsQuery() {
      return {
        t: parseInt(Number(this.$route.query.time) / 1e6),
        timebefore: Number.parseFloat(this.signalsTimeBefore / 1e6).toFixed(1),
        timeafter: Number.parseFloat(this.signalsTimeAfter / 1e6).toFixed(1),
      };
    },
    pcapsUrl() {
      return 'getpcapsmeta';
    },
    oscsUrl() {
      return 'oscs';
    },
    eventTypes() {
      return [
        { name: 'GO', label: this.$i18n.t('Goose') },
        { name: 'SV', label: this.$i18n.t('SV') },
        { name: 'SRV', label: this.$i18n.t('Service events') },
        { name: 'ARP', label: this.$i18n.t('ARP') },
        { name: 'MMS', label: this.$i18n.t('MMS') },
        { name: 'USER', label: this.$i18n.t('User events') },
      ];
    },
    _eq() {
      return {
        GO: this.$i18n.t('Goose'),
        SV: this.$i18n.t('SV'),
        SRV: this.$i18n.t('Service events'),
        ARP: this.$i18n.t('ARP'),
        MMS: this.$i18n.t('MMS'),
        USER: this.$i18n.t('User events'),
      };
    },

    __eq() {
      let eq = {};
      for (let t in this._eq) {
        eq[this._eq[t]] = t;
      }
      return eq;
    },
    headerVisible() {
      return this.archive && !isNaN(this.archive.tstart) && !isNaN(this.archive.tend);
    },

    descriptionType() {
      return this.user && this.user.preferences && this.user.preferences.descriptionType;
    },

    fields() {
      return [
        {
          label: this.$i18n.t('signals-columns.IED Name'),
          name: 'iedName',
          data: 'iedName',
          visible: this.descriptionType !== 'onlyDesc',
          sortable: false,
        },
        {
          label: this.$i18n.t('signals-columns.IED description'),
          name: 'iedDesc',
          data: 'iedDesc',
          visible: this.descriptionType !== 'onlyName',
          sortable: false,
        },
        {
          label: this.$i18n.t('signals-columns.Signal'),
          name: 'signalName',
          data: 'signalName',
          visible: this.descriptionType !== 'onlyDesc',
          sortable: false,
        },
        {
          label: this.$i18n.t('signals-columns.Signal description'),
          name: 'desc',
          data: 'desc',
          visible: this.descriptionType !== 'onlyName',
          render: (d, t, row) => `<span data-name="${row.signalName}">${d.replace(/\|+(.*)/g, '$1')}</span>`,

          sortable: false,
        },
        {
          label: this.$i18n.t('signals-columns.Control Block Name'),
          name: 'gooseCB',
          data: 'gooseCB',
          sortable: false,
        },
        {
          label: this.$i18n.t('signals-columns.Simulation'),
          name: 'simulation',
          data: 'simulation',
          render: (_) => `<b class="fa-${_ ? 'exclamation-' : ''}circle fal text-${_ ? 'warning' : 'success'}"></b>`,

          sortable: false,
        },
      ];
    },
    svrFields() {
      return [
        {
          label: this.$i18n.t('svr-columns.LVB'),
          name: 'lvbName',
          data: 'lvbName',
          sortable: false,
        },
        {
          label: this.$i18n.t('svr-columns.SVID'),
          name: 'svId',
          data: 'svId',
          sortable: false,
        },
        {
          label: this.$i18n.t('svr-columns.Destination MAC'),
          name: 'dstMac',
          data: 'dstMac',
          sortable: false,
        },
        {
          label: this.$i18n.t('svr-columns.APPID'),
          name: 'appId',
          data: 'appId',
          sortable: false,
        },
        {
          label: this.$i18n.t('svr-columns.Simulation'),
          name: 'simulation',
          data: 'simulation',
          render: (_) => `<b class="fa-${_ ? 'exclamation-' : ''}circle fal text-${_ ? 'warning' : 'success'}"></b>`,
          sortable: false,
        },
        {
          label: this.$i18n.t('svr-columns.Record Start'),
          name: 'start',
          data: 'start',
          render: (d) => {
            console.log('d', d);
            let ts = new DateNano(d.sec * 1e6 + Math.round(d.nano / 1e3));

            return ts.customFormat('#hh#:#mm#:#ss# #DD#.#MM#.#YYYY#');
          },
          sortable: false,
        },
        {
          label: this.$i18n.t('svr-columns.Record Duration'),
          name: 'duration',
          render: (d, t, row) => {
            let recordDuration = 0;
            if (row.stop.nano > row.start.nano)
              recordDuration =
                `${row.stop.sec - row.start.sec}.` + `${row.stop.nano - row.start.nano}`.padStart(9, '0');
            else
              recordDuration =
                `${row.stop.sec - row.start.sec - 1}.` + `${row.stop.nano - row.start.nano + 1e9}`.padStart(9, '0');
            return recordDuration;
          },
          sortable: false,
        },
      ];
    },
    pcapsFields() {
      return [
        {
          label: this.$i18n.t('pcaps-columns.LVB name'),
          name: 'lvbName',
          data: 'lvbName',
          sortable: false,
        },
        {
          label: this.$i18n.t('pcaps-columns.pcap frames'),
          name: 'count',
          data: 'count',
          sortable: false,
        },
        {
          label: this.$i18n.t('pcaps-columns.download'),
          name: 'link',
          data: 'count',
          render: (d, t, row) => {
            if (parseInt(row.count) > 5e5) {
              return `<a
                                    title="${this.$t('No more than 500000 frames')}"
                                    class="btn btn-outline-dark btn-sm fa-ban fal"
                                    href="#"
                                </a>`;
            } else {
              return `<a download class="btn btn-outline-dark btn-sm fa-download fal" href="getpcap?t=${parseInt(
                this.$route.query.time / 1e6
              )}&timebefore=${parseInt(this.signalsTimeBefore / 1e6)}&timeafter=${parseInt(
                this.signalsTimeAfter / 1e6
              )}&lvb=${row._id}" >
                                </a>`;
            }
          },

          sortable: false,
        },
      ];
    },

    nanoTstart() {
      return new DateNano(this.archive.tstart).customFormat();
    },
    nanoTend() {
      return new DateNano(this.archive.tend).customFormat();
    },
    doneTodosCount() {
      return this.$store.state.todos.filter((todo) => todo.done).length;
    },
  },

  mounted() {
    console.log('$main', JSON.stringify(this.$main));
    // console.log('$tpws', this.$tpws);
    console.log('main', window.main);
    // // console.log('main', main);
    // console.log('process.env.NODE_ENV', process.env.NODE_ENV);
    window.addEventListener('tpui', () => {
      console.log('ev:tpui');
      // eslint-disable-next-line no-undef
      this.tpui = tpui;
    });
    window.addEventListener('user', () => {
      console.log('ev:user');
      this.user = this.$main.user;
    });
    // document.onreadystatechange = () => {
    //   console.log('document.readyState', document.readyState);
    //   if (document.readyState === 'complete') {
    //     this.user = this.$main.user;
    //     setTimeout(function() {
    //       console.log('timer');
    //       this.user = this.$main.user;
    //     }, 1000);
    //   }
    // };
    this.$nextTick(() => {});
    this.onTypes = this.eventTypes.map((e) => e.name);
    this.offTypes = this.eventTypes.filter((e) => this.onTypes.indexOf(e.name) < 0).map((e) => e.name);
  },
  methods: {
    swDygraph(e) {
      this.showDygraph(e);
    },
    showDygraph() {
      this.buttonsVidible = true;
      this.dygraphVidible = true;
      this.getSignals();
    },
    swSvr(e) {
      console.log('swSvr');
      this.showSvr(e);
    },
    showSvr() {
      this.buttonsVidible = true;
      this.dygraphVidible = true;
      console.log('showSvr');
      this.getSvr();
    },
    showTabs() {
      this.buttonsVidible = true;
      this.dygraphVidible = false;
    },
    dygraphData() {
      return this.$refs.signalsMeta.rowsDataSelected.map((e) => e.id);
    },
    getSignals() {
      let signals = this.dygraphData();
      console.log('signals', signals);
      let commonPart = ((signals) => {
        let common = '';
        let flag = true;
        let i = 0;
        while (flag) {
          common = common + signals[0][i];
          signals.forEach((s) => (s.indexOf(common) != 0 ? (flag = false) : 0));
          i++;
          if (i >= signals[0].length) break;
        }
        return common.substring(0, common.length - 1);
      })(signals);
      let dataUrl =
        'getsignals' +
        '?' +
        this.$qs.stringify({
          ...this.signalsQuery,
          common: commonPart,
          signals: signals.map((s) => s.substring(commonPart.length)).join(','),
        });
      axios
        .get(dataUrl)
        .then((result) => {
          // JSON responses are automatically parsed.
          let res = result.data.desc;
          console.log(res); //result.data.desc;
          res.tstart = Number(this.$route.query.time) - Number(this.$route.query.timeBefore);
          res.tend = Number(this.$route.query.time) + Number(this.$route.query.timeAfter);
          window._displayDygraph1(res);
          // this.$emit('loadedRows', this.rowsData.length);
          // this.loading = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    svrDraw(recordData) {
      let meta = { ...recordData.desc.meta, ...recordData.desc.meta.metadata },
        // data = JSON.parse(recordData.desc.data)
        data = recordData.desc.data,
        DATASET = meta.DATASET,
        labels = ['t'],
        labelsDesc = ['t'],
        quality = [];
      for (let d in DATASET)
        if (DATASET[d].p) {
          labels.push(d);
          labelsDesc.push(
            main.user.descriptionType == 'onlyDesc' && typeof DATASET[d].desc == 'string'
              ? DATASET[d].desc.replace(/\|+(.*)/g, '$1')
              : d
          );
        }

      if (labels.length != 9)
        return $.notify(
          $('#timestamp-type-toggle'),
          $.i18n(
            "Tekvel Park now supports  9-2LE compatible dataset only with 8 analog values with\n quality flags included. SV configuration is incompatible with 9-2LE and can't be displayed."
          ),
          {
            clickToHide: true,
            autoHide: false,
            elementPosition: 'down',
            className: 'error',
            showAnimation: 'show',
          }
        );

      let base64ToHex = (str) =>
        atob(str.replace(/[ \r\n]+$/, ''))
          .split('')
          .reduce(
            (a, b) => (a += (b.charCodeAt(0).toString(16).length === 1 ? '0' : '') + b.charCodeAt(0).toString(16)),
            ''
          );

      let _dy = [],
        // FIXME временный костыль для посчета smpRate. По хорошему его стоит брать из scd
        smpRate = data.reduce((a, p) => (a = p.smpCnt > a ? p.smpCnt : a), 0) + 1,
        nowSecond = data.find((p) => p.smpCnt == smpRate - 1).lvb_ts.sec - 1,
        prevSmpCount = data[0].smpCnt - 1,
        prevSec = data[0].lvb_ts.sec - 1;
      console.log(smpRate);

      for (let p of data) {
        let _p = [], //это координаты по оси У для графиков сигналов
          _pq = [], //это координаты по оси У для графиков качества сигналов
          _ts = +(`${p.lvb_ts.sec}.` + `${p.lvb_ts.nano}`.padStart(9, '0'));
        if (eventProfile.timestampType === 'process') {
          if (p.smpCnt === 0) {
            nowSecond = p.lvb_ts.sec; // - 1
            _ts = nowSecond;
          } else {
            if (Math.abs(nowSecond - p.lvb_ts.sec) > 1) nowSecond = p.lvb_ts.sec - 1;
            _ts = nowSecond + p.smpCnt / smpRate;
          }
        }

        // Обработка прорех в последовательности smpCnt
        if (p.smpCnt - 1 != prevSmpCount || _ts * 1e6 - prevSec * 1e6 > 2e5)
          _dy.push([
            _ts * 1e6,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
          ]);
        prevSmpCount = p.smpCnt == smpRate - 1 ? -1 : p.smpCnt;
        prevSec = _ts;

        _pq.push(_ts * 1e6);
        p.dataset = base64ToHex(p.dataset);
        let _dataset = p.dataset.match(/.{8}/g);
        // console.log(_dataset, p.dataset);
        // let _dataset = p.dataset.split('').map((e,i,a)=>a[a.length-i-1]).join('').match(/.{8}/g)
        // for (let i = 0; i < _dataset.length; i++){
        //     if (_dataset[i][0] == 'f'){
        //         let newP = ''
        //         for (let j = 0; j<_dataset[i].length; j++){
        //             newP += (0xF - parseInt('0x'+_dataset[i][j])).toString(16)
        //         }
        //         // _p.push(-parseInt('0x' + newP))
        //         // _p.push(-+('0x' + _dataset[i].split('').map(n=>(0xF-`0x${n}`).toString(16)).join('')))
        //         _p.push(Math.round(-+('0x' + _dataset[i].split('').map(n=>(0xF-`0x${n}`).toString(16)).join('')) * (~labels[i/2+1].indexOf('Amp')?1e-3:1e-2)))
        //     }else
        //         // _p.push(parseInt('0x'+_dataset[i]))
        //         _p.push(Math.round(+('0x' + _dataset[i]) * (~labels[i/2+1].indexOf('Amp')?1e-3:1e-2)))
        //
        //     i++
        // }
        let i = 0;
        for (let d in DATASET) {
          if (DATASET[d].p) {
            if (_dataset[i][0] == 'f') {
              // let newP = ''
              // for (let j = 0; j<_dataset[i].length; j++){
              //     newP += (0xF - parseInt('0x'+_dataset[i][j])).toString(16)
              // }
              // _p.push(-parseInt('0x' + newP))
              // _p.push(-+('0x' + _dataset[i].split('').map(n=>(0xF-`0x${n}`).toString(16)).join('')))
              _p.push(
                Math.round(
                  -+(
                    '0x' +
                    _dataset[i]
                      .split('')
                      .map((n) => (0xf - `0x${n}`).toString(16))
                      .join('')
                  ) * DATASET[d].k
                )
              );
            }
            // _p.push(parseInt('0x'+_dataset[i]))
            else _p.push(Math.round(+('0x' + _dataset[i]) * DATASET[d].k));
          } else {
            let _q;
            if (_dataset[i][0] == 'f')
              _q = Math.round(
                -+(
                  '0x' +
                  _dataset[i]
                    .split('')
                    .map((n) => (0xf - `0x${n}`).toString(16))
                    .join('')
                )
              );
            else _q = Math.round(+('0x' + _dataset[i]));
            if (_q !== 0) quality.push({ p: _p[0], t: 1, s: (0.5 / smpRate) * 1e6 + 10 });
            if (_dataset[i][_dataset[i].length - 1] == '1') {
              _pq.push(_p[_p.length - 1]);
            } else {
              _pq.push(null);
            }
          }
          i++;
        }
        _p = [..._pq, ..._p]; //графики качества должны рисоваться под графиками сигналов, чтобы не перекрывать их. Поэтому в массив кладем сначала координаты для графиков качества, а потом координаты для графиков сигналов.
        _dy.push(_p);
      }

      let graphDiv = $('<div/>').appendTo($('#${blockId} .svr-dygraph')).get(0); // document.getElementById("svr-dygraph")
      let graphContainer = document.createElement('div');
      graphDiv.style.height = '250px';
      graphDiv.style.display = 'flex';
      graphDiv.style.width = '100%';
      let graphId =
        recordData.desc.meta.lvbId +
        recordData.desc.meta.svId +
        recordData.desc.meta.dstMac +
        recordData.desc.meta.appId +
        recordData.desc.meta.simulation;
      graphDiv.dataset.rowId = graphId;
      graphDiv.className = 'osc-graph';

      let signalColors = ['#e6e614', 'green', 'red', '#68ade4', '#e6e614', 'green', 'red', '#68ade4'];
      let qualityColors = new Array(8).fill('#ffbcad');
      let allColors = [...qualityColors, ...signalColors];

      let signalLabels = labels;
      let qualityLabels = new Array(8).fill('quality');
      let allLabels = [signalLabels[0], ...qualityLabels, ...signalLabels.slice(1)];
      // _dy.shift()
      let valueRange = (_dy.map((_) => [..._].sort((a, b) => b - a)[1]).sort((a, b) => b - a)[0] + 1e3) * 1.5;
      // valueRange += valueRange*.5 +  1e3

      graphContainer.style.width = 'calc(100% - 170px)';
      graphDiv.appendChild(graphContainer);
      // console.log(valueRange);
      let srvGraph = new Dygraph(graphContainer, _dy, {
        labels: allLabels,
        colors: allColors,
        dateWindow: [timestamp - timeBefore, timestamp + timeAfter], //[_dy[0][0], _dy[_dy.length-1][0]],
        valueRange: [-valueRange, valueRange],
        series: {
          quality: {
            strokeWidth: 8,
          },
        },
        plotter: Dygraph.tpui.smoothPlotter,
        // labelsSeparateLines: true,
        legend: 'never',
        // errorBars: true,
        // underlayCallback: (canvas, area, g) => {
        //     let _q = quality[0]
        //     for (let q of quality) {
        //         let left = g.toDomCoords(_q.p, -20)[0],
        //             right = g.toDomCoords(q.p + q.s, +20)[0];
        //         canvas.fillStyle = q.t !== 1 ? "rgba(255, 255, 102, 1.0)" : "#ffbcad";
        //         canvas.fillRect(left - 1, area.y, right - left + 1, area.h);
        //     }
        //     //
        //     // var bottom_left = g.toDomCoords(1538579732469250, -20);
        //     // var top_right = g.toDomCoords(1538579732469750, +20);
        //     //
        //     // var left = bottom_left[0];
        //     // var right = top_right[0];
        //     //
        //     // canvas.fillStyle = "rgba(255, 255, 102, 1.0)";
        //     // canvas.fillRect(left, area.y, right - left, area.h);
        // },
        hideOverlayOnMouseOut: true,
        highlightCircleSize: 5,
        strokeWidth: 1.1,
        strokeBorderWidth: 0.2,
        // valueFormatter: (_val, opts, seriesName, dygraph, row, col) => {
        //     if (seriesName == 't') return new DateNano(_val).setTimezoneOffset(main.user.timezoneOffset).customFormat('#hhhh#:#mm#:#ss#.#ms##us#')
        //     let num = Math.abs(_val),
        //         _d = ['', ' к', ' М', ' Г', ' Т', ' П'],
        //         r = (_val < 0 ? '-' : '') +
        //             +`${num}`.padStart(18, '0').match(/.{3}/g).reduce((a, n) =>
        //                 a = n != '000' && a == '' ? n : a.length == 3 ? a + '.' + n : a, '')
        //             + _d[Math.floor((`${num}`.length - 1) / 3)]
        //             + (~seriesName.indexOf('Amp') ? 'А' : 'В')
        //     // console.log(seriesName, r, _val);
        //     return r
        // },
        pointClickCallback: tpui.faultprofile.pointClick,
        highlightCallback: (event, x, points) => {
          //функция создает подписи со значениями для точек вместо встроенной легенды
          let graph = $(event.currentTarget).parents('.osc-graph');
          let time = new DateNano(x)
            .setTimezoneOffset(main.user.timezoneOffset)
            .customFormat('#hhhh#:#mm#:#ss#.#ms##us#');
          graph.find('.legend-sv-time-label').html(time);
          function valueFormatter(_val, seriesName) {
            //функция переводит значения по оси У в человекочитаемый вид и добавляет единицы измерения
            let num = Math.abs(_val),
              _d = ['', ' к', ' М', ' Г', ' Т', ' П'],
              r =
                (_val < 0 ? '-' : '') +
                +`${num}`
                  .padStart(18, '0')
                  .match(/.{3}/g)
                  .reduce((a, n) => (a = n != '000' && a == '' ? n : a.length == 3 ? a + '.' + n : a), '') +
                _d[Math.floor((`${num}`.length - 1) / 3)] +
                (~seriesName.indexOf('Amp') ? 'А' : 'В');
            return r;
          }
          for (let point of points) {
            let label = point.name;
            let value = valueFormatter(point.yval, label);
            graph.find(`span[data-target="${label}"]`).html(!isNaN(parseFloat(value)) ? value : '');
          }
        },
        unhighlightCallback: () => {
          $('.osc-value').html('');
          $('.legend-sv-time-label').html('');
        },
        axes: {
          x: {
            drawAxis: true,
            ticker: Dygraph.tpui.dateTicker,
            axisLabelFormatter: Dygraph.tpui.dateAxisLabelFormatter,
          },
          y: {
            axisLabelFormatter: (y) => '',
          },
        },
        showLabelsOnHighlight: true,
        animatedZooms: true,
      });
      tpui.faultprofile.timelinesArray.push(srvGraph);

      if (tpui.faultprofile.timelinesArray.length > 1)
        Dygraph.synchronize(tpui.faultprofile.timelinesArray, {
          selection: false,
          zoom: true,
          range: false,
        });

      let _iedHtml = `
                    <li class="legend-ied-name-li">
                        <div class="legend-ied-name" title="${meta.iedName}">
                            ${meta.iedName}
                        </div>
                    </li>`,
        _signalHtml = `
                    <li class="legend-data-object-name-li">
                        <div class="legend-data-object-name">
                        <span ></span>
                            <div id="label-container__${graphId}" class="legend-sv-signal-labels">
                                ${labelsDesc
                                  .map((l, i) =>
                                    i
                                      ? '<div class="osc-label-container"><span class="osc-label" style="color:' +
                                        signalColors[i - 1] +
                                        ';cursor:pointer;" svDatasetSignalIdx="' +
                                        i +
                                        '" title="' +
                                        l +
                                        '">' +
                                        l +
                                        '</span><span class="osc-value" data-target="' +
                                        labels[i] +
                                        '"></span ></div>'
                                      : ''
                                  )
                                  .join('')}
                                <div class="legend-sv-time-label"></div>
                            </div>
                        </div>
                    </li>`;
      if (main.user.descriptionType == 'onlyDesc') {
        _iedHtml = '';
      }
      if (main.user.descriptionType != 'onlyName') {
        _iedHtml += `
                    <li class="legend-ied-name-li">
                        <span>
                            ${meta.iedDesc ? meta.iedDesc : ''}
                        </span>
                    </li>`;
      }
      let yLabel = $(`
                <div class="wh-graph-signal-name">
                    <ul>
                        ${_iedHtml}
                        <li class="legend-control-block-name-li">
                            <span class="legend-control-block-name ${meta.simulation ? 'simulated' : ''}" title="${
        meta.svId
      }">
                                ${meta.svId}
                            </span>
                        </li>
                        ${_signalHtml}
                    </ul>
                </div>`);

      graphDiv.insertBefore(yLabel.get(0), graphDiv.firstChild);

      $(yLabel).prepend(
        `<button class="remove-btn close" onclick="eventProfile.removeGraph(event)" title="${$.i18n(
          'Hide the graph'
        )}"><span aria-hidden="true">&times;</span></button>`
      );
      $(`#label-container__${graphId} span`).on('click', (label) => {
        $(label.currentTarget).toggleClass('osc-label');
        srvGraph.setVisibility(
          +$(label.currentTarget).attr('svDatasetSignalIdx') + 7,
          $(label.currentTarget).hasClass('osc-label')
        );
        srvGraph.setVisibility(
          $(label.currentTarget).attr('svDatasetSignalIdx') - 1,
          $(label.currentTarget).hasClass('osc-label')
        );
      });

      return 0;
    },
    getSvr() {
      let lines = this.$refs.svrMeta.rowsDataSelected;
      console.log('setSvr', parseInt(this.signalsTimeBefore), parseInt(this.signalsTimeAfter));
      $(`#svr-dygraph`).empty();
      lines.forEach((l) => {
        let dataUrl =
          'oscfiles' +
          '?' +
          this.$qs.stringify({
            t: parseInt(this.$route.query.time),
            prev: parseInt(this.signalsTimeBefore),
            next: parseInt(this.signalsTimeAfter),

            lvb: l.lvb_id,
            svId: l.svId,
            dstMac: l.dstMac,
            appId: l.appId,
            simulation: l.simulation,
          });
        axios
          .get(dataUrl)
          .then((result) => {
            let res = result.data.desc;
            console.log('ans', result.data);
            console.log(
              'svrDraw',
              'svr-dygraph',
              Number(parseInt(this.$route.query.time)),
              Number(parseInt(this.$route.query.timeBefore)),
              Number(parseInt(this.$route.query.timeAfter))
            );
            svrDraw(
              result.data,
              'signals-dygraph-canvas',
              Number(parseInt(this.$route.query.time)),
              Number(parseInt(this.signalsTimeBefore)),
              Number(parseInt(this.signalsTimeAfter))
            );
          })
          .catch((e) => {
            this.errors.push(e);
            this.loading = false;
          });
      });
      // let dataUrl =
      //   'oscfiles' +
      //   '?' +
      //   this.$qs.stringify({
      //     t: parseInt(this.$route.query.time),
      //     prev: parseInt(this.signalsTimeBefore),
      //     next: parseInt(this.signalsTimeAfter),
      //
      //     cluster: this.$route.query.cluster,
      //     common: commonPart,
      //     signals: signals.map((s) => s.substring(commonPart.length)).join(','),
      //   });
      // axios
      //   .get(dataUrl)
      //   .then((result) => {
      //     // JSON responses are automatically parsed.
      //     let res = result.data.desc;
      //     console.log(res); //result.data.desc;
      //     res.tstart = Number(this.$route.query.time) - Number(this.$route.query.timeBefore);
      //     res.tend = Number(this.$route.query.time) + Number(this.$route.query.timeAfter);
      //     window._displayDygraph1(res);
      //     // this.$emit('loadedRows', this.rowsData.length);
      //     // this.loading = false;
      //   })
      //   .catch((e) => {
      //     this.errors.push(e);
      //     this.loading = false;
      //   });
    },

    switchTab(tab) {
      switch (tab) {
        case 'signals':
          if (this.signalsCount > 0) {
            this.currentTab = 'signals';
          }
          break;
        case 'svr':
          if (this.svrCount > 0) {
            this.currentTab = 'svr';
          }
          break;
        case 'pcaps':
          if (this.pcapsCount > 0) {
            this.currentTab = 'pcaps';
          }
          break;
      }
    },
    loadedSignals(rows) {
      this.signalsCount = rows;
    },
    loadedSvr(rows) {
      this.svrCount = rows;
    },
    loadedPcaps(rows) {
      this.pcapsCount = rows;
    },
    switchType(type) {
      console.log('t', type);
      if (this.onTypes.indexOf(type.name) < 0) {
        this.onTypes.push(type.name);
      } else {
        this.onTypes = this.onTypes.filter((e) => e != type.name);
      }
      this.offTypes = this.eventTypes.filter((e) => this.onTypes.indexOf(e.name) < 0).map((e) => e.name);
    },
  },
  watch: {
    signalsQuery: {
      deep: true,
      handler: function (v) {
        console.log('signalsQuery', JSON.stringify(v));
      },
    },
  },
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
