<template>
  <div id="signals-table" class="container">
    <div class="event-profile__title mt-5">
      <el-button type="primary" plain icon="el-icon-arrow-left" @click="goBackToClusters">{{
        $t('Go back')
      }}</el-button>
      <template v-if="!editClusterName"
        ><h1>
          <span>
            {{ $t('Cluster name') }}:&nbsp;<editable-span
              :class="{ 'gen-cluster-name': !clusterNameReal }"
              v-model="clusterName"
              @change="saveClusterName"
            ></editable-span>
          </span>

          <!--          <editable-text-->
          <!--            :label="$t('Cluster name')"-->
          <!--            :text="clusterName"-->
          <!--            :text-class="{ 'gen-cluster-name': !clusterNameReal }"-->
          <!--            @change="saveClusterName"-->
          <!--          ></editable-text>-->
        </h1>
      </template>
      <template v-else>
        <div class="event-profile__title mt-5">
          <el-form :inline="true">
            <el-input :placeholder="$t('Cluster name')" v-model="clusterNameEdit"> </el-input>
            <el-button type="success" icon="el-icon-check" @click="saveClusterName"></el-button>
          </el-form>
        </div>
      </template>
    </div>
    <template v-if="clusterObj.leftDT !== clusterObj.rightDT"
      ><small>{{
        $t(`Showing event summary for time interval from {0} to {1}`, [
          [clusterObj.locLeftDate.date, clusterObj.locLeftDate.year, clusterObj.leftDT].join(' '),
          [clusterObj.locRightDate.date, clusterObj.locRightDate.year, clusterObj.rightDT].join(' '),
        ])
      }}</small></template
    >
    <template v-else
      ><small>{{
        $t(`Showing event summary for time {0}`, [
          [clusterObj.locLeftDate.date, clusterObj.locLeftDate.year, clusterObj.leftDT].join(' '),
        ])
      }}</small></template
    >
    <br />
    <span>
      {{ $t('Cluster description') }}:&nbsp;<editable-span
        :class="{ 'gen-cluster-name': !clusterNameReal }"
        v-model="clusterDesc"
        multiline
        @change="saveClusterDesc"
      ></editable-span>
    </span>
    <!--
    <div>{{ $t('Tags') }}: <tags-list></tags-list></div>
-->

    <a v-if="buttonsVidible && dygraphVidible" class="ml-2 event-profile__show-tabs-btn" href="#" @click="showTabs"
      >Показать вкладки</a
    >
    <a
      v-if="buttonsVidible && !dygraphVidible"
      class="ml-2 event-profile__show-graphs-btn"
      href="#"
      @click="showDygraph"
      >Показать графики</a
    >
    <div class="event-profile__content container">
      <div v-show="!dygraphVidible" class="collapse show">
        <ul class="nav nav-tabs card-header-tabs mt-4">
          <li class="nav-item" @click="switchTab('events')">
            <div
              class="signal-btn btn nav-link"
              :class="{ active: currentTab === 'events', disabled: eventsCount === 0 }"
            >
              <h2>
                {{ $t('signals-tab.Events') }} &nbsp;<small class="badge badge-primary">{{ eventsCount }}</small>
              </h2>
            </div>
          </li>
          <li class="nav-item" @click="switchTab('signals')">
            <div
              class="signal-btn btn nav-link"
              id="signals-btn1"
              :class="{ active: currentTab === 'signals', disabled: signalsCount === 0 }"
            >
              <h2>
                {{ $t('signals-tab.Signals') }} &nbsp;<small class="badge badge-primary">{{ signalsCount }}</small>
              </h2>
            </div>
          </li>
          <li class="nav-item" @click="switchTab('svr')">
            <div
              class="signal-btn btn nav-link"
              id="svr-btn1"
              :class="{ active: currentTab === 'svr', disabled: svrCount === 0 }"
            >
              <h2>
                {{ $t('signals-tab.Sampled values records') }} &nbsp;<small class="badge badge-primary">{{
                  svrCount
                }}</small>
              </h2>
            </div>
          </li>
          <li class="nav-item" @click="switchTab('pcaps')">
            <div
              class="signal-btn btn nav-link"
              id="pcaps-btn1"
              :class="{ active: currentTab === 'pcaps', disabled: pcapsCount === 0 }"
            >
              <h2>
                {{ $t('signals-tab.PCAPs') }} &nbsp;<small class="badge badge-primary">{{ pcapsCount }}</small>
              </h2>
            </div>
          </li>
        </ul>
        <br />
        <events-table
          ref="eventsTable"
          v-show="currentTab === 'events'"
          :no-search="true"
          :fields="eventsFields"
          :off-types="offTypes"
          :url="eventsUrl"
          :query="eventsQuery"
          :ext-buttons="eventsButtons"
          @ext-action="extAction"
          :length-menu="lengthMenu"
          @loadedRows="loadedEvents"
          @showDygraph="swDygraph"
        >
        </events-table>
        <signals-meta
          ref="signalsMeta"
          v-show="currentTab === 'signals'"
          :no-search="true"
          :fields="fields"
          :off-types="offTypes"
          :url="signalsUrl"
          :query="signalsQuery"
          :length-menu="lengthMenu"
          @loadedRows="loadedSignals"
          @showDygraph="swDygraph"
        >
        </signals-meta>
        <svr-meta
          ref="svrMeta"
          v-show="currentTab === 'svr'"
          :no-search="true"
          :fields="svrFields"
          :off-types="offTypes"
          :url="oscsUrl"
          :query="oscsQuery"
          :length-menu="lengthMenu"
          :hide-bottom="true"
          @loadedRows="loadedSvr"
          @open-selected="swSvr"
        >
        </svr-meta>
        <pcaps-meta
          ref="pcapsMeta"
          v-show="currentTab === 'pcaps'"
          :no-search="true"
          :fields="pcapsFields"
          :off-types="offTypes"
          :url="pcapsUrl"
          :query="pcapsQuery"
          :hide-bottom="true"
          :length-menu="lengthMenu"
          @loadedRows="loadedPcaps"
          @showDygraph="swDygraph"
        >
        </pcaps-meta>
      </div>
      <a class="up-btn fal fa-angle-up" href="#signals" style="display: none"></a>
      <div v-if="dygraphVidible" class="mt-4 row" id="signals-dygraph">
        <div id="timer-form" style="padding: 5px 0 5px 50px">
          <div id="timer-back"></div>
          <form class="form-inline">
            <div class="input-group col-md-3" id="timer-input-t1-group">
              <div class="input-group-prepend">
                <span class="input-group-text">T1</span>
              </div>
              <input class="form-control" id="timer-input-t1" type="text" placeholder="0" readonly="" />
            </div>
            <div class="input-group col-md-3" id="timer-input-t2-group">
              <div class="input-group-prepend">
                <span class="input-group-text">T2</span>
              </div>
              <input class="form-control" id="timer-input-t2" type="text" placeholder="0" readonly="" />
            </div>
            <div class="input-group col-md-2" id="timer-input-dt-group">
              <div class="input-group-prepend">
                <span class="input-group-text">ΔT</span>
              </div>
              <input class="form-control" id="timer-input-dt" type="text" placeholder="0" readonly="" />
            </div>
            <div class="input-group col-md-1">
              <button
                class="btn btn-default"
                id="timer-reset-button"
                type="button"
                onclick="tpui.faultprofile.timer.reset()"
              >
                Сброс
              </button>
            </div>
          </form>
        </div>
        <div id="vue_cluster-signals-dygraph-canvas" style="position: relative; width: 100%">
          <div class="position-relative" id="graphs"></div>
          <div class="mb-2 d-none" id="timestamp-type-toggle">
            <div class="timestamp-type-toggle__background"></div>
            <div class="timestamp-type-toggle__title mr-2">Тип метки времени:</div>
            <div class="btn-group btn-group-toggle" data-toggle="buttons">
              <label class="btn btn-sm btn-secondary"
                >Процесс<input
                  type="radio"
                  name="options"
                  id="timestamp-type-toggle__process"
                  autocomplete="off"
                  onchange="eventProfile.toggleTimestampType()" /></label
              ><label class="btn btn-sm btn-secondary active"
                >Сетевая<input
                  type="radio"
                  name="options"
                  id="timestamp-type-toggle__network"
                  autocomplete="off"
                  onchange="eventProfile.toggleTimestampType()"
              /></label>
            </div>
          </div>
          <div class="position-relative" id="svr-dygraph"></div>
        </div>
      </div>
      <div class="loading" style="display: none">
        <div class="wrapper"><div class="cssload-loader"></div></div>
      </div>
    </div>

    <a href="?p=wheel" class="wh-back d-none"
      >{{ $t('Back to') }} <span class="fal fa-asterisk"></span> {{ $t('Chart') }}</a
    >
    <div class="wh-page">
      <h2 v-if="archive && !isNaN(archive.tstart) && !isNaN(archive.tend)" class="events-archive__title">
        {{ $t('Events log from $1 to $2', [`${nanoTstart}`, `${nanoTend}`]) }}
      </h2>
    </div>
  </div>
</template>

<script>
// import ArchiveEventsModal from './ArchiveEventsModal';
// import ExportEventsModal from './ExportEventsModal';
import DateNano from '../utils/format.date.time';
import SignalsMeta from './DataTable/SignalsMeta';
import SvrMeta from './DataTable/SvrMeta';
import PcapsMeta from './DataTable/PcapsMeta';

import axios from 'axios';
import EventsTable from '@/components/DataTable/EventsTable';
import ScrollTo from 'vue-scrollto';
import TagsList from './TagsList';
import EditableText from './EditableText';
import EditableSpan from './EditableSpan';

export default {
  name: 'clusters-events-page',
  components: { EventsTable, SignalsMeta, SvrMeta, PcapsMeta, TagsList, EditableText, EditableSpan },
  props: {
    cluster: String,
    page: { type: Number, default: 1 },
    archive: Object,
    eventsSearchStr: String,
  },
  data: () => {
    return {
      scrollOptions: {
        container: 'body',
        easing: 'ease-in-out',
        offset: -60,
        force: false,
        cancelable: true,
        x: false,
        y: true,
      },
      tagsRef: [],
      prevRoute: null,
      clusterObj: {},
      clusterName: '',
      clusterNameReal: false,
      clusterDescReal: false,
      clusterNameEdit: '',
      clusterNameEv: '',
      clusterNameDT: '',
      clusterDesc: '',
      clusterDescEdit: '',
      clusterLeft: 0,
      clusterRight: 0,
      // clusterSize: 0,
      input3: '',
      select: undefined,
      editClusterName: false,
      editClusterDesc: false,
      d: undefined,
      lang: undefined,
      v: false,
      vv: false,
      search: '',
      quickSearch: '',
      onTypes: [],
      offTypes: [],
      lengthMenu: [7, 17, 50, 100, 300, 500, 1000, 5000, 10000, 25000],
      tpui: undefined,
      user: undefined,
      eventsCount: 0,
      signalsCount: 0,
      svrCount: 0,
      pcapsCount: 0,
      currentTab: 'events',
      dygraphVidible: false,
      buttonsVidible: false,
      errors: [],
      eventsButtons: [
        {
          name: 'print',
          label: 'Print',
        },
        {
          name: 'export-sub',
          label: 'Export',
          children: [
            {
              name: 'pdf',
              label: 'PDF',
              extAction: 'eventsExportPdf',
            },
            {
              name: 'csv',
              label: 'CSV',
              extAction: 'eventsExportCsv',
            },
            {
              name: 'xls',
              label: 'Excel',
              extAction: 'eventsExportXls',
            },
          ],
        },
        // {
        //   name: 'select-all',
        //   label: 'Select all',
        //   action: 'selectAll',
        // },
        // {
        //   name: 'view-selected',
        //   label: 'View selected',
        //   action: 'openSelected',
        // },
      ],
    };
  },
  computed: {
    clusterNameGen() {
      if (this.clusterName) {
        return this.clusterName;
      } else {
        if (this.clusterNameEv) {
          return this.clusterNameEv;
        } else {
          return this.clusterNameDT;
        }
      }
    },
    clusterDescGen() {
      if (this.clusterDesc) {
        return this.clusterDesc;
      } else {
        if (this.clusterNameEv) {
          return this.clusterNameEv;
        }
      }
    },
    offset() {
      return this.user && this.user.preferences && this.user.preferences.timezoneOffset;
    },
    dateNow() {
      try {
        return new DateNano(this.$route.query.time).setTimezoneOffset(this.offset).customFormat();
      } catch (e) {}
    },
    dateBefore() {
      try {
        return new DateNano(this.$route.query.time - this.$route.query.timeBefore)
          .setTimezoneOffset(this.offset)
          .customFormat();
      } catch (e) {}
    },
    dateAfter() {
      try {
        return new DateNano(Number(this.$route.query.time) + Number(this.$route.query.timeAfter))
          .setTimezoneOffset(this.offset)
          .customFormat();
      } catch (e) {}
    },
    eventsUrl() {
      return 'events2-exp';
    },
    signalsUrl() {
      return 'getsignalsmeta';
    },
    pcapsUrl() {
      return 'getpcapsmeta';
    },
    oscsUrl() {
      return 'oscs';
    },
    signalsTimeMiddle() {
      return this.$route.name === 'clusters-profile'
        ? Math.ceil((this.clusterLeft + this.clusterRight) / 2)
        : Number(this.$route.query.time);
    },
    signalsTimeBefore() {
      const timeBefore =
        this.$route.name === 'clusters-profile'
          ? this.signalsTimeMiddle - this.clusterLeft
          : Number(this.$route.query.timeBefore);
      return this.$tpui && timeBefore + this.$tpui.faultprofile.tbefore * 1e6;
    },
    signalsTimeAfter() {
      const timeAfter =
        this.$route.name === 'clusters-profile'
          ? this.clusterRight - this.signalsTimeMiddle
          : Number(this.$route.query.timeAfter);
      return this.$tpui && timeAfter + this.$tpui.faultprofile.tafter * 1e6;
    },
    eventsQuery() {
      return {
        cluster: this.$route.query.cluster,
      };
    },
    signalsQuery() {
      return {
        cluster: this.$route.query.cluster,
      };
    },
    oscsQuery() {
      return {
        cluster: this.$route.query.cluster,
      };
    },
    pcapsQuery() {
      return {
        cluster: this.$route.query.cluster,
      };
    },
    eventTypes() {
      return [
        { name: 'GO', label: this.$i18n.t('Goose') },
        { name: 'SV', label: this.$i18n.t('SV') },
        { name: 'SRV', label: this.$i18n.t('Service events') },
        { name: 'ARP', label: this.$i18n.t('ARP') },
        { name: 'MMS', label: this.$i18n.t('MMS') },
        { name: 'USER', label: this.$i18n.t('User events') },
      ];
    },
    _eq() {
      return {
        GO: this.$i18n.t('Goose'),
        SV: this.$i18n.t('SV'),
        SRV: this.$i18n.t('Service events'),
        ARP: this.$i18n.t('ARP'),
        MMS: this.$i18n.t('MMS'),
        USER: this.$i18n.t('User events'),
      };
    },

    __eq() {
      let eq = {};
      for (let t in this._eq) {
        eq[this._eq[t]] = t;
      }
      return eq;
    },
    headerVisible() {
      return this.archive && !isNaN(this.archive.tstart) && !isNaN(this.archive.tend);
    },

    descriptionType() {
      return this.user && this.user.preferences && this.user.preferences.descriptionType;
    },

    eventsFields() {
      return [
        {
          label: this.$i18n.t('Date & Time'),
          name: 'timeDT',
          data: 'timeDT',
          sortable: true,
        },
        {
          label: this.$i18n.t('Type'),
          name: 'typeDT',
          data: 'typeDT',
          sortable: true,
        },
        {
          label: this.$i18n.t('IED'),
          name: 'iedNameDT',
          data: 'iedNameDT',
          sortable: true,
        },
        {
          label: this.$i18n.t('Control Block Name'),
          name: 'controlBlockDT',
          data: 'controlBlockDT',
          sortable: true,
        },
        {
          label: this.$i18n.t('Description'),
          name: 'descriptionsDT',
          data: 'descriptionsDT',
          sortable: true,
        },
      ];
    },
    fields() {
      return [
        {
          label: this.$i18n.t('signals-columns.IED Name'),
          name: 'iedName',
          data: 'iedName',
          visible: this.descriptionType !== 'onlyDesc',
          sortable: false,
        },
        {
          label: this.$i18n.t('signals-columns.IED description'),
          name: 'iedDesc',
          data: 'iedDesc',
          visible: this.descriptionType !== 'onlyName',
          sortable: false,
        },
        {
          label: this.$i18n.t('signals-columns.Signal'),
          name: 'signalName',
          data: 'signalName',
          visible: this.descriptionType !== 'onlyDesc',
          sortable: false,
        },
        {
          label: this.$i18n.t('signals-columns.Signal description'),
          name: 'desc',
          data: 'desc',
          visible: this.descriptionType !== 'onlyName',
          render: (d, t, row) => `<span data-name="${row.signalName}">${d.replace(/\|+(.*)/g, '$1')}</span>`,

          sortable: false,
        },
        {
          label: this.$i18n.t('signals-columns.Control Block Name'),
          name: 'gooseCB',
          data: 'gooseCB',
          sortable: false,
        },
        {
          label: this.$i18n.t('signals-columns.Simulation'),
          name: 'simulation',
          data: 'simulation',
          render: (_) => `<b class="fa-${_ ? 'exclamation-' : ''}circle fal text-${_ ? 'warning' : 'success'}"></b>`,

          sortable: false,
        },
      ];
    },
    svrFields() {
      return [
        {
          label: this.$i18n.t('svr-columns.LVB'),
          name: 'lvbName',
          data: 'lvbName',
          sortable: false,
        },
        {
          label: this.$i18n.t('svr-columns.SVID'),
          name: 'svId',
          data: 'svId',
          sortable: false,
        },
        {
          label: this.$i18n.t('svr-columns.Destination MAC'),
          name: 'dstMac',
          data: 'dstMac',
          sortable: false,
        },
        {
          label: this.$i18n.t('svr-columns.APPID'),
          name: 'appId',
          data: 'appId',
          sortable: false,
        },
        {
          label: this.$i18n.t('svr-columns.Simulation'),
          name: 'simulation',
          data: 'simulation',
          render: (_) => `<b class="fa-${_ ? 'exclamation-' : ''}circle fal text-${_ ? 'warning' : 'success'}"></b>`,
          sortable: false,
        },
        {
          label: this.$i18n.t('svr-columns.Record Start'),
          name: 'start',
          data: 'start',
          render: (d) => {
            console.log('d', d);
            let ts = new DateNano(d.sec * 1e6 + Math.round(d.nano / 1e3));

            return ts.customFormat('#hh#:#mm#:#ss# #DD#.#MM#.#YYYY#');
          },
          sortable: false,
        },
        {
          label: this.$i18n.t('svr-columns.Record Duration'),
          name: 'duration',
          render: (d, t, row) => {
            let recordDuration = 0;
            if (row.stop.nano > row.start.nano)
              recordDuration =
                `${row.stop.sec - row.start.sec}.` + `${row.stop.nano - row.start.nano}`.padStart(9, '0');
            else
              recordDuration =
                `${row.stop.sec - row.start.sec - 1}.` + `${row.stop.nano - row.start.nano + 1e9}`.padStart(9, '0');
            return recordDuration;
          },
          sortable: false,
        },
      ];
    },
    pcapsFields() {
      return [
        {
          label: this.$i18n.t('pcaps-columns.LVB name'),
          name: 'lvbName',
          data: 'lvbName',
          sortable: false,
        },
        {
          label: this.$i18n.t('pcaps-columns.pcap frames'),
          name: 'count',
          data: 'count',
          sortable: false,
        },
        {
          label: this.$i18n.t('pcaps-columns.download'),
          name: 'link',
          data: 'count',
          render: (d, t, row) => {
            const time = this.signalsTimeMiddle / 1e6;
            const timebefore = this.signalsTimeBefore / 1e6;
            const timeafter = this.signalsTimeAfter / 1e6;
            console.log(time, timebefore, timeafter, row);
            if (parseInt(row.count) > 5e5) {
              return `<a
                                    title="${this.$t('No more than 500000 frames')}"
                                    class="btn btn-outline-dark btn-sm fa-ban fal"
                                    href="#"
                                </a>`;
            } else {
              return `<a download class="btn btn-outline-dark btn-sm fa-download fal" href="getpcap?t=${parseInt(
                time
              )}&timebefore=${parseInt(timebefore)}&timeafter=${parseInt(timeafter)}&lvb=${row._id}" >
                                </a>`;
            }
          },

          sortable: false,
        },
      ];
    },

    nanoTstart() {
      return new DateNano(this.archive.tstart).customFormat();
    },
    nanoTend() {
      return new DateNano(this.archive.tend).customFormat();
    },
    doneTodosCount() {
      return this.$store.state.todos.filter((todo) => todo.done).length;
    },
  },

  mounted() {
    this.getClusterData();
    this.input3 = ' ';
    console.log('$main', JSON.stringify(this.$main));
    // console.log('$tpws', this.$tpws);
    console.log('main', window.main);
    // // console.log('main', main);
    // console.log('process.env.NODE_ENV', process.env.NODE_ENV);
    window.addEventListener('user', () => {
      console.log('ev:user');
      this.user = this.$main.user;
    });
    // document.onreadystatechange = () => {
    //   console.log('document.readyState', document.readyState);
    //   if (document.readyState === 'complete') {
    //     this.user = this.$main.user;
    //     setTimeout(function() {
    //       console.log('timer');
    //       this.user = this.$main.user;
    //     }, 1000);
    //   }
    // };

    this.onTypes = this.eventTypes.map((e) => e.name);
    this.offTypes = this.eventTypes.filter((e) => this.onTypes.indexOf(e.name) < 0).map((e) => e.name);
    this.$nextTick(() => {
      ScrollTo.scrollTo(`#tpws_menu`, 300, this.scrollOptions);
    });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  methods: {
    editCluster() {
      this.editClusterName = true;
      this.clusterNameEdit = this.clusterNameGen;
    },
    editClusterDescr() {
      this.editClusterDesc = true;
      this.clusterDescEdit = this.clusterDescGen;
    },
    goBackToClusters() {
      const lastRoute = localStorage.getItem('lastRoute');
      console.log('this.$router', this.$router);
      if (lastRoute === 'clusters-index') {
        this.$router.push({ name: 'clusters-index', params: { cluster: this.$route.query.cluster } });
      } else {
        this.$router.push({ name: 'clusters-index' });
      }
    },
    getClusterData(e) {
      console.log('save', e, this.$route.query.cluster);
      this.editClusterName = false;
      this.clusterNameReal = false;
      this.clusterDescReal = false;
      axios
        .post('get-cluster-data', { _id: this.$route.query.cluster })
        .then((response) => {
          // JSON responses are automatically parsed.
          console.log('res', response.data.data.result);
          this.clusterObj = response.data.data.result;
          if (response.data.data.result.name) {
            this.clusterName = response.data.data.result.name;
            this.clusterNameReal = true;
          } else {
            if (response.data.data.result.nameEv) {
              this.clusterName = response.data.data.result.nameEv;
            } else {
              this.clusterName = response.data.data.result.nameDT;
            }
          }
          if (response.data.data.result.desc) {
            this.clusterDesc = response.data.data.result.desc;
            this.clusterDescReal = true;
          } else {
          }

          // this.clusterNameEv = response.data.data.result.nameEv;
          // this.clusterNameDT = response.data.data.result.nameDT;
          this.clusterLeft = response.data.data.result.left;
          this.clusterRight = response.data.data.result.right;
          this.clusterSize = response.data.data.result.size;
          this.loading = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    saveClusterName(e) {
      console.log('save', e, this.$route.query.cluster);
      this.editClusterName = false;
      axios
        .post('set-cluster-name', { _id: this.$route.query.cluster, name: e })
        .then((response) => {
          // JSON responses are automatically parsed.

          this.loading = false;
          this.getClusterData();
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    saveClusterDesc(e) {
      console.log('save', e, this.$route.query.cluster);
      this.editClusterDesc = false;
      axios
        .post('set-cluster-desc', { _id: this.$route.query.cluster, desc: e })
        .then((response) => {
          // JSON responses are automatically parsed.

          this.loading = false;
          this.getClusterData();
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    extAction(a, e) {
      if (a) {
        this[a](e);
      }
    },
    eventsExportXls(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl =
        `/projects/${this.$tpws.projectName}/cluster-events2-xls` +
        '?' +
        this.$qs.stringify({
          cluster: this.$route.query.cluster,
        });
      axios
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.$tpws.displayName} ` + this.$i18n.t('analysis report') + `.xlsx`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    eventsExportPdf(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl =
        `/projects/${this.$tpws.projectName}/cluster-events2-pdf` +
        '?' +
        this.$qs.stringify({
          cluster: this.$route.query.cluster,
        });
      axios
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.$tpws.displayName} ` + this.$i18n.t('analysis report') + `.pdf`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    eventsExportCsv(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl =
        `/projects/${this.$tpws.projectName}/cluster-events2-csv` +
        '?' +
        this.$qs.stringify({
          cluster: this.$route.query.cluster,
        });
      axios
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.$tpws.displayName} ` + this.$i18n.t('analysis report') + `.csv`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },

    swSvr(e) {
      console.log('swSvr');
      this.showSvr(e);
    },
    showSvr() {
      this.buttonsVidible = true;
      this.dygraphVidible = true;
      console.log('showSvr');
      this.getSvr();
    },
    swDygraph(e) {
      this.showDygraph(e);
    },
    showDygraph() {
      this.buttonsVidible = true;
      this.dygraphVidible = true;
      this.getSignals();
    },
    showTabs() {
      this.buttonsVidible = true;
      this.dygraphVidible = false;
    },
    dygraphData() {
      return this.$refs.signalsMeta.rowsDataSelected.map((e) => e.id);
    },
    getSignals() {
      let signals = this.dygraphData();
      console.log('signals', signals);
      let commonPart = ((signals) => {
        let common = '';
        let flag = true;
        let i = 0;
        while (flag) {
          common = common + signals[0][i];
          signals.forEach((s) => (s.indexOf(common) != 0 ? (flag = false) : 0));
          i++;
          if (i >= signals[0].length) break;
        }
        return common.substring(0, common.length - 1);
      })(signals);
      let dataUrl =
        'getsignals' +
        '?' +
        this.$qs.stringify({
          // t: parseInt(this.$route.query.time),
          // prev: parseInt(this.signalsTimeBefore),
          // next: parseInt(this.signalsTimeAfter),

          cluster: this.$route.query.cluster,
          common: commonPart,
          signals: signals.map((s) => s.substring(commonPart.length)).join(','),
        });
      axios
        .get(dataUrl)
        .then((result) => {
          // JSON responses are automatically parsed.
          let res = result.data.desc;
          console.log(res); //result.data.desc;
          res.tstart = Number(this.$route.query.time) - Number(this.$route.query.timeBefore);
          res.tend = Number(this.$route.query.time) + Number(this.$route.query.timeAfter);
          window._displayDygraph1(res);
          // this.$emit('loadedRows', this.rowsData.length);
          // this.loading = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    getSvr() {
      let lines = this.$refs.svrMeta.rowsDataSelected;
      lines.forEach((l) => {
        let dataUrl =
          'oscfiles' +
          '?' +
          this.$qs.stringify({
            // t: parseInt(this.$route.query.time),
            // prev: parseInt(this.signalsTimeBefore),
            // next: parseInt(this.signalsTimeAfter),
            cluster: this.$route.query.cluster,

            lvb: l.lvb_id,
            svId: l.svId,
            dstMac: l.dstMac,
            appId: l.appId,
            simulation: l.simulation,
          });
        axios
          .get(dataUrl)
          .then((result) => {
            let res = result.data.desc;
            console.log('ans', result.data);
            svrDraw(
              result.data,
              'signals-dygraph-canvas',
              Number(parseInt(result.data.time.t)),
              Number(parseInt(result.data.time.timeBefore)),
              Number(parseInt(result.data.time.timeAfter))
            );
          })
          .catch((e) => {
            this.errors.push(e);
            this.loading = false;
          });
      });
      // let dataUrl =
      //   'oscfiles' +
      //   '?' +
      //   this.$qs.stringify({
      //     t: parseInt(this.$route.query.time),
      //     prev: parseInt(this.signalsTimeBefore),
      //     next: parseInt(this.signalsTimeAfter),
      //
      //     cluster: this.$route.query.cluster,
      //     common: commonPart,
      //     signals: signals.map((s) => s.substring(commonPart.length)).join(','),
      //   });
      // axios
      //   .get(dataUrl)
      //   .then((result) => {
      //     // JSON responses are automatically parsed.
      //     let res = result.data.desc;
      //     console.log(res); //result.data.desc;
      //     res.tstart = Number(this.$route.query.time) - Number(this.$route.query.timeBefore);
      //     res.tend = Number(this.$route.query.time) + Number(this.$route.query.timeAfter);
      //     window._displayDygraph1(res);
      //     // this.$emit('loadedRows', this.rowsData.length);
      //     // this.loading = false;
      //   })
      //   .catch((e) => {
      //     this.errors.push(e);
      //     this.loading = false;
      //   });
    },
    switchTab(tab) {
      switch (tab) {
        case 'events':
          if (this.eventsCount > 0) {
            this.currentTab = 'events';
          }
          break;
        case 'signals':
          if (this.signalsCount > 0) {
            this.currentTab = 'signals';
          }
          break;
        case 'svr':
          if (this.svrCount > 0) {
            this.currentTab = 'svr';
          }
          break;
        case 'pcaps':
          if (this.pcapsCount > 0) {
            this.currentTab = 'pcaps';
          }
          break;
      }
    },
    loadedEvents(rows) {
      this.eventsCount = rows;
    },
    loadedSignals(rows) {
      this.signalsCount = rows;
    },
    loadedSvr(rows) {
      this.svrCount = rows;
    },
    loadedPcaps(rows) {
      this.pcapsCount = rows;
    },
    switchType(type) {
      console.log('t', type);
      if (this.onTypes.indexOf(type.name) < 0) {
        this.onTypes.push(type.name);
      } else {
        this.onTypes = this.onTypes.filter((e) => e != type.name);
      }
      this.offTypes = this.eventTypes.filter((e) => this.onTypes.indexOf(e.name) < 0).map((e) => e.name);
    },
  },
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
