import Vue from 'vue';
import Vuex from 'vuex';
import VueResource from 'vue-resource';
import clients from './modules/clients';
import createPersistedState from 'vuex-persistedstate';
import axios from 'axios';
import SecureLS from 'secure-ls';
var ls = new SecureLS({ isCompression: false });
import DateNano from '../utils/format.date.time';

Vue.use(Vuex);
Vue.use(VueResource);

export const FILTER_MUTATIONS = {
  SET_LOADED: 'SET_LOADED',
  SET_ITEMS: 'SET_ITEMS',
  SET_PUSH_ITEM_VALUE: 'SET_PUSH_ITEM_VALUE',
  SET_SHIFT_ITEM_VALUE: 'SET_SHIFT_ITEM_VALUE',
  SET_BOUNDS: 'SET_BOUNDS',
  SET_VALUES: 'SET_VALUES',
  SET_TEXT_SEARCH: 'SET_TEXT_SEARCH',
  SET_CLUSTER_SIZE: 'SET_CLUSTER_SIZE',
  SET_CLUSTER_DATE: 'SET_CLUSTER_DATE',
};

export const REPORTS_MUTATIONS = {
  ADD_PENDING: 'ADD_PENDING',
  UPDATE_PENDING: 'UPDATE_PENDING',
  ADD_PREPARED: 'ADD_PREPARED',
  CLEAR_PENDING: 'CLEAR_PENDING',
  SET_PREPARED: 'SET_PREPARED',
  SHOW_PREPARED: 'SHOW_PREPARED',
};

export const TIME_MUTATIONS = {
  SET_SERVER_OFFSET: 'SET_SERVER_OFFSET',
};

export default new Vuex.Store({
  namespaced: true,
  modules: {
    clients,
  },
  strict: true,
  plugins: [createPersistedState({ fetchBeforeUse: true })],
  state: {
    pendingReports: [],
    preparedReports: [{}],
    preparedReportsVisible: false,
    serverTimeOffset: 0,
    clusters: [],
    clusterFilterLoaded: false,
    clusterFilter: {
      loading: false,
      bounds: {
        clusterSize: {
          min: 0,
          max: 0,
        },
        clusterDate: {
          min: 0,
          max: 0,
        },
      },
      items: {
        types: [],
        protocols: [],
        devices: [],
      },
    },
    clusterFilterValues: {
      textSearch: '',
      clusterSize: { min: 1, max: 99999 },
      clusterDate: { min: 0, max: 0 },
      types: [],
      protocols: [],
      devices: [],
    },
    clusterFilterValuesRestored: false,
  },
  mutations: {
    [REPORTS_MUTATIONS.ADD_PENDING](state, e) {
      state.pendingReports.push(e);
    },
    [REPORTS_MUTATIONS.UPDATE_PENDING](state, e) {
      state.pendingReports.find((el) => el.filename === e.filename).progress = e.progress;
    },
    [REPORTS_MUTATIONS.ADD_PREPARED](state, e) {
      state.pendingReports = state.pendingReports.filter((el) => el.filename !== e.filename);
      state.preparedReports.push(e);
      window.dispatchEvent(new Event('reloadNotify'));
    },
    [REPORTS_MUTATIONS.SET_PREPARED](state, e) {
      state.preparedReports = e;
      // window.dispatchEvent(new Event('reloadNotify'));
    },
    [REPORTS_MUTATIONS.CLEAR_PENDING](state) {
      state.pendingReports = [];
      // window.dispatchEvent(new Event('reloadNotify'));
    },
    [REPORTS_MUTATIONS.SHOW_PREPARED](state, e) {
      state.preparedReportsVisible = e;
    },
    [TIME_MUTATIONS.SET_SERVER_OFFSET](state, e) {
      state.serverTimeOffset = e;
    },
    [FILTER_MUTATIONS.SET_TEXT_SEARCH](state, e) {
      state.clusterFilterValues['textSearch'] = e;
    },
    [FILTER_MUTATIONS.SET_CLUSTER_SIZE](state, { values }) {
      // console.log('mut cs', values);
      let { min, max } = values;
      if (min < state.clusterFilter.bounds.clusterSize.min || min > state.clusterFilter.bounds.clusterSize.max) {
        min = state.clusterFilter.bounds.clusterSize.min;
      }
      if (max < state.clusterFilter.bounds.clusterSize.min || max > state.clusterFilter.bounds.clusterSize.max) {
        max = state.clusterFilter.bounds.clusterSize.max;
      }
      state.clusterFilterValues['clusterSize'] = { min, max };
    },
    [FILTER_MUTATIONS.SET_CLUSTER_DATE](state, { values }) {
      // console.log('mut cd', values);
      let { min, max } = values;
      if (min < state.clusterFilter.bounds.clusterDate.min || min > state.clusterFilter.bounds.clusterDate.max) {
        min = state.clusterFilter.bounds.clusterDate.min;
      }
      if (max < state.clusterFilter.bounds.clusterDate.min || max > state.clusterFilter.bounds.clusterDate.max) {
        max = state.clusterFilter.bounds.clusterDate.max;
      }
      state.clusterFilterValues['clusterDate'] = { min, max };
    },
    [FILTER_MUTATIONS.SET_LOADED](state, { loaded }) {
      state.clusterFilterLoaded = loaded;
    },
    [FILTER_MUTATIONS.SET_ITEMS](state, { types, protocols, devices }) {
      state.clusterFilter.items.types = types;
      state.clusterFilter.items.protocols = protocols;
      state.clusterFilter.items.devices = devices;
    },
    [FILTER_MUTATIONS.SET_PUSH_ITEM_VALUE](state, { type, value }) {
      state.clusterFilterValues[type].push(value);
    },
    [FILTER_MUTATIONS.SET_SHIFT_ITEM_VALUE](state, { type, value }) {
      const elIdx = state.clusterFilterValues[type].findIndex((el) => el === value);
      state.clusterFilterValues[type].splice(elIdx, 1);
    },
    [FILTER_MUTATIONS.SET_BOUNDS](state, { clusterSizeBounds, clusterDateBounds }) {
      state.clusterFilter.bounds.clusterSize = clusterSizeBounds;
      state.clusterFilter.bounds.clusterDate = clusterDateBounds;
    },
    [FILTER_MUTATIONS.SET_VALUES](state, values) {
      // console.log('mut values', values);
      state.clusterFilterValues = values;
    },
  },
  getters: {
    filtersLoaded: (state) => {
      return state.clusterFilterLoaded;
    },
    filters: (state) => {
      return {
        types: state.clusterFilter.items.types,
        protocols: state.clusterFilter.items.protocols,
        devices: state.clusterFilter.items.devices,
        clusterSizeBounds: state.clusterFilter.bounds.clusterSize,
        clusterDateBounds: state.clusterFilter.bounds.clusterDate,
      };
    },
    filterValues: (state) => {
      return state.clusterFilterValues;
    },
    hasPendingReports: (state) => {
      return state.pendingReports.length > 0;
    },
    pendingReports: (state) => {
      return state.pendingReports;
    },
    preparedReportsCount: (state) => {
      return (state.preparedReports && state.preparedReports.length) || 0;
    },
    preparedReportsVisible: (state) => {
      return state.preparedReportsVisible;
    },
    preparedReports: (state) => {
      return state.preparedReports;
    },
    serverTimeOffset: (state) => {
      return state.serverTimeOffset;
    },
  },
  actions: {
    async loadFilters({ commit, dispatch }) {
      // make an API call to login the user with an email address and password
      const {
        data: {
          data: { types, protocols, devices, clusterSizeBounds, clusterDateBounds },
        },
      } = await axios.get('get-filters');

      // commit the user and tokens to the state
      commit(FILTER_MUTATIONS.SET_ITEMS, { types, protocols, devices });
      commit(FILTER_MUTATIONS.SET_BOUNDS, { clusterSizeBounds, clusterDateBounds });
      commit(FILTER_MUTATIONS.SET_LOADED, { loaded: Date.now() });
      dispatch('restoreValues');
    },
    async loadPreparedReports({ commit, dispatch }) {
      // make an API call to login the user with an email address and password
      const {
        data: { files },
      } = await axios.get('get-project-reports');

      commit(REPORTS_MUTATIONS.SHOW_PREPARED, false);
      commit(REPORTS_MUTATIONS.SET_PREPARED, files);
      commit(REPORTS_MUTATIONS.CLEAR_PENDING);
      console.log('files', files);

      // commit the user and tokens to the state
      // commit(FILTER_MUTATIONS.SET_ITEMS, { types, protocols, devices });
      // commit(FILTER_MUTATIONS.SET_BOUNDS, { clusterSizeBounds, clusterDateBounds });
      // commit(FILTER_MUTATIONS.SET_LOADED, { loaded: Date.now() });
      // dispatch('restoreValues');
    },
    async removePreparedReport({ commit, dispatch }, { filename }) {
      // make an API call to login the user with an email address and password
      const {
        data: { files },
      } = await axios.post('remove-report', { filename });

      // commit(REPORTS_MUTATIONS.SHOW_PREPARED, false);
      commit(REPORTS_MUTATIONS.SET_PREPARED, files);
      // commit(REPORTS_MUTATIONS.CLEAR_PENDING);
      console.log('files', files);

      // commit the user and tokens to the state
      // commit(FILTER_MUTATIONS.SET_ITEMS, { types, protocols, devices });
      // commit(FILTER_MUTATIONS.SET_BOUNDS, { clusterSizeBounds, clusterDateBounds });
      // commit(FILTER_MUTATIONS.SET_LOADED, { loaded: Date.now() });
      // dispatch('restoreValues');
    },
    startPending({ commit, dispatch }, value) {
      // console.log('startPending', value);
      commit(REPORTS_MUTATIONS.ADD_PENDING, value);
    },
    progressPending({ commit, dispatch }, value) {
      // console.log('progressPending', value);
      commit(REPORTS_MUTATIONS.UPDATE_PENDING, value);
    },
    finishPending({ commit, dispatch }, value) {
      // console.log('finishPending', value);
      commit(REPORTS_MUTATIONS.ADD_PREPARED, value);
    },
    showPreparedReports({ commit, dispatch }, value) {
      // console.log('showPreparedReports');
      commit(REPORTS_MUTATIONS.SHOW_PREPARED, value);
    },
    syncTime({ commit, dispatch }, value) {
      // console.log('showPreparedReports');
      const clientOffset = ls.get('userTimeOffset');
      this._vm.$socket.send(
        JSON.stringify({ type: 'time-sync', content: { clientTime: Date.now(), clientOffset: clientOffset } })
      );
    },
    serverTime({ commit, dispatch }, value) {
      const now = new DateNano().getTime();
      // const travelTime = now - value.requestTime;
      const serverOffset = now - value.serverTime;
      // console.log('serverTime', travelTime, serverOffset, value);
      commit(TIME_MUTATIONS.SET_SERVER_OFFSET, serverOffset);
    },
    setTextSearch({ commit, dispatch }, value) {
      // console.log('setTextSearch', value);
      commit(FILTER_MUTATIONS.SET_TEXT_SEARCH, value);
    },
    setClusterSize({ commit, dispatch }, values) {
      // console.log('setClusterSize', values);
      commit(FILTER_MUTATIONS.SET_CLUSTER_SIZE, { values });
    },
    setClusterDate({ commit, dispatch }, values) {
      commit(FILTER_MUTATIONS.SET_CLUSTER_DATE, { values });
    },
    pushItemValue({ commit, dispatch }, values) {
      commit(FILTER_MUTATIONS.SET_PUSH_ITEM_VALUE, values);
    },
    shiftItemValue({ commit, dispatch }, values) {
      commit(FILTER_MUTATIONS.SET_SHIFT_ITEM_VALUE, values);
    },
    storeValues({ commit, dispatch, state }) {
      // commit(FILTER_MUTATIONS.SET_VALUES, { values });
      ls.set('filters', state.clusterFilterValues);
    },
    restoreValues({ commit, dispatch, state }) {
      let oldValues = { ...state.clusterFilterValues };
      let values = ls.get('filters');
      // console.log('act restore', values);
      if (values) {
        // console.log('no empty');
      } else {
        values = {};
      }
      // console.log('rest oldValues', JSON.stringify(oldValues));
      if (Object.keys(values).indexOf('textSearch') > -1) {
        oldValues['textSearch'] = values['textSearch'];
      }
      if (Object.keys(values).indexOf('clusterSize') > -1) {
        oldValues['clusterSize'] = values['clusterSize'];
      } else {
        oldValues['clusterSize'] = state.clusterFilter.bounds.clusterSize;
      }
      if (Object.keys(values).indexOf('clusterDate') > -1) {
        oldValues['clusterDate'] = values['clusterDate'];
      } else {
        oldValues['clusterDate'] = state.clusterFilter.bounds.clusterDate;
      }
      if (Object.keys(values).indexOf('types') > -1) {
        oldValues['types'] = values['types'];
      }
      if (Object.keys(values).indexOf('protocols') > -1) {
        oldValues['protocols'] = values['protocols'];
      }
      if (Object.keys(values).indexOf('devices') > -1) {
        oldValues['devices'] = values['devices'];
      }
      // console.log('rest oldValues after', JSON.stringify(oldValues));

      commit(FILTER_MUTATIONS.SET_VALUES, oldValues);
    },
  },
});
