<template>
  <div class="filter-list">
    <div class="filter-list__header">
      <div v-if="searchable" class="filter-list__search-block">
        <el-input
          prefix-icon="el-icon-search"
          class="el-input__search el-input__search--no-border"
          :placeholder="$t('Search')"
          v-model="search"
        >
        </el-input>
      </div>
      <div v-if="selectable" class="list-checkbox">
        <div class="list-checkbox__icon">
          <img
            src="https://uploads-ssl.webflow.com/5f91d5a10b8e3e32addb2532/5f9952ab6349fbcb14e34e8c_box.svg"
            loading="lazy"
            alt=""
          />
        </div>
        <div class="list-checkbox__title">{{ $i18n.t('Select all') }}</div>
      </div>
    </div>
    <div class="filter-list__elements" :class="{ 'filter-list__elements--scroll': scroll }">
      <simplebar
        v-if="scroll"
        data-simplebar-auto-hide="false"
        rel="filterWr"
        :class="['filter-list-wrapper--scroll', { 'filter-list-wrapper': outOfView }]"
        ref="outerBlock"
      >
        <div class="filter-list-inner" ref="innerBlock" v-resize:debounce="onResize">
          <list-checkbox
            v-for="item in searchedItems"
            :item="item"
            :active-item.sync="activeItems"
            class="list-checkbox"
            :key="item.value"
            @checked="elCh($event)"
          >
          </list-checkbox>
        </div>
      </simplebar>
      <div v-else class="filter-list-wrapper-limited" :class="{ 'filter-list-wrapper': outOfView }" ref="outerBlock">
        <div class="filter-list-inner" ref="innerBlock" v-resize:debounce="onResize">
          <list-checkbox
            v-for="item in searchedItems"
            :ch.sync="item.checked"
            :item="item"
            :active-item="activeItems"
            class="list-checkbox"
            :key="item.value"
            @checked="elCh($event)"
          >
          </list-checkbox>
        </div>
      </div>
    </div>
    <div v-if="outOfView" class="filter-list__bottom">
      <div v-if="!scroll" class="filter-list__show-all" @click="scroll = true">{{ $i18n.t('Show all') }}</div>
      <div v-else class="filter-list__show-all" @click="scroll = false">{{ $i18n.t('Hide') }}</div>
      <!--      <div class="filter-list__reset-list"><div>Сбросить</div></div>-->
    </div>
  </div>
</template>

<script>
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import resize from 'vue-resize-directive';
import ListCheckbox from './ListCheckbox';

export default {
  name: 'filter-list',
  components: {
    simplebar,
    ListCheckbox,
  },
  props: {
    searchable: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
    },
    activeItems: {
      type: Array,
    },
  },
  directives: {
    resize,
  },

  data: () => {
    return {
      outOfView: false,
      scroll: false,
      search: '',
    };
  },
  computed: {
    searchedItems() {
      return this.items.filter((el) => el.title.indexOf(this.search) > -1 || el.description.indexOf(this.search) > -1);
    },
  },
  methods: {
    elCh(e) {
      this.$emit('checked', e);
    },
    onResize({ width, height }) {
      console.log('siz', this.$refs.innerBlock.clientHeight, height);
      this.outOfView = this.$refs.innerBlock.clientHeight > 138; //this.$refs.filterWr.clientHeight;
    },
  },
};
</script>

<style scoped></style>
