<template>
  <div class="tl-wrapper">
    <div class="clusters">
      <div class="clusters__toolbar">
        <top-bar>
          <template slot="title">{{ $i18n.t('Clusters') }}</template>
          <template slot="left">
            <!--
            <t-btn class="top-bar__item" icon="fas fa-sliders-h" @click="filterVisible = !filterVisible">{{
              $i18n.t('Filter')
            }}</t-btn>
-->
            <el-button type="primary" plain icon="el-icon-s-operation" @click="filterVisible = !filterVisible">{{
              $t('Filter')
            }}</el-button>

            <div class="textsearch-wrapper" style="width: 100%" v-observe-visibility="textsearchVisibilityChanged">
              <portal to="textSearch" :disabled="!moveSearch">
                <el-input
                  class="top-bar__item el-input__search"
                  v-model="searchString"
                  prefix-icon="el-icon-search"
                  :placeholder="$t('Search')"
                ></el-input>
              </portal>
            </div>
          </template>
          <template slot="right">
            <!--
            <el-button type="primary" plain @click="wsSend('reloadNotify')">{{ $t('Socket test') }}</el-button>
            <el-button type="primary" plain @click="wsSend('viewedPrepared')">{{ $t('Socket test') }}</el-button>
-->
          </template>
        </top-bar>
      </div>
      <div class="clusters__layout">
        <div v-show="showFilters" class="clusters__sidebar">
          <clusters-filter
            :filter-visible.sync="filterVisible"
            :active-filters.sync="activeFilters"
            @filter-changed="onFilterChange"
          >
            <template slot="text-search">
              <div :class="{ 'sidebar-search': moveSearch }">
                <portal-target name="textSearch" />
              </div>
            </template>
          </clusters-filter>
        </div>
        <div class="clusters__list" ref="cl_list" v-resize:debounce="onClustersListResize">
          <loading :active.sync="loading" :can-cancel="false" :is-full-page="false"> </loading>
          <div class="timeline">
            <template v-for="(groupData, key) in groupedRowsData">
              <div class="timeline__date-block">
                <div class="timeline__stycky-container">
                  <timeline-date
                    v-sticky="{ zIndex: 10, stickyTop: 0 }"
                    :date-obj="groupedDataObj[key]"
                  ></timeline-date>
                </div>
                <div class="timeline__scroll-block">
                  <template v-for="(rowData, idx) in groupData">
                    <timeline-interval
                      v-if="rowData.timeGapHum"
                      :interval="rowData.timeGapHum ? rowData.timeGapHum.text : ''"
                    ></timeline-interval>
                    <timeline-element
                      :row-data="rowData"
                      :fields="fields"
                      :class-name="['high-row', { even: idx % 2 === 0, odd: idx % 2 === 1, selected: isSelected(idx) }]"
                      @select="clickRow(idx)"
                      @colAction="colAction"
                      :key="'data-' + rowData._id"
                      :gap="rowData.timeGapHum ? rowData.timeGapHum.text : ''"
                      :len="rowData.clusterDuration.text"
                    >
                      <timeline-detail v-if="rowData.showDetails" :fields="fields" :key="'child-' + idx">
                        <cluster-events-table :no-select="true" :rows-data="rowData.children" :fields="eventsFields">
                        </cluster-events-table>
                      </timeline-detail>
                    </timeline-element>
                  </template>
                </div>
              </div>
            </template>
          </div>
          <div
            class="floating-pager"
            style="width: auto"
            :style="{ left: clustersListLeft + Math.floor(clustersListWidth / 2) + 'px' }"
          >
            <div class="floating-pager__inner">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-sizes="[7, 17, 50, 100, 300]"
                :page-size="pageLength"
                layout="total, sizes, prev, pager, next, jumper"
                :total="recordsFiltered"
              >
              </el-pagination>
              <!--
              <ul class="pagination">
                <li
                  v-if="currentPage > minPage"
                  @click="currentPage = currentPage - 1"
                  class="paginate_button page-item previous"
                  id="wheel_events_table_previous"
                >
                  <a aria-controls="wheel_events_table" data-dt-idx="0" tabindex="0" class="page-link">{{
                    $i18n.t('Prev.')
                  }}</a>
                </li>
                <li v-else class="paginate_button page-item previous disabled" id="wheel_events_table_previous">
                  <a aria-controls="wheel_events_table" data-dt-idx="0" tabindex="0" class="page-link">{{
                    $i18n.t('Prev.')
                  }}</a>
                </li>
                <li
                  v-for="(p, i) in pagesList"
                  class="paginate_button page-item"
                  :class="{ disabled: p === null, active: p === currentPage }"
                  :key="i + '-' + p"
                  @click="setCurrentPage(p)"
                >
                  <a aria-controls="wheel_events_table" :data-dt-idx="i + 1" tabindex="0" class="page-link">{{
                    p !== null ? p : '...'
                  }}</a>
                </li>
                <li
                  v-if="currentPage < maxPage"
                  @click="currentPage = currentPage + 1"
                  class="paginate_button page-item next"
                  id="wheel_events_table_next"
                >
                  <a
                    aria-controls="wheel_events_table"
                    :data-dt-idx="pagesList.length + 2"
                    tabindex="0"
                    class="page-link"
                    >{{ $i18n.t('Next') }}</a
                  >
                </li>
                <li v-else class="paginate_button page-item next disabled" id="wheel_events_table_next">
                  <a
                    aria-controls="wheel_events_table"
                    :data-dt-idx="pagesList.length + 2"
                    tabindex="0"
                    class="page-link"
                    >{{ $i18n.t('Next') }}</a
                  >
                </li>
              </ul>
-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataRow from './DataRow';
import DetailRow from './DetailRow';
import ClusterEventsTable from './ClusterEventsTable';
// import loading from 'vue-loading';
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

import ArchiveEventsModal from './../ArchiveEventsModal';
import ExportEventsModal from './../ExportEventsModal';

import TimelineRow from './TimelineRow';
import TimelineDetail from './TimelineDetail';
import { Zone, DateTime } from 'luxon';
import ScrollTo from 'vue-scrollto';

import axios from 'axios';
import SidebarFilter from './SidebarFilter';
import TopBar from './TopBar';
import TBtn from './TBtn';
import TInput from './TInput';
import TimelineDate from './TimelineDate';
import TimelineInterval from './TimelineInterval';
import TimelineElement from './TimelineElement';
import ClustersFilter from './ClustersFilter';
import VueSticky from 'vue-sticky';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import resize from 'vue-resize-directive';

import { debounce } from '../../plugins/debounce';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'clusters-timeline',
  props: {
    url: {
      type: String,
      default: '',
    },
    className: {
      type: String,
    },
    // buttons: {
    //   type: Array,
    // },
    fields: {
      type: Array,
    },
    lengthMenu: {
      type: Array,
    },
    offTypes: {
      type: Array,
    },
    archive: Boolean,
  },
  directives: {
    resize,
    sticky: VueSticky,
  },
  // mixins: [vueDebounce],
  components: {
    ArchiveEventsModal,
    ExportEventsModal,
    TimelineRow,
    TimelineDetail,
    DataRow,
    DetailRow,
    TopBar,
    TBtn,
    TInput,
    TimelineDate,
    TimelineInterval,
    TimelineElement,
    ClusterEventsTable,
    Loading,
    SidebarFilter,
    ClustersFilter,
    simplebar,
  },
  // directives: { loading },
  data: () => {
    return {
      scrollOptions: {
        container: 'body',
        easing: 'ease-in-out',
        offset: -60,
        force: false,
        cancelable: true,
        x: false,
        y: true,
      },
      sortables: [],
      restored: false,
      currentSortField: '',
      currentSortDir: -1,
      currentPage: 1,
      preventReload: false,
      moveSearch: false,
      pageStart: 0,
      pageLength: 17,
      // searchTypes: [],
      rowsData: [],
      devicesList: [],
      recordsTotal: 0,
      recordsFiltered: 0,
      loading: false,
      draw: 0,
      errors: [],
      onTypes: [],
      // offTypes: [],
      dateOffset: { top: 10, bottom: 20 },
      filterVisible: true,
      activeFilters: {
        clusterDate: { min: 0, max: 0 },
        clusterSize: { min: 0, max: 0 },
        types: [],
        protocols: [],
        devices: [],
      },

      selectedRows: [],
      visibleArchiveDialog: false,
      clustersListLeft: 0,
      clustersListWidth: 0,
      buttons: [
        {
          name: 'print',
          label: 'Print',
        },
        {
          name: 'export-sub',
          label: 'Export',
          children: [
            {
              name: 'pdf',
              label: 'PDF',
              action: 'exportPdf',
            },
            {
              name: 'csv',
              label: 'CSV',
              action: 'exportCsv',
            },
            {
              name: 'xls',
              label: 'Excel',
              action: 'exportXls',
            },
          ],
        },
        {
          name: 'archive',
          label: 'Archive',
          action: 'switchArchive',
        },
        {
          name: 'view-selected',
          label: 'View selected',
          action: 'openSelected',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'filters',
      'filtersLoaded',
      'filterValues',
      // ...
    ]),
    searchString: {
      get() {
        return this.filterValues && this.filterValues.textSearch;
      },
      set(e) {
        console.log('textSearch val set', e);
        // this.maxValueTmp = e;
        this.$store.dispatch('setTextSearch', e);
      },
    },
    showFilters() {
      return this.filterVisible;
    },
    selectedFilters() {
      return {
        textSearch: this.filterValues.textSearch || undefined,
        clusterSize:
          this.filters.clusterSizeBounds.min !== this.filterValues.clusterSize.min ||
          this.filters.clusterSizeBounds.max !== this.filterValues.clusterSize.max
            ? this.filterValues.clusterSize
            : undefined,
        clusterDate:
          this.filters.clusterDateBounds.min !== this.filterValues.clusterDate.min ||
          this.filters.clusterDateBounds.max !== this.filterValues.clusterDate.max
            ? this.filterValues.clusterDate
            : undefined,
        types: this.filterValues.types,
        protocols: this.filterValues.protocols,
        devices: this.filterValues.devices,
        // types: this.filters.types.filter((el) => this.filterValues.types.indexOf(el.value) > -1),
        // protocols: this.filters.protocols.filter((el) => this.filterValues.protocols.indexOf(el.value) > -1),
        // devices: this.filters.devices.filter((el) => this.filterValues.devices.indexOf(el.value) > -1),
      };
    },

    activeFiltersValues() {
      let fil = {};
      if (this.selectedFilters['clusterDate']) {
        fil['clusterDate'] = {
          min: this.selectedFilters['clusterDate'].min * 1e3,
          max: this.selectedFilters['clusterDate'].max * 1e3,
        };
      }
      if (this.selectedFilters['clusterSize']) {
        fil['clusterSize'] = this.selectedFilters['clusterSize'];
      }
      if (this.selectedFilters['types'].length > 0) {
        fil['types'] = this.selectedFilters['types'];
      }
      if (this.selectedFilters['protocols'].length > 0) {
        fil['protocols'] = this.selectedFilters['protocols'];
      }
      if (this.selectedFilters['devices'].length > 0) {
        fil['devices'] = this.selectedFilters['devices'];
      }
      return fil;
    },
    groupedDataObj() {
      return this.rowsData.reduce(function (r, a) {
        r[a.dateDT] = a.dateTimeline;
        return r;
      }, Object.create(null));
    },
    groupedRowsData() {
      return this.rowsData.reduce(function (r, a) {
        r[a.dateDT] = r[a.dateDT] || [];
        r[a.dateDT].push(a);
        return r;
      }, Object.create(null));
    },
    eventsFields() {
      return [
        {
          label: this.$i18n.t('Date & Time'),
          name: 'timeDT',
          data: 'timeDT',
          sortable: true,
        },
        {
          label: this.$i18n.t('Type'),
          name: 'typeDT',
          data: 'typeDT',
          sortable: true,
        },
        {
          label: this.$i18n.t('IED'),
          name: 'iedNameDT',
          data: 'iedNameDT',
          sortable: true,
        },
        {
          label: this.$i18n.t('Control Block Name'),
          name: 'controlBlockDT',
          data: 'controlBlockDT',
          sortable: true,
        },
        {
          label: this.$i18n.t('Description'),
          name: 'descriptionsDT',
          data: 'descriptionsDT',
          sortable: true,
        },
        // {
        //   label: '',
        //   name: 'filtered',
        //   sortable: false,
        //   render: (d, r) => {
        //     return r.filtered
        //       ? "<i class='fa fa-circle text-success'></i>"
        //       : "<i class='fal fa-circle text-muted'></i>";
        //   },
        // },
      ];
    },

    rowsDataSelected() {
      return this.rowsData.filter((e, i) => this.selectedRows.indexOf(this.pageStart + i) > -1);
    },
    rowsDataSelectedTs() {
      return this.rowsDataSelected.map((e) => e.ts).sort();
    },
    rowsDataSelectedTime() {
      if (this.rowsDataSelectedTs.length > 0) {
        let timestamp = (this.rowsDataSelectedTs[0] + this.rowsDataSelectedTs[this.rowsDataSelectedTs.length - 1]) / 2;
        let timeBefore = timestamp - this.rowsDataSelectedTs[0] + (this.rowsDataSelectedTs.length > 1 ? 0 : 1e7);
        let timeAfter = timestamp - this.rowsDataSelectedTs[0] + (this.rowsDataSelectedTs.length > 1 ? 0 : 1e7);
        return {
          timestamp,
          timeBefore,
          timeAfter,
        };
      }
    },
    rowsDataGrouped() {},
    enableView() {
      return this.rowsDataSelected.filter((e) => e.hasRef).length > 1;
    },
    recordLeft() {
      return this.pageStart + 1;
    },
    recordRight() {
      return Math.min(this.pageStart + this.pageLength, this.recordsFiltered);
    },
    minPage() {
      return 1;
    },
    maxPage() {
      return Math.max(Math.ceil(this.recordsFiltered / this.pageLength), 1);
    },
    pages() {
      let pages = [];
      pages.push(this.currentPage);
      pages.push(this.minPage);
      pages.push(this.maxPage);
      if (this.currentPage - 1 > this.minPage) {
        pages.push(this.currentPage - 1);
      }
      if (this.currentPage + 1 < this.maxPage) {
        pages.push(this.currentPage + 1);
      }
      if (this.currentPage - this.minPage === 3) {
        pages.push(this.currentPage - 2);
      }
      if (this.maxPage - this.currentPage === 3) {
        pages.push(this.currentPage + 2);
      }
      if (this.currentPage - this.minPage < 3) {
        for (let i = this.minPage; i < this.minPage + 5 && this.maxPage > i; i++) {
          pages.push(i);
        }
      }
      if (this.maxPage - this.currentPage < 3) {
        for (let i = this.maxPage; i > this.maxPage - 5 && this.minPage < i; i--) {
          pages.push(i);
        }
      }
      pages = [...new Set(pages)];
      return pages.sort(function (a, b) {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });
    },
    order() {
      return {
        column: this.fields.map((e) => e.name).indexOf(this.currentSortField),
        dir: this.currentSortDir === 1 ? 'asc' : 'desc',
      };
    },
    pagesList() {
      let list = [];
      for (let i = 0; i < this.pages.length; i++) {
        list.push(this.pages[i]);
        if (i + 1 < this.pages.length && this.pages[i] + 1 !== this.pages[i + 1]) {
          list.push(null);
        }
      }
      return list;
    },
    offTypesArray() {
      return (this.offTypes && this.offTypes.map((e) => '!' + e)) || [];
    },
    search() {
      let searchArray = this.offTypesArray;
      if (this.searchString !== '') {
        searchArray = [...this.offTypesArray, this.filterValues.textSearch];
      }
      return {
        value: searchArray.join(','),
        filters: this.activeFiltersValues,
        regex: false,
      };
    },
    eventTypes() {
      return [
        { name: 'GO', label: this.$i18n.t('Goose') },
        { name: 'SV', label: this.$i18n.t('SV') },
        { name: 'SRV', label: this.$i18n.t('Service events') },
        { name: 'ARP', label: this.$i18n.t('ARP') },
        { name: 'MMS', label: this.$i18n.t('MMS') },
        { name: 'USER', label: this.$i18n.t('User events') },
      ];
    },

    queryString() {
      let order = [];
      let search = this.search;
      order.push(this.order);
      return this.$qs.stringify({
        draw: this.draw,
        order,
        search,
        find: this.$route.params.cluster ? this.$route.params.cluster : false,
        start: this.pageStart,
        length: this.pageLength,
      });
    },
  },
  beforeMount() {
    /*
    try {
      let onTypes = this.eventTypes.map((e) => e.name);
      let ss = this.$route.query;
      let ssArr = this.$qs.parse(ss);
      let types = ssArr['search']
        .split(',')
        .filter((e) => e.indexOf('!') > -1)
        .map((e) => e.substring(1));
      console.log('ss', ssArr, onTypes);
      onTypes.forEach((el) => {
        if (types.indexOf(el) < 0) {
          this.onTypes.push(el);
        }
      });
      this.offTypes = this.eventTypes.filter((e) => this.onTypes.indexOf(e.name) < 0).map((e) => e.name);
      this.$nextTick(() => {
        this.visibleTable = true;
      });
    } catch (e) {
      this.onTypes = this.eventTypes.map((e) => e.name);
      this.offTypes = this.eventTypes.filter((e) => this.onTypes.indexOf(e.name) < 0).map((e) => e.name);
      this.visibleTable = true;
    }
    if (this.extButtons) {
      this.buttons = this.extButtons;
    }
*/
  },

  mounted() {
    this.setDefaultSort();
    // this.loadFilters();
    // this.loadData();
    this.$store.dispatch('loadFilters');
    this.$store.watch(
      (state) => state.clusterFilter,
      (newValue, oldValue) => {
        // this.$store.dispatch('storeValues');
        // this.restoreFilters();
      }
    );

    console.log('r', this.$route);
    console.log('cf', this.$cf);
    // this.$store.watch(
    //   (state, getters) => getters.filtersLoaded,
    //   () => {
    //     console.log('loaded');
    //     this.restoreFilters();
    //   }
    // );
  },
  methods: {
    ...mapActions(['loadFilters', 'storeValues']),
    textsearchVisibilityChanged(isVisible, ent) {
      this.moveSearch = !isVisible;
    },
    wsSend(e) {
      // this.$socket.send('some data');
      // or with {format: 'json'} enabled
      window.dispatchEvent(new Event(e));
    },

    scrollToCluster() {
      if (this.$route.params.cluster) {
        ScrollTo.scrollTo(`#cl-${this.$route.params.cluster}`, 300, this.scrollOptions);
      }
      this.preventReload = false;
      this.$route.params.cluster = false;
    },
    onClustersListResize() {
      const box = this.$refs.cl_list && this.$refs.cl_list.getBoundingClientRect();
      if (box) {
        this.clustersListLeft = box.left;
        this.clustersListWidth = box.width;
      }
    },

    switchType(type) {
      console.log('t', type);
      if (this.onTypes.indexOf(type.name) < 0) {
        this.onTypes.push(type.name);
      } else {
        this.onTypes = this.onTypes.filter((e) => e != type.name);
      }
      this.offTypes = this.eventTypes.filter((e) => this.onTypes.indexOf(e.name) < 0).map((e) => e.name);
      this.$nextTick(() => {
        // this.pageChange();
        this.$emit('pageUpdate');
      });
    },
    execAction(a, e, d) {
      console.log('execAction', a, e, d);
      if (a) {
        this[a](e, d);
      }
    },
    exportXls(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl = this.archive
        ? `/projects/${this.$tpws.projectName}/archive-xls?tstart=${this.archive.tstart}&tend=${this.archive.tend}`
        : `/projects/${this.$tpws.projectName}/events2-xls`;
      axios
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.$tpws.displayName} ` + this.$i18n.t('analysis report') + `.xlsx`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    exportPdf(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl = this.archive
        ? `/projects/${this.$tpws.projectName}/archive-pdf?tstart=${this.archive.tstart}&tend=${this.archive.tend}`
        : `/projects/${this.$tpws.projectName}/events2-pdf`;
      axios
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.$tpws.displayName} ` + this.$i18n.t('analysis report') + `.pdf`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    exportCsv(e) {
      const button = e.target;
      e.stopImmediatePropagation();
      const i = document.createElement('i');
      i.className = 'fa fa-spinner fa-pulse ml-2 fa-fw';
      button.appendChild(i);
      let dataUrl = this.archive
        ? `/projects/${this.$tpws.projectName}/archive-csv?tstart=${this.archive.tstart}&tend=${this.archive.tend}`
        : `/projects/${this.$tpws.projectName}/events2-csv`;
      axios
        .get(dataUrl)
        .then((response) => {
          var link = document.createElement('a');
          link.href = response.data.downloadPath;
          link.download = `${this.$tpws.displayName} ` + this.$i18n.t('analysis report') + `.csv`;
          link.click();
          const i = button.getElementsByTagName('i')[0];
          button.removeChild(i);
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    switchArchive(e) {
      this.visibleArchiveDialog = true;
    },
    openSelected(e) {
      console.log('openSelected', e);
      const ts = this.rowsDataSelectedTime;
      if (e.ctrlKey || e.shiftKey || e.altKey)
        window.open(`?p=signals&time=${ts.timestamp}&timeBefore=${ts.timeBefore}&timeAfter=${ts.timeAfter}`, '_blank');
      else location.href = `?p=signals&time=${ts.timestamp}&timeBefore=${ts.timeBefore}&timeAfter=${ts.timeAfter}`;
    },
    colAction(a, e, d) {
      if (a) {
        e.stopImmediatePropagation();
        this[a](e, d);
      }
    },
    showProfile(e, d) {
      console.log(e);
      // this.$emit('showProfile', e);
      const queryString = this.$qs.stringify({
        cluster: d.clusterDT,
      });
      const url = `?p=cluster-events-profile` + (queryString ? '&' + queryString : '');
      if (e.ctrlKey || e.shiftKey || e.altKey) window.open(url, '_blank');
      else location.href = url;
    },
    showEvents(e, d) {
      console.log(d);
      let el = this.rowsData.find((e) => e._id === d._id);
      let idx = this.rowsData.indexOf(el);

      el.showDetails = !el.showDetails;
      // this.timePresets.$set(idx, el);
      this.$set(this.rowsData, idx, el);
      // this.$emit('showEvents', e);
      if (el.showDetails) {
        this.loadDetails(d);
      }
    },
    loadDetails(d) {
      let search = this.search;
      const queryString = this.$qs.stringify({
        cluster: d.clusterDT,
        search,
      });
      let dataUrl = `/projects/${this.$tpws.projectName}/events2-exp?` + queryString;
      // this.selectedRows = [];
      this.loading = true;

      // this.$emit('pageUpdate');

      axios
        .get(dataUrl)
        .then((response) => {
          console.log(response);
          // JSON responses are automatically parsed.
          // this.rowsData = response.data.data;
          // this.recordsTotal = response.data.recordsTotal;
          let el = this.rowsData.find((e) => e._id === d._id);
          let idx = this.rowsData.indexOf(el);

          el.children = response.data.data;
          // this.timePresets.$set(idx, el);
          this.$set(this.rowsData, idx, el);

          this.loading = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    clickRow(i) {
      const rowInx = this.pageStart + i;
      console.log('clickRow', this.selectedRows.indexOf(rowInx), rowInx);
      // if (this.selectedRows.indexOf(rowInx) < 0) {
      //   this.selectedRows.push(rowInx);
      // } else {
      //   this.selectedRows = this.selectedRows.filter((e) => e !== rowInx);
      // }
    },
    clickCol(e) {
      console.log('clickCol', e);
    },
    selectRow(i) {
      const rowInx = this.pageStart + i;
      console.log('sr', this.selectedRows.indexOf(rowInx), rowInx);
      if (this.selectedRows.indexOf(rowInx) < 0) {
        this.selectedRows.push(rowInx);
      } else {
        this.selectedRows = this.selectedRows.filter((e) => e !== rowInx);
      }
    },
    isSelected(idx) {
      const rowInx = this.pageStart + idx;
      return this.selectedRows.indexOf(rowInx) > -1;
    },
    handleSizeChange(e) {
      this.pageLength = e;
      console.log('set size', e);
    },
    handleCurrentChange(e) {
      // this.currentPage = e;
      console.log('set page', e);
    },
    setCurrentPage(p) {
      if (p) {
        this.currentPage = p;
      }
    },
    // onFilterChange(e) {
    //   this.activeFilters = e;
    //   this.$nextTick(() => {
    //     this.loadData();
    //   });
    // },
    onFilterChange: debounce(function (e) {
      this.activeFilters = e;
      this.$nextTick(() => {
        this.storeFilters();
        this.loadData();
      });
    }, 1000),
    onSearchInput: debounce(function (e) {
      //this.activeFilters = e;
      this.$nextTick(() => {
        this.storeFilters();

        this.loadDataDebouced();
      });
    }, 2000),
    loadData: debounce(function () {
      this.loadDataDebouced();
    }, 1500),
    loadDataDebouced() {
      this.draw++;
      console.log('sq', this.queryString);
      let dataUrl = this.url + '?' + this.queryString;
      this.selectedRows = [];
      this.loading = true;
      this.rowsData = [];

      // this.$emit('pageUpdate');

      axios
        .get(dataUrl)
        .then((response) => {
          // JSON responses are automatically parsed.

          this.rowsData = response.data.data;
          this.recordsTotal = response.data.recordsTotal;
          if (response.data.pageNum && response.data.pageNum !== this.currentPage) {
            this.preventReload = true;
            this.currentPage = response.data.pageNum;
            this.$emit('pageUpdate');
          }
          this.recordsFiltered = response.data.recordsFiltered;
          this.$nextTick(() => {
            this.scrollToCluster();
          });
          this.loading = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    // loadFilters() {
    //   axios
    //     .get('get-filters')
    //     .then((response) => {
    //       // JSON responses are automatically parsed.
    //       this.filters['types'] = response.data.data.types;
    //       this.filters['protocols'] = response.data.data.protocols;
    //       this.filters['devices'] = response.data.data.devices;
    //       this.filters['clusterSizeBounds']['min'] = response.data.data.clusterSizeBounds.min;
    //       this.filters['clusterSizeBounds']['max'] = response.data.data.clusterSizeBounds.max;
    //       this.filters['clusterDateBounds']['left'] = response.data.data.clusterDateBounds.min;
    //       this.filters['clusterDateBounds']['right'] = response.data.data.clusterDateBounds.max;
    //       this.restoreFilters();
    //       this.loading = false;
    //     })
    //     .catch((e) => {
    //       this.errors.push(e);
    //       this.loading = false;
    //     });
    // },
    storeFilters() {
      // let fl = {};
      // if (this.activeFilters['clusterDate']) {
      //   fl['clusterDate'] = this.activeFilters['clusterDate'];
      // }
      // if (this.activeFilters['clusterSize']) {
      //   fl['clusterSize'] = this.activeFilters['clusterSize'];
      // }
      // fl['types'] = this.activeFilters['types'];
      // fl['protocols'] = this.activeFilters['protocols'];
      // fl['devices'] = this.activeFilters['devices'];
      // // this.$ls.set('filters', fl);
      // this.storeValues(fl);
      this.$store.dispatch('storeValues');

      console.log('stored', JSON.stringify(this.filterValues));
    },
    restoreFilters() {
      console.log('isRestored', this.restored, !this.restored);
      if (!this.restored) {
        console.log('restoring');
        // this.$store.dispatch('restoreValues');
        const storedFilters = this.filterValues;
        console.log('st filters', storedFilters, Object.keys(storedFilters));
        if (Object.keys(storedFilters).length > 0) {
          console.log(JSON.stringify(storedFilters['clusterSize']), JSON.stringify(this.filters));
          this.activeFilters['clusterSize'] = storedFilters['clusterSize'];
          this.activeFilters['clusterDate'] = storedFilters['clusterDate'];
          console.log('aft rest', JSON.stringify(this.activeFilters['clusterSize']));
          this.activeFilters['protocols'] = storedFilters['protocols'];
          this.activeFilters['types'] = storedFilters['types'];
          this.activeFilters['devices'] = storedFilters['devices'];
        } else {
          this.storeFilters();
        }
        console.log('restored', JSON.stringify(this.activeFilters));
        this.restored = true;
      }

      this.loadData();
    },
    setDefaultSort() {
      if (this.currentSortField === '') {
        this.currentSortField = this.fields[0].name;
      }
      if (this.currentSortDir !== -1) {
        this.currentSortDir = -1;
      }
    },
    columnSortClass(f) {
      if (f.name === this.currentSortField) {
        if (this.currentSortDir === 1) {
          return 'sorting_asc';
        }
        if (this.currentSortDir === -1) {
          return 'sorting_desc';
        }
      } else {
        return f.sortable ? 'sorting' : '';
      }
    },
    changeSort(f) {
      console.log('f', f);
      const prevSortField = this.currentSortField;
      this.currentSortField = f.name;
      if (prevSortField !== this.currentSortField) {
        this.currentSortDir = 1;
      } else {
        if (this.currentSortDir === 1) {
          this.currentSortDir = -1;
        } else {
          this.currentSortDir = 1;
        }
      }
      this.loadData();
      this.$emit('pageUpdate');
    },
  },
  watch: {
    // filters: {
    //   deep: true,
    //   handler(e, o) {
    //     console.log('watch filters tl', e, o);
    //     this.restoreFilters();
    //   },
    // },
    // filtersLoaded: function (val, old) {
    //   console.log('watch filters tl', val, old);
    //   this.restoreFilters();
    // },
    offTypes: function (val, old) {
      if (val !== old) {
        this.loadData();
      }
    },
    searchString: function (val, old) {
      if (val !== old) {
        this.onSearchInput();
      }
    },
    currentPage: function (val, old) {
      if (val !== old) {
        this.pageStart = (this.currentPage - 1) * this.pageLength;
      }
    },
    pageLength: function (val, old) {
      if (val !== old) {
        this.loadData();
      }
    },
    pageStart: function (val, old) {
      if (val !== old) {
        if (!this.preventReload) this.loadData();
      }
    },
    maxPage: function (val, old) {
      if (this.currentPage > this.maxPage) {
        this.currentPage = this.maxPage;
      }
    },
  },
};
</script>

<style lang="scss">
.el-input {
  &__search {
    margin-left: 8px;
    & input {
      background-color: transparent;
      border-radius: 0;
      border-top: none;
      border-left: none;
      border-right: none;
    }
    &--no-border {
      & input {
        border-bottom: none;
      }
    }
  }
}
.wrr {
  height: calc(100vh - 56px - 16px);
  padding-right: 16px;
}
</style>

<style scoped>
.data-table-loading-wrapper {
  position: relative;
}

.tl {
  margin-top: 20px;
  position: relative;
}

.tl:before {
  position: absolute;
  content: '';
  width: 4px;
  height: calc(100% - 50px);
  background: rgb(138, 145, 150);
  background: -moz-linear-gradient(
    left,
    rgba(138, 145, 150, 1) 0%,
    rgba(122, 130, 136, 1) 60%,
    rgba(98, 105, 109, 1) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(138, 145, 150, 1) 0%,
    rgba(122, 130, 136, 1) 60%,
    rgba(98, 105, 109, 1) 100%
  );
  background: linear-gradient(
    to right,
    rgba(138, 145, 150, 1) 0%,
    rgba(122, 130, 136, 1) 60%,
    rgba(98, 105, 109, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8a9196', endColorstr='#62696d',GradientType=1 );
  left: 14px;
  top: 5px;
  border-radius: 4px;
}

.tl-month {
  position: relative;
  padding: 4px 15px 4px 35px;
  background-color: #f8f9fa;
  display: inline-block;
  width: auto;
  border-radius: 40px;
  border: 1px solid #17191b;
  border-right-color: black;
  margin-bottom: 30px;
}

.tl-month span {
  position: absolute;
  top: -1px;
  left: calc(100% - 10px);
  z-index: -1;
  white-space: nowrap;
  display: inline-block;
  background-color: #111;
  padding: 4px 10px 4px 20px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  border: 1px solid black;
  box-sizing: border-box;
}

.tl-month:before {
  position: absolute;
  content: '';
  width: 20px;
  height: 20px;
  background: rgb(138, 145, 150);
  background: -moz-linear-gradient(
    top,
    rgba(138, 145, 150, 1) 0%,
    rgba(122, 130, 136, 1) 60%,
    rgba(112, 120, 125, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(138, 145, 150, 1) 0%,
    rgba(122, 130, 136, 1) 60%,
    rgba(112, 120, 125, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(138, 145, 150, 1) 0%,
    rgba(122, 130, 136, 1) 60%,
    rgba(112, 120, 125, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8a9196', endColorstr='#70787d',GradientType=0 );
  border-radius: 100%;
  border: 1px solid #17191b;
  left: 5px;
  top: 6px;
}

.tl-cluster {
  padding-left: 35px;
  display: block;
  position: relative;
  /*margin-bottom: 15px;*/
}

.tl-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: 15px;
}

.tl-section {
  padding-left: 35px;
  display: block;
  position: relative;
  /*margin-bottom: 30px;*/
}

.tl-interval {
  margin-bottom: 15px;
  padding: 2px 15px;
  /*background: linear-gradient(#74cae3, #5bc0de 60%, #4ab9db);*/
  background: linear-gradient(
    to bottom,
    rgba(138, 145, 150, 1) 0%,
    rgba(122, 130, 136, 1) 60%,
    rgba(112, 120, 125, 1) 100%
  );

  position: relative;
  display: inline-block;
  border-radius: 20px;
  border: 1px solid #17191b;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}
.tl-section:before {
  content: '';
  position: absolute;
  width: 30px;
  height: 2px;
  background-color: #444950;
  top: 13px;
  left: 20px;
}

.tl-section:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: linear-gradient(
    to bottom,
    rgba(138, 145, 150, 1) 0%,
    rgba(122, 130, 136, 1) 60%,
    rgba(112, 120, 125, 1) 100%
  );
  top: 9px;
  left: 11px;
  border: 1px solid #17191b;
  border-radius: 100%;
}

.data-table {
  width: 100%;
}
.data-table thead .sorting_asc {
  background-image: url(../../../assets/sort_asc.png);
}
.data-table thead .sorting_desc {
  background-image: url(../../../assets/sort_desc.png);
}
.data-table thead .sorting {
  background-image: url(../../../assets/sort_both.png);
}
.data-table .event-types-block {
  display: flex;
}
.data-table .event-types-block {
  margin: 10px 0;
}
.data-table thead .sorting,
.data-table thead .sorting_asc,
.data-table thead .sorting_desc,
.data-table thead .disabled {
  background-repeat: no-repeat;
  background-position: center right;
}
.data-table thead .sorting,
.data-table thead .sorting_asc,
.data-table thead .sorting_desc {
  cursor: pointer;
  *cursor: hand;
}
.data-table .dataTables_filter {
  float: right;
  text-align: right;
}
.data-table thead .sorting {
}

.data-table thead th,
.data-table thead td {
  user-select: none;
  padding: 10px 18px;
  border-bottom: 1px solid #111;
}

.data-table tfoot th,
.data-table tfoot td {
  padding: 10px 18px;
  border-top: 1px solid #111;
}
.data-table thead th,
.data-table tfoot th {
  font-weight: bold;
}

.data-table.display tbody tr.selected {
  background-color: #acbad4;
}
</style>
