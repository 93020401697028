import Vue from 'vue';
import api from './../../api';
import * as mutation from './../mutation-types';
import axios from 'axios';
import { FILTER_MUTATIONS } from '@/store';

const isJson = function (str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
const state = {
  clients: [],
  clientId: undefined,
  lastMsg: '',
  loading: false,
  socket: {
    isConnected: false,
    message: '',
    reconnectError: false,
  },
};

const getters = {
  clientList: (state) => state.clients,
  clientId: (state) => state.clientId,
};

const mutations = {
  [mutation.WS_OPEN](state, event) {
    // console.log('connect', event);
    Vue.prototype.$socket = event.currentTarget;
    state.socket.isConnected = true;
  },
  [mutation.WS_CLOSE](state, event) {
    state.socket.isConnected = false;
  },
  [mutation.WS_ERROR](state, event) {
    console.error(state, event);
  },
  // default handler called for all methods
  [mutation.WS_MESSAGE](state, message) {
    let msg = undefined;
    if (isJson(message.data)) {
      msg = JSON.parse(message.data);
      if (msg.type) {
        this.dispatch('messageProcessing', msg);
      }
    } else {
      // console.log('untyped msg', message);
    }
    state.socket.message = msg;
  },
  [mutation.WS_SET_CLIENT_ID](state, message) {
    state.clientId = message;
  },
  // mutations for reconnect methods
  [mutation.WS_RECONNECT](state, count) {
    console.info(state, count);
  },
  [mutation.WS_RECONNECT_ERROR](state) {
    state.socket.reconnectError = true;
  },
};

const actions = {
  setClientId({ commit, dispatch, state }, value) {
    // console.log('setClientId', value);
    if (state.clientId === undefined) {
      commit(mutation.WS_SET_CLIENT_ID, value);
    } else {
      dispatch('fixClientId', state.clientId);
    }
  },
  messageProcessing({ commit, dispatch, state }, msg) {
    switch (msg.type) {
      case 'id':
        dispatch('setClientId', msg.content);
        break;
      case 'server-time':
        dispatch('serverTime', msg.content, { root: true });
        break;
      case 'EXPORT_START':
        dispatch('startPending', msg.content, { root: true });
        break;
      case 'EXPORT_PROGRESS':
        dispatch('progressPending', msg.content, { root: true });
        break;
      case 'EXPORT_FINISH':
        dispatch('finishPending', msg.content, { root: true });
        break;
    }
  },
  async fixClientId({ commit, dispatch }, value) {
    this._vm.$socket.send(JSON.stringify({ type: 'fix-id', content: value }));
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
