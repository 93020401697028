<template>
  <div id="top-app" class="mr-2">
    <div class="top-app-inner">
      <template v-for="rep in pendingReports">
        <div class="report-icon mr-2">
          <i class="fa fa-spinner fa-pulse fa-fw" :title="rep.filename"></i>
          <div class="report-progress0"></div>
          <div class="report-progress" :style="{ width: rep.progress + '%' }"></div>
        </div>
        <!--<span>{{ rep.progress }}%</span>-->
      </template>
    </div>
    <div class="top-app-inner">
      <span v-if="timeColor !== 'black'" class="time-description">
        <popper trigger="hover" :append-to-body="true" :options="popperOptions">
          <div class="popper p-2">
            <div>{{ timeObject.timeDescription }}</div>
          </div>
          <span slot="reference">
            <i :style="{ color: timeColor }" class="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i>
            <span>{{ timeObject.localTime }}</span>
          </span>
        </popper>
        <!--      <div v-if="timeDiff" class="t-icon t-icon__20px t-icon__blink t-icon__ts-alert-icon" ></div>-->
      </span>
      <span v-else>{{ timeObject.localTime }}</span>
    </div>
    <prepared-reports :visible="preparedReportsVisible"></prepared-reports>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PreparedReports from './PreparedReports';
import { Zone, DateTime } from 'luxon';
import DateNano from '../utils/format.date.time';

import Popper from 'vue-popperjs';
import './../../assets/vue-popper-custom.css';
import { clusterInterval } from '../../../app/datetimeHumanize';

export default {
  name: 'top-app',
  components: { PreparedReports, popper: Popper },
  data() {
    return {
      // eslint-disable-next-line no-undef
      now: Date.now(),
      offset: 0,
      lang: 'en',
      popperOptions: {
        placement: 'bottom',
        modifiers: { offset: [0, 10] },
      },
    };
  },
  computed: {
    ...mapGetters(['pendingReports', 'serverTimeOffset']),
    preparedReportsVisible: {
      get() {
        return this.$store.state.preparedReportsVisible;
      },
      set(value) {
        if (value) {
          this.$store.dispatch('showPreparedReports', true);
        }
      },
    },
    timeObject() {
      const serverTime = new DateNano(this.now + this.serverTimeOffset)
        .setTimezoneOffset(this.offset)
        .customFormat('#DD#.#MM#.#YYYY# #hhhh#:#mm#:#ss#');
      return {
        localTime: new DateNano(this.now).setTimezoneOffset(this.offset).customFormat('#hhhh#:#mm#:#ss#'),
        time: DateTime.fromMillis(this.now + this.serverTimeOffset).toLocaleString(DateTime.TIME_WITH_SECONDS),
        timeDescription:
          this.$t('Server time differs by') +
          ' ' +
          clusterInterval(this.serverTimeOffset, this.lang).text +
          ' (' +
          serverTime +
          ')',
        dateTime: DateTime.fromMillis(
          new DateNano(this.now + this.serverTimeOffset).setTimezoneOffset(this.offset).getTime()
        ).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS),
      };
    },
    timeDiff() {
      if (Math.abs(this.serverTimeOffset) > 15 * 1e3) {
        return true;
      }
      return false;
    },
    timeColor() {
      if (Math.abs(this.serverTimeOffset) > 10000) {
        return 'red';
      } else if (Math.abs(this.serverTimeOffset <= 10000) && Math.abs(this.serverTimeOffset) > 1000) {
        return 'orange';
      } else {
        return 'black';
      }
    },
  },
  created() {
    var self = this;
    setInterval(function () {
      self.now = Date.now();
    }, 500);
    // setInterval(function () {
    //   self.$store.dispatch('syncTime');
    // }, 5000);
  },
  mounted() {
    // this.$store.commit('increment')
    // console.log('process.env.NODE_ENV', process.env.NODE_ENV);
    window.addEventListener('showPrepared', () => {
      // console.log('ev:user');
      this.preparedReportsVisible = true;
    });
    this.offset = this.$ls.get('offset');
    this.lang = this.$ls.get('lang');
  },
  methods: {},
  watch: {},
};
</script>

<style>
#events-app {
  width: 100%;
}
</style>

<style lang="scss">
#top-app {
  display: flex;
  justify-content: center;
  align-items: center;
}
.report-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  position: relative;
}
.report-progress0 {
  position: absolute;
  background-color: darkgray;
  height: 4px;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}
.report-progress {
  position: absolute;
  background-color: lawngreen;
  height: 4px;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
<style lang="scss"></style>
