<template>
  <div
    :aria-labelledby="modal.name + '-modal__title'"
    :class="[modal.name + '-modal', { show: visible }]"
    :id="modal.name + '-modal'"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered" role="document" :class="modal.size ? 'modal-' + modal.size : ''">
      <div class="modal-content">
        <div class="modal-header" :class="modal.name + '-modal__header'">
          <h5 class="modal-title" :id="modal.name + '-modal__title'">
            <slot name="title">
              <span v-html="$t(`${modal.title}`)"></span>
            </slot>
          </h5>
          <button class="close" type="button" aria-label="Close" @click="$emit('update:visible', false)">
            <span aria-hidden="true"> &times; </span>
          </button>
          <!--          <button-->
          <!--            class="btn btn-primary"-->
          <!--            :class="modal.name + '-modal__submit-btn'"-->
          <!--            :type="modal.typeSubmit ? 'submit' : 'button'"-->
          <!--            :aria-label="modal.submitBtn ? modal.submitBtn : 'Save'"-->
          <!--            :onclick="modal.onSubmit"-->
          <!--            :form="modal.typeSubmit ? modal.name + '-modal__form' : ''"-->
          <!--            :id="$t(modal.submitBtn ? modal.submitBtn : 'Save')"-->
          <!--          ></button>-->
        </div>
        <div class="modal-body" :class="modal.name + '-modal__body'">
          <slot name="content"></slot>
        </div>
        <div class="modal-footer" :class="modal.name + '-modal__footer'">
          <slot name="buttons">
            <button
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
              :aria-label="modal.closeBtn ? modal.closeBtn : 'Cancel'"
              :id="$t(`${modal.closeBtn ? modal.closeBtn : 'Cancel'}`)"
              @click="$emit('closeModal')"
            >
              {{ $t(`${modal.closeBtn ? modal.closeBtn : 'Cancel'}`) }}
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-dialog',
  props: {
    modal: Object,
    visible: Boolean,
  },
  components: {},
  data: () => {
    return {
      // modalName: 'fff',
    };
  },
  mounted() {},
  methods: {
    showModal() {
      var locModal = document.getElementById(this.modal.name + '-modal');
      locModal.style.display = 'block';
      locModal.style.paddingRight = '17px';
      locModal.className = 'modal fade show';
    },
    hideModal() {
      var locModal = document.getElementById(this.modal.name + '-modal');
      locModal.style.display = 'none';
      locModal.className = 'modal fade';
      console.log('hide');
    },
  },
  watch: {
    visible: function (val) {
      if (val) {
        this.showModal();
      } else {
        this.hideModal();
      }
    },
  },
};
</script>

<style lang="scss"></style>
<style lang="scss"></style>
