<template>
  <div class="labeled-block">
    <div v-if="getSlotText !== '' && !empty" class="labeled-block__label">
      <div class="labeled-block__title">{{ title }}</div>
      <div v-if="count" class="labeled-block__counter">{{ count }}</div>
    </div>
    <div class="labeled-block__content">
      <div :class="{ 'labeled-block__text': type === 'text', 'items-list': type === 'list' }">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'labeled-block',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    title: {
      type: String,
    },
    count: {
      type: Number,
    },
    empty: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
    getSlotText() {
      let out = '';
      if (this.$slots.default) {
        this.$slots.default.forEach((vnode) => {
          // console.log('vnode', vnode);
          if (vnode.text) {
            out += vnode.text.trim();
          } else if (vnode.elm && vnode.elm.innerText) {
            out += vnode.elm.innerText.trim();
          } else {
            out += 'else';
            // console.log('else', vnode);
          }
        });
      }
      return out;
    },
  },
  mounted() {
    // console.log('this.$slots.default', this.$slots.default);
  },
};
</script>

<style scoped></style>
