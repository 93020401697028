<template>
  <div class="signal-view position-relative">
    <p class="mb-0">
      {{ $t('Table below shows the list of signals that might have changed during the selected time interval.') }}
    </p>
    <p>
      {{
        $t(
          'To view all of them, click "View all" or select the signals you are interested in and click "View selected"'
        )
      }}
    </p>
    <div class="data-table">
      <div class="data-table-buttons events-top-controllers">
        <div class="dt-buttons btn-group">
          <template v-for="b in buttons">
            <div class="btn-group" v-if="b.children">
              <button
                class="btn btn-primary dropdown-toggle"
                :class="'data-table-' + b.name"
                :id="'submenu-' + b.name"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ $t(b.label) }}
              </button>

              <div class="dropdown-menu" :aria-labelledby="'submenu-' + b.name">
                <div class="dt-button-collection-title"></div>
                <template v-for="cb in b.children">
                  <a class="dt-button dropdown-item buttons-csv buttons-html5" tabindex="0" href="#"
                    ><span>{{ $t(cb.label) }}</span></a
                  >
                </template>
              </div>
            </div>
            <button
              class="btn btn-primary"
              :class="['data-table-' + b.name, !enableView && b.name === 'view-selected' ? 'disabled' : '']"
              @click="execAction(b.action, $event)"
              v-else
            >
              {{ $t(b.label) }}
            </button>
          </template>
        </div>
        <div v-if="!noSearch" id="vue_events_table_filter" class="dataTables_filter">
          <label
            >Поиск:<input
              v-model="searchString"
              type="search"
              class="form-control form-control-sm"
              placeholder=""
              aria-controls="wheel_events_table"
          /></label>
        </div>
      </div>
      <div class="event-types-block">
        <slot name="filters"></slot>
      </div>
      <div class="data-table-loading-wrapper">
        <loading :active.sync="loading" :can-cancel="false" :is-full-page="false"> </loading>

        <table class="data-table display" :class="className">
          <thead>
            <tr role="row">
              <template v-for="f in fields">
                <th v-if="f.visible !== false" :key="f.name" :class="columnSortClass(f)" @click="changeSort(f)">
                  {{ $t(f.label) }}
                </th>
              </template>
            </tr>
          </thead>

          <tbody>
            <data-row
              v-for="(rowData, idx) in rowsData"
              :key="rowData.id"
              :row-data="rowData"
              :fields="fields"
              :class-name="[{ even: idx % 2 === 0, odd: idx % 2 === 1, selected: isSelected(idx) }]"
              @select="selectRow(idx)"
            ></data-row>
          </tbody>
          <tfoot>
            <tr role="row">
              <template v-for="f in fields">
                <th v-if="f.visible !== false" :key="f.name">
                  {{ $t(f.label) }}
                </th>
              </template>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="data-info">
        <div class="events-page-info-vue">
          <div class="dataTables_length" id="wheel_events_table_length">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-sizes="[4, 17, 50, 100, 300]"
              :page-size="pageLength"
              layout="sizes, prev, pager, next"
              :total="recordsFiltered"
            >
            </el-pagination>
          </div>
          <div class="dataTables_info" id="wheel_events_table_info" role="status" aria-live="polite">
            <template v-if="recordsFiltered !== recordsTotal">
              Записи с {{ recordLeft }} до {{ recordRight }} из {{ recordsFiltered }} записей (отфильтровано из
              {{ recordsTotal }} записей)
            </template>
            <template v-else> Записи с {{ recordLeft }} до {{ recordRight }} из {{ recordsTotal }} записей </template>
          </div>
        </div>
      </div>
      <div class="data-pages">
        <div class="dataTables_paginate paging_simple_numbers" id="wheel_events_table_paginate"></div>
      </div>
    </div>

    <div class="jumbotron" style="display: none">
      <h1>Нет, тут смотреть нечего.</h1>
      <p>Похоже, для этого события мы не можем ничего отобразить. Попробуйте посмотреть другое событие</p>
    </div>
    <div class="loading" style="display: none">
      <div class="wrapper"><div class="cssload-loader"></div></div>
    </div>
    <div class="loading" style="display: none">
      <div class="wrapper"><div class="cssload-loader"></div></div>
    </div>
  </div>
</template>

<script>
import DataRow from './DataRow';
// import loading from 'vue-loading';
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

import axios from 'axios';
export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    className: {
      type: String,
    },
    // buttons: {
    //   type: Array,
    // },
    fields: {
      type: Array,
    },
    lengthMenu: {
      type: Array,
    },
    noSearch: {
      defauld: false,
    },
    offTypes: {
      type: Array,
    },
    query: {
      type: Object,
    },
  },
  components: { DataRow, Loading },
  // directives: { loading },
  name: 'signals-meta',
  data: () => {
    return {
      sortables: [],
      currentSortField: '',
      currentSortDir: -1,
      currentPage: 1,
      pageStart: 0,
      pageLength: 17,
      searchString: '',
      // searchTypes: [],
      rowsData: [],
      recordsTotal: 0,
      recordsFiltered: 0,
      loading: false,
      draw: 0,
      errors: [],
      selectedRows: [],
      buttons: [
        {
          name: 'view-all',
          label: 'View all',
          action: 'openAll',
        },
        {
          name: 'view-selected',
          label: 'View selected',
          action: 'openSelected',
        },
      ],
    };
  },
  computed: {
    rowsDataSelected() {
      return this.rowsData.filter((e, i) => this.selectedRows.indexOf(this.pageStart + i) > -1);
    },
    rowsDataSelectedTs() {
      return this.rowsDataSelected.map((e) => e.ts).sort();
    },
    rowsDataSelectedTime() {
      if (this.rowsDataSelectedTs.length > 0) {
        let timestamp = (this.rowsDataSelectedTs[0] + this.rowsDataSelectedTs[this.rowsDataSelectedTs.length - 1]) / 2;
        let timeBefore = timestamp - this.rowsDataSelectedTs[0] + (this.rowsDataSelectedTs.length > 1 ? 0 : 1e7);
        let timeAfter = timestamp - this.rowsDataSelectedTs[0] + (this.rowsDataSelectedTs.length > 1 ? 0 : 1e7);
        return {
          timestamp,
          timeBefore,
          timeAfter,
        };
      }
    },
    enableView() {
      return this.selectedRows.length > 0;
    },
    recordLeft() {
      return this.pageStart + 1;
    },
    recordRight() {
      return Math.min(this.pageStart + this.pageLength, this.recordsTotal);
    },
    minPage() {
      return 1;
    },
    maxPage() {
      return Math.max(Math.ceil(this.recordsTotal / this.pageLength), 1);
    },
    pages() {
      let pages = [];
      pages.push(this.currentPage);
      pages.push(this.minPage);
      pages.push(this.maxPage);
      if (this.currentPage - 1 > this.minPage) {
        pages.push(this.currentPage - 1);
      }
      if (this.currentPage + 1 < this.maxPage) {
        pages.push(this.currentPage + 1);
      }
      if (this.currentPage - this.minPage === 3) {
        pages.push(this.currentPage - 2);
      }
      if (this.maxPage - this.currentPage === 3) {
        pages.push(this.currentPage + 2);
      }
      if (this.currentPage - this.minPage < 3) {
        for (let i = this.minPage; i < this.minPage + 5 && this.maxPage > i; i++) {
          pages.push(i);
        }
      }
      if (this.maxPage - this.currentPage < 3) {
        for (let i = this.maxPage; i > this.maxPage - 5 && this.minPage < i; i--) {
          pages.push(i);
        }
      }
      pages = [...new Set(pages)];
      return pages.sort(function (a, b) {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });
    },
    pagesList() {
      let list = [];
      for (let i = 0; i < this.pages.length; i++) {
        list.push(this.pages[i]);
        if (i + 1 < this.pages.length && this.pages[i] + 1 !== this.pages[i + 1]) {
          list.push(null);
        }
      }
      return list;
    },
    offTypesArray() {
      return this.offTypes && this.offTypes.map((e) => '!' + e);
    },
    queryString() {
      let order = [];
      let search = this.search;
      order.push(this.order);
      let searchObj = {
        draw: this.draw,
        order,
        search,
        start: this.pageStart,
        length: this.pageLength,
      };

      if (this.query) {
        return this.$qs.stringify({ ...this.query, ...searchObj, removeUnfiltered: true });
      } else {
        return this.$qs.stringify(searchObj);
      }
    },
  },
  mounted() {
    this.setDefaultSort();
    this.loadData();
    console.log('r', this.$route);
  },
  methods: {
    handleSizeChange(e) {
      this.pageLength = e;
      console.log('set size', e);
    },
    handleCurrentChange(e) {
      // this.currentPage = e;
      console.log('set page', e);
    },
    execAction(a, e) {
      if (a) {
        this[a](e);
      }
    },
    openAll(e) {
      this.selectAll();
      this.$nextTick(() => {
        this.$emit('showDygraph', e);
      });
    },
    openSelected(e) {
      this.$emit('showDygraph', e);
    },
    selectAll() {
      this.selectedRows = this.rowsData.map((el, i) => i);
      // const rowInx = this.pageStart + i;
      // console.log('sr', this.selectedRows.indexOf(rowInx), rowInx);
      // if (this.selectedRows.indexOf(rowInx) < 0) {
      //   this.selectedRows.push(rowInx);
      // } else {
      //   this.selectedRows = this.selectedRows.filter((e) => e !== rowInx);
      // }
    },
    selectRow(i) {
      const rowInx = this.pageStart + i;
      console.log('sr', this.selectedRows.indexOf(rowInx), rowInx);
      if (this.selectedRows.indexOf(rowInx) < 0) {
        this.selectedRows.push(rowInx);
      } else {
        this.selectedRows = this.selectedRows.filter((e) => e !== rowInx);
      }
    },
    isSelected(idx) {
      const rowInx = this.pageStart + idx;
      return this.selectedRows.indexOf(rowInx) > -1;
    },
    setCurrentPage(p) {
      if (p) {
        this.currentPage = p;
      }
    },
    loadData() {
      this.draw++;
      console.log('sq', this.queryString);
      let dataUrl = 'getsignalsmeta' + '?' + this.queryString;
      this.selectedRows = [];
      this.loading = true;
      axios
        .get(dataUrl)
        .then((response) => {
          // JSON responses are automatically parsed.
          //           console.log('si resp', response);

          this.rowsData = response.data.desc;
          this.recordsTotal = response.data.recordsTotal;
          this.recordsFiltered = response.data.recordsTotal;

          this.$emit('loadedRows', this.rowsData.length);
          this.loading = false;
        })
        .catch((e) => {
          this.errors.push(e);
          this.loading = false;
        });
    },
    setDefaultSort() {
      if (this.currentSortField === '') {
        this.currentSortField = this.fields[0].name;
      }
      if (this.currentSortDir !== -1) {
        this.currentSortDir = -1;
      }
    },
    columnSortClass(f) {
      if (f.name === this.currentSortField) {
        if (this.currentSortDir === 1) {
          return 'sorting_asc';
        }
        if (this.currentSortDir === -1) {
          return 'sorting_desc';
        }
      } else {
        return f.sortable ? 'sorting' : '';
      }
    },
    changeSort(f) {
      console.log('f', f);
      const prevSortField = this.currentSortField;
      this.currentSortField = f.name;
      if (prevSortField !== this.currentSortField) {
        this.currentSortDir = 1;
      } else {
        if (this.currentSortDir === 1) {
          this.currentSortDir = -1;
        } else {
          this.currentSortDir = 1;
        }
      }
      this.loadData();
    },
  },
  watch: {
    currentPage: function (val, old) {
      if (val !== old) {
        this.pageStart = (this.currentPage - 1) * this.pageLength;
      }
    },
    pageLength: function (val, old) {
      if (val !== old) {
        this.loadData();
      }
    },
    pageStart: function (val, old) {
      if (val !== old) {
        this.loadData();
      }
    },
  },
};
</script>

<style scoped>
.data-table-loading-wrapper {
  position: relative;
}
.data-table {
  width: 100%;
}
.data-table thead .sorting_asc {
  background-image: url(../../../assets/sort_asc.png);
}
.data-table thead .sorting_desc {
  background-image: url(../../../assets/sort_desc.png);
}
.data-table thead .sorting {
  background-image: url(../../../assets/sort_both.png);
}
.data-table .event-types-block {
  display: flex;
}
.data-table .event-types-block {
  margin: 10px 0;
}
.data-table thead .sorting,
.data-table thead .sorting_asc,
.data-table thead .sorting_desc,
.data-table thead .disabled {
  background-repeat: no-repeat;
  background-position: center right;
}
.data-table thead .sorting,
.data-table thead .sorting_asc,
.data-table thead .sorting_desc {
  cursor: pointer;
  *cursor: hand;
}
.data-table .dataTables_filter {
  float: right;
  text-align: right;
}
.data-table thead .sorting {
}

.data-table thead th,
.data-table thead td {
  user-select: none;
  padding: 10px 18px;
  border-bottom: 1px solid #111;
}

.data-table tfoot th,
.data-table tfoot td {
  padding: 10px 18px;
  border-top: 1px solid #111;
}
.data-table thead th,
.data-table tfoot th {
  font-weight: bold;
}

.data-table.display tbody tr.selected {
  background-color: #acbad4;
}
</style>
