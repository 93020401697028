<template>
  <div class="list-checkbox" @click="changeChecked">
    <div class="list-checkbox__icon">
      <img
        v-if="isChecked"
        src="https://uploads-ssl.webflow.com/5f91d5a10b8e3e32addb2532/5f9952a52d5844110634b0e7_box-checked.svg"
        loading="lazy"
        alt=""
      />
      <img
        v-else
        src="https://uploads-ssl.webflow.com/5f91d5a10b8e3e32addb2532/5f9952ab6349fbcb14e34e8c_box.svg"
        loading="lazy"
        alt=""
      />
    </div>
    <div class="list-checkbox__title" :title="item.title !== item.description ? item.description : false">
      {{ item.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'list-checkbox',
  props: {
    item: {
      type: Object,
    },
    activeItem: {
      type: Array,
    },
    ch: {
      type: Boolean,
    },
  },
  computed: {
    isChecked() {
      return this.activeItem.indexOf(this.item.value) > -1;
    },
  },
  methods: {
    changeChecked() {
      if (this.isChecked) {
        //remove
        this.$emit('checked', { action: 'remove', value: this.item.value });
      } else {
        //add
        this.$emit('checked', { action: 'add', value: this.item.value });
      }
    },
  },
};
</script>

<style scoped></style>
