<template>
  <div class="" v-resize:debounce="onResize" ref="thisEl">
    <t-icon v-if="type === 'iconed'" class="items-list__icon" :size="20" :icon="item.icon"></t-icon>
    <div v-if="type === 'tagged'">
      <div class="items-list__tag">
        <div
          class="items-list__tag-title"
          :class="{
            'not-fit': !item.inView,
          }"
        >
          <popper
            trigger="hover"
            :append-to-body="true"
            :options="{
              placement: 'top',
              modifiers: { offset: { offset: '0,10px' } },
            }"
          >
            <div class="popper">
              <div>{{ item.title }}</div>
            </div>
            <ell slot="reference" :text="item.title"></ell>
          </popper>
        </div>
      </div>
    </div>
    <div v-if="type === 'iconed'">
      <div class="items-list__title" :title="item.description">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
import resize from 'vue-resize-directive';
import TIcon from './TIcon';
import Ell from './Ell';

import Popper from 'vue-popperjs';
// import 'vue-popperjs/dist/vue-popper.css';
import './../../../assets/vue-popper-custom.css';

export default {
  name: 'limited-item',
  props: {
    item: {
      type: Object,
    },
    parentSize: {
      type: DOMRect,
    },
    type: {
      type: String,
    },
  },
  components: {
    TIcon,
    Ell,
    popper: Popper,
  },
  directives: {
    resize,
  },
  data: () => {
    return {
      tooBig: false,
    };
  },

  methods: {
    onResize(e) {
      const child = this.$refs.thisEl.getBoundingClientRect();
      const parent = this.parentSize;
      const inView =
        parent.left <= child.left &&
        parent.top <= child.top &&
        parent.right >= child.right &&
        parent.bottom >= child.bottom;
      const right = child.right - parent.left;

      // console.log('w', parent.width, child.width, parent.width <= child.width);
      // this.tooBig = parent.width - 32 < child.width;

      // console.log('child', child);
      // console.log('parent', parent);
      if (this.item.inView !== inView || this.item.right !== right) {
        this.$emit('inViewChange', { inView: inView, right: right });
      }
    },
  },
  watch: {
    parentSize: function (old, val) {
      if (JSON.stringify(old) !== JSON.stringify(val)) this.onResize();
    },
  },
};
</script>

<style scoped>
.popper {
  background: #1757bd;
  color: white;
  font-weight: bold;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 4px;
  border-color: #1757bd;
}
</style>

<style>
.popper[x-placement^='top'] .popper__arrow {
  border-width: 5px 5px 0 5px;
  border-color: #13499e transparent transparent transparent;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
</style>
