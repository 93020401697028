import Vue from 'vue';

import EventsApp from './components/EventsApp';
import TopApp from './components/TopApp';
import AboutApp from './components/AboutApp';
import MenuReportsApp from './components/MenuReportsApp';
import LastEventsTable from './components/LastEventsTable';
import store from './store';
import mutations from './store/mutation-types';
import i18n from './i18n';
import Teledom from 'vue-teledom';
import router from './router';
import Vuebar from 'vuebar';
import SecureLS from 'secure-ls';
var ls = new SecureLS({ isCompression: false });
Vue.use(Vuebar);

// import * as io from 'socket.io-client';
// import VueSocketIO from 'vue-socket.io';
//
// Vue.use(VueSocketIO, io('http://localhost:3000'), store);

import VueNativeSock from 'vue-native-websocket';

// import VueResizeObserver from "vue-resize-observer";
// Vue.use(VueResizeObserver);
// import resize from 'vue-element-resize-detector';
// Vue.use(resize);

// import checkView from 'vue-check-view';
// Vue.use(checkView);

// import VueSimplebar from 'vue-simplebar'
// import 'vue-simplebar/dist/vue-simplebar.min.css'
//
// Vue.use(VueSimplebar)

// import ellipsis from '@hyjiacan/vue-ellipsis'
// Vue.use(ellipsis)import ellipsis from '@hyjiacan/vue-ellipsis'
// Vue.use(ellipsis)
import VueEditableElement from 'vue-editable-element';
import PortalVue from 'portal-vue';

Vue.use(PortalVue);

import VueObserveVisibility from 'vue-observe-visibility';
Vue.use(VueObserveVisibility);

Vue.use(VueEditableElement);
import VScrollLock from 'v-scroll-lock';
Vue.use(VScrollLock, {
  bodyScrollOptions: {
    reserveScrollBarGap: true,
  },
});

import {
  Slider,
  DatePicker,
  Input,
  Button,
  Tag,
  Dialog,
  Form,
  FormItem,
  Pagination,
  RadioButton,
  RadioGroup,
  Table,
  TableColumn,
} from 'element-ui';
// import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import ruLang from 'element-ui/lib/locale/lang/ru-RU';
import enLang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

const elLangs = {
  ru: ruLang,
  en: enLang,
};

locale.use(enLang);
// locale.use(elLangs['en']);

Vue.use(Slider);
Vue.use(DatePicker);
Vue.use(Input);
Vue.use(Button);
Vue.use(Tag);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Pagination);
Vue.use(RadioButton);
Vue.use(RadioGroup);
Vue.use(Table);
Vue.use(TableColumn);
// Vue.use(Icon);
// Vue.use(ElementUI);

import VueLocalStorage from 'vue-ls';
Vue.use(VueLocalStorage, {
  namespace: 'vue.',
});

import '../assets/cluster.scss';
import '../assets/cluster-common.scss';

// import Loading from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/vue-loading.css';
const queryString = require('qs');

// Vue.use(Loading);
Vue.use(Teledom);
// Vue.use(Sticky)
Vue.config.devtools = true;
Vue.config.debug = true;
Vue.config.productionTip = false;
Vue.config.silent = true;
Vue.config.disableNoTranslationWarning = true;

// eslint-disable-next-line no-undef
Vue.prototype.$main = main;
Vue.prototype.$ = window.$;
Vue.prototype.$tpws = window.tpws;
Vue.prototype.$tpui = window.tpui;
Vue.prototype.$qs = queryString;

console.log('Active locale: ', i18n.locale);

const VueCommon = {
  i18n,
  store,
  router,
};

// new Vue({
//   ...VueCommon,
//   data: {},
//   render: (h) => h(EventsApp),
// }).$mount('#events-block');

new Vue({
  ...VueCommon,
  data: {},
  mounted() {
    const wsProtocol = location.protocol !== 'https:' ? 'ws' : 'wss';
    let wsUrl;
    const wsPort = location.port;
    if (wsPort) {
      wsUrl = `${wsProtocol}://${location.hostname}:${wsPort}`;
    } else {
      wsUrl = `${wsProtocol}://${location.hostname}`;
    }
    console.log('init vue', wsUrl);
    Vue.use(VueNativeSock, wsUrl, {
      reconnection: true, // (Boolean) whether to reconnect automatically (false)
      reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
      reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
      store: store,
      mutations: mutations,
    });
    Vue.ls.set('test', locale);
    window.addEventListener('user', () => {
      console.log('ev user');
      Vue.ls.set('lang', Vue.ls.get('user').preferences.language);
      Vue.ls.set('offset', Vue.ls.get('user').preferences.timezoneOffset);
      ls.set('userTimeOffset', Vue.ls.get('user').preferences.timezoneOffset);
      const lang = Vue.ls.get('lang');
      this.switchLocale(lang);
      this.switchLang(lang);
      console.log('i18', this.$i18n.locale, this.$i18n);
    });
    window.addEventListener('tpws', () => {
      console.log('ev:tpws');
    });
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }
    },
    switchLang(lang) {
      locale.use(elLangs[lang]);
    },
  },

  render: (h) => h(TopApp),
}).$mount('#top-block');

new Vue({
  ...VueCommon,
  data: {},
  render: (h) => h(AboutApp),
}).$mount('#about-disk-block');

new Vue({
  ...VueCommon,
  data: {},
  render: (h) => h(MenuReportsApp),
}).$mount('#reports-block');

new Vue({
  ...VueCommon,
  data: {},
  render: (h) => h(LastEventsTable),
}).$mount('#recentEventsVue');

Vue.config.silent = false;
