<template>
  <div class="period-picker">
    <div class="locker" v-scroll-lock="focus">
      <el-date-picker
        key="date-1"
        v-model="value1"
        type="datetime"
        placeholder="Начало"
        :clearable="true"
        :default-value="value1default"
        style="margin-bottom: 8px"
        format="dd.MM.yyyy HH:mm:ss"
        value-format="timestamp"
        :picker-options="startPickerOptions"
        @change="changeLeft"
        @focus="onFocus"
        @blur="onBlur"
      >
      </el-date-picker>
      <el-date-picker
        key="date-2"
        v-model="value2"
        type="datetime"
        placeholder="Конец"
        :clearable="true"
        :default-value="value2default"
        format="dd.MM.yyyy HH:mm:ss"
        value-format="timestamp"
        :picker-options="finishPickerOptions"
        @change="changeRight"
        @focus="onFocus"
        @blur="onBlur"
      >
      </el-date-picker>
    </div>
  </div>
</template>

<script>
import DateNano from '../../utils/format.date.time';
import { mapGetters } from 'vuex';
export default {
  name: 'period-picker',
  props: {
    value: {
      type: [Object, Array, undefined],
    },
  },
  data: () => {
    return {
      value1: '',
      value2: '',
      focus: false,
      lang: '',
      offset: '',
    };
  },
  computed: {
    ...mapGetters([
      'filters',
      // ...
    ]),
    bounds() {
      return this.filters.clusterDateBounds;
    },
    value1default() {
      return this.filters.clusterDateBounds.min;
    },
    value2default() {
      return this.filters.clusterDateBounds.max;
    },
    startPickerOptions() {
      let _t = this;
      const tl = _t.filters.clusterDateBounds.min;
      const tr = _t.filters.clusterDateBounds.max;
      // console.log(new DateNano(tlf).toISOString(), new DateNano(trf).toISOString());

      return {
        disabledDate(time) {
          return time.getTime() < tl || time.getTime() > tr;
        },
        shortcuts: [
          // {
          //   text: 'Start date',
          //   onClick(picker) {
          //     const end = new Date();
          //     const start = new Date();
          //     start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          //     picker.$emit('pick', [start, end]);
          //   },
          // },
        ],
      };
    },
    finishPickerOptions() {
      let _t = this;
      const tl = _t.filters.clusterDateBounds.min;
      const tr = _t.filters.clusterDateBounds.max;
      // console.log(new DateNano(tlf).toISOString(), new DateNano(trf).toISOString());

      return {
        disabledDate(time) {
          return time.getTime() < tl || time.getTime() > tr;
        },
        shortcuts: [
          // {
          //   text: 'Start date',
          //   onClick(picker) {
          //     const end = new Date();
          //     const start = new Date();
          //     start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          //     picker.$emit('pick', [start, end]);
          //   },
          // },
        ],
      };
    },
    dateLeft() {
      try {
        return this.value && this.value.min;
      } catch (e) {}
    },
    dateRight() {
      try {
        return this.value && this.value.max;
      } catch (e) {}
    },
  },
  created() {
    this.offset = this.$ls.get('offset');
    this.lang = this.$ls.get('lang');
    var _this = this;
    this.$ls.on('offset', function (val) {
      console.log('ooff', val);
      _this.offset = val;
    });
    this.$ls.on('lang', function (val) {
      console.log('ooff', val);
      _this.lang = val;
    });
  },
  mounted() {
    console.log('datepicker start', JSON.stringify(this.value), JSON.stringify(this.bounds));
    if (this.value.min) {
      this.value1 = this.value.min;
    } else {
      this.value1 = this.filters.clusterDateBounds.min;
    }
    if (this.value.max) {
      this.value2 = this.value.max;
    } else {
      this.value2 = this.filters.clusterDateBounds.max;
    }
  },
  methods: {
    changeLeft(v) {
      console.log('left', v, this.value1default, this.value1);
      if (this.value1 === null || this.value1 < this.value1default || this.value1 > this.value2default) {
        this.value1 = this.value1default;
      }
      this.$emit('change', { min: this.value1, max: this.value2 });
    },
    changeRight(v) {
      console.log('right', v, this.value2default, this.value2);
      if (this.value2 === null || this.value2 < this.value1default || this.value2 > this.value2default) {
        this.value2 = this.value2default;
      }
      this.$emit('change', { min: this.value1, max: this.value2 });
    },

    onFocus() {
      console.log('onFocus');
      this.focus = true;
    },
    onBlur() {
      console.log('onBlur');
      this.focus = false;
    },
  },
  watch: {
    dateLeft: function (val) {
      this.value1 = val;
    },
    dateRight: function (val) {
      this.value2 = val;
    },
  },
};
</script>

<style scoped></style>
