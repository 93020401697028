<template>
  <div class="t-ellipsis" ref="root" v-resize:debounce="onResize">
    <div v-if="!tested" class="t-ellipsis__test" ref="test" v-html="start"></div>
    <div v-else class="t-ellipsis__begin" v-html="start"></div>
    <div v-if="end" class="t-ellipsis__end" v-html="end"></div>
  </div>
</template>

<script>
import resize from 'vue-resize-directive';
export default {
  name: 'ell',
  props: {
    text: {
      type: String,
    },
  },
  directives: {
    resize,
  },
  data: () => {
    return {
      start: '',
      end: '',
      tested: false,
      tr: false,
    };
  },
  created() {
    this.start = this.text;
  },
  mounted() {},
  methods: {
    onResize() {
      this.tested = false;
      this.$nextTick(() => {
        const parent = this.$refs.root.getBoundingClientRect();
        const child = this.$refs.test.getBoundingClientRect();
        this.tr = parent.width < child.width;
        this.tested = true;
        if (this.tr) {
          const lastSpace = this.text.lastIndexOf(' ');
          if (lastSpace > Math.floor(this.text.length * 0.6)) {
            this.start = this.text.substring(0, lastSpace);
            this.end = '&nbsp;' + this.text.substring(lastSpace + 1, this.text.length);
          } else {
            this.start = this.text;
            this.end = '';
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.t-ellipsis {
  display: flex;
  &__begin {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__end {
    flex: 0 0 auto;
  }
}
</style>
