<template>
  <a v-show="preparedReportsCount > 0" class="nav-link pointer" @click="showPreparedReports">
    <span class="fal fal-menu fa-file-code" aria-hidden="true"></span>
    {{ $t('Prepared reports') }}
    <span>({{ preparedReportsCount }})</span>
  </a>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'menu-reports-app',
  data() {
    return {
      reportDialogVisible: false,
    };
  },
  computed: {
    ...mapGetters(['preparedReportsCount']),
  },

  mounted() {},
  methods: {
    showPreparedReports() {
      this.$store.dispatch('showPreparedReports', true);
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.pointer {
  cursor: pointer;
}
</style>
<style lang="scss"></style>
