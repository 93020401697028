<template>
  <span
    :class="['editable-span', { 'editable-multiline': multiline }]"
    :ref="'editable' + _uid"
    :id="'editable' + _uid"
    :contenteditable="editable || over"
    spellcheck="false"
    @mouseover="over = true"
    @mouseout="over = false"
    @click="beginEditable"
    v-on="listeners"
  >
  </span>
</template>

<script>
export default {
  name: 'editable-span',
  props: {
    value: {
      type: String,
      default: '',
    },
    multiline: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      editable: false,
      over: false,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        keydown: this.onKeydown,
        input: this.onInput,
        focus: this.onFocus,
        blur: this.onBlur,
      };
    },
    text() {
      return this.$refs['editable' + this._uid].innerText.split('<br/>').join('/n');
    },
  },
  mounted() {
    this.$refs['editable' + this._uid].innerText = this.value;
    console.log('mnt editable' + this._uid);
  },
  methods: {
    beginEditable(e) {
      this.editable = true;
    },
    onClick(e) {
      console.log('click', e.target, 'editable' + this._uid);
      // e.preventDefault();
      // e.stopPropagation();
      this.$refs['editable' + this._uid].focus();
      this.$emit('click', e.target.innerText);
    },
    onFocus(e) {
      console.log('focus', e.target, 'editable' + this._uid);
      e.preventDefault();
      e.stopPropagation();
      this.$refs['editable' + this._uid].focus();
      var rangeobj = document.createRange();
      var selectobj = window.getSelection();
      var el = document.getElementById(['editable' + this._uid]);
      console.log('el', el);
      rangeobj.setStart(el, 0);
      rangeobj.setEnd(el, 0);
      rangeobj.collapse(true);
      selectobj.removeAllRanges();
      selectobj.addRange(rangeobj);

      this.$emit('focus', e.target.innerText);
    },
    onBlur(e) {
      console.log('blur', e.target.innerText, e.target, 'editable' + this._uid);
      this.$emit('blur', e.target.innerText);
      e.preventDefault();
      this.$refs['editable' + this._uid].blur();

      this.editable = false;
      if (this.value !== e.target.innerText) {
        this.$emit('change', e.target.innerText);
      }
    },
    onKeydown(e) {
      if (this.multiline) {
        if (e.key === 'Enter' && e.ctrlKey) {
          this.$refs['editable' + this._uid].blur();
          e.preventDefault();
          // this.$emit('change', e.target.innerText);
          console.log('onKeydown', e);
        }
      } else {
        if (e.key === 'Enter') {
          this.$refs['editable' + this._uid].blur();
          e.preventDefault();
          // this.$emit('change', e.target.innerText);
          console.log('onKeydown', e);
        }
      }
    },
    onInput(e) {
      console.log('input', e);
      // this.$emit('input', e.target.innerText);
    },
  },
  watch: {
    value: function (v) {
      this.$refs['editable' + this._uid].innerText = this.value;
    },
  },
};
</script>

<style lang="scss">
.editable-span {
  margin-bottom: 0;
  vertical-align: top;
  position: relative;
  display: inline-block;
  //min-width: 100px;
  box-sizing: border-box;
  padding-top: 0;
  padding-right: 42px;
  padding-left: 0;
  padding-bottom: 0;
  border-bottom: 2px dashed #7080902e;
  outline: none;
}
[contenteditable='true'] {
}
[contenteditable='true']:hover {
}

[contenteditable='true']:focus {
  border-bottom: 2px solid #00f;
  //background: aliceblue;
}
.editable-span:before {
  content: '\200B';
}
.editable-span:after {
  content: '\00a0\e78c';
  position: absolute;
  right: 2px;
  top: 50%;
  margin-top: -12px;
  font-family: element-icons !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: baseline;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
