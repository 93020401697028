var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tl-row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-left",domProps:{"innerHTML":_vm._s(_vm.renderData(_vm.rowData, _vm.getField(_vm.fields, 'timeDT')))}}),_c('div',{staticClass:"col text-left"},[(_vm.gap)?[_c('span',{attrs:{"title":_vm.$t('Prior cluster interval')}},[_c('i',{staticClass:"fal fa-arrow-up",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.gap))])]:_vm._e()],2),_c('div',{staticClass:"col text-left"},[(_vm.len)?[_c('span',{attrs:{"title":_vm.$t('Cluster duration')}},[_c('i',{staticClass:"fal fa-arrows-v",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.len))])]:_vm._e()],2),_c('div',{staticClass:"col text-right"},[_c('a',{staticClass:"text-info pointer",on:{"click":function($event){_vm.execAction(_vm.getField(_vm.fields, 'nameDT').action, $event, _vm.rowData)}}},[_vm._v(_vm._s(_vm.$t('Name') + ': ' + _vm.renderData(_vm.rowData, _vm.getField(_vm.fields, 'nameDT'))))])])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-left",domProps:{"innerHTML":_vm._s(_vm.$t('Types') + ': ' + _vm.renderData(_vm.rowData, _vm.getField(_vm.fields, 'evTypes')))}}),(_vm.renderData(_vm.rowData, _vm.getField(_vm.fields, 'devCount')) > 0)?_c('div',{staticClass:"col text-left",domProps:{"innerHTML":_vm._s(
              _vm.$t('Devices') +
              ': ' +
              _vm.renderData(_vm.rowData, _vm.getField(_vm.fields, 'evNames')) +
              (_vm.renderData(_vm.rowData, _vm.getField(_vm.fields, 'devCount')) > 1
                ? '&nbsp;<span class=\'badge badge-light bot\'>' +
                  _vm.renderData(_vm.rowData, _vm.getField(_vm.fields, 'devCount')) +
                  '</span>'
                : '')
            )}}):_vm._e()])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-left"}),_c('div',{staticClass:"col text-right"},[_c('a',{staticClass:"text-info pointer",on:{"click":function($event){_vm.execAction(_vm.getField(_vm.fields, 'evCount').action, $event, _vm.rowData)}}},[_vm._v(_vm._s(_vm.$t('Events') + ': ' + _vm.renderData(_vm.rowData, _vm.getField(_vm.fields, 'evCount'))))])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }