<template>
  <div class="range-filter">
    <div class="range-filter__slider">
      <el-slider
        class="r1"
        v-if="range && value"
        :value="value"
        :range="true"
        :min="min"
        :max="max"
        ref="sli"
        @input="updateInputs"
        @change="onChangeValue"
      >
      </el-slider>
    </div>
    <div class="range-filter__values">
      <template v-if="isArray">
        <el-input
          class="range-filter__value"
          style="margin-right: 8px"
          type="text"
          v-model="minValueTmp"
          :value="minValue"
          @change="updateMinValue"
        ></el-input>
        <el-input
          class="range-filter__value"
          style=""
          type="text"
          v-model="maxValueTmp"
          :value="maxValue"
          @change="updateMaxValue"
        ></el-input>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'range-filter',
  props: {
    range: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      minValueTmp: 0,
      maxValueTmp: 0,
    };
  },
  computed: {
    ...mapGetters([
      'filters',
      'filterValues',
      // ...
    ]),
    minValue: {
      get() {
        return this.filterValues.clusterSize.min;
      },
      set(e) {
        console.log('clusterSize min val set', e);
        // this.minValueTmp = e;

        this.$store.dispatch('setClusterSize', { min: e, max: this.maxValue });
      },
    },
    maxValue: {
      get() {
        return this.filterValues.clusterSize.max;
      },
      set(e) {
        console.log('clusterSize max val set', e);
        // this.maxValueTmp = e;
        this.$store.dispatch('setClusterSize', { min: this.minValue, max: e });
      },
    },
    value: {
      get() {
        return [this.filterValues.clusterSize.min, this.filterValues.clusterSize.max];
      },
      set(e) {
        console.log('clusterSize value set', e);
        this.$store.dispatch('setClusterSize', { min: e[0], max: e[1] });
      },
    },
    min() {
      return this.filters.clusterSizeBounds.min;
    },
    max() {
      return this.filters.clusterSizeBounds.max;
    },
    isArray() {
      return this.value && Array.isArray(this.value);
    },
  },
  mounted() {
    this.minValueTmp = this.minValue;
    this.maxValueTmp = this.maxValue;
    this.$store.watch(
      (state) => state.clusterFilterValues.clusterSize.min,
      (newValue, oldValue) => {
        this.minValueTmp = newValue;
      }
    );
    this.$store.watch(
      (state) => state.clusterFilterValues.clusterSize.max,
      (newValue, oldValue) => {
        this.maxValueTmp = newValue;
      }
    );
  },
  methods: {
    updateInputs(e) {
      console.log('inp', e);
      this.value = e;
    },
    onChangeValue(e) {
      this.$nextTick(() => {
        this.$emit('change', { min: e[0], max: e[1] });
      });
    },
    updateMinValue(e) {
      this.minValue = e;
      this.$refs.sli.setValues();
    },
    updateMaxValue(e) {
      this.maxValue = e;
      this.$refs.sli.setValues();
    },
    onChange(e) {
      // this.$set(this.v, e);
      this.v = e;
      console.log(e, this.v);
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.range-filter {
  &__slider {
    padding-left: 16px;
    padding-right: 16px;
    max-width: 220px;
  }
  &__values {
    display: flex;
    max-width: 220px;
  }
  &__value {
    text-align: center;
    flex: 1;
    & input {
      text-align: center;
    }
  }
}
</style>
