<template>
  <tr role="row" :class="className" @click="$emit('select')" :data-gap="gap">
    <template v-for="f in fields">
      <td
        v-if="f.visible !== false"
        :key="f.name"
        :data-gap="f.name === 'timeDT' && gap"
        :class="{ pointer: !!f.action, 'show-gap': f.name === 'timeDT' && gap }"
        v-html="renderData(rowData, f)"
        @click="execAction(f.action, $event, rowData)"
      ></td>
    </template>
  </tr>
</template>

<script>
export default {
  props: {
    rowData: Object,
    fields: Array,
    className: Array,
    gap: String,
  },
  name: 'data-row',
  methods: {
    execAction(a, e, d) {
      console.log('execAction', a, e);
      if (a) {
        e.stopImmediatePropagation();
        this.$emit('colAction', a, e, d);
      }

      // if (a) {
      //   e.stopImmediatePropagation();
      //   this[a](e);
      // }
    },
    // showProfile(e) {
    //   e.stopImmediatePropagation();
    //   this.$emit('colAction', e);
    // },
    renderData(row, f) {
      if (f.render && typeof f.render === 'function') {
        return f.render(row[f.data], row, row);
      } else {
        return row[f.data];
      }
    },
  },
};
</script>

<style scoped>
.data-table.stripe tbody tr.odd,
.data-table.display tbody tr.odd {
  background-color: #fbfbfb;
}
.data-table.display tbody td {
  border-top: 1px solid #ddd;
}
.data-table.display tbody tr:hover:not(.child) {
  background-color: #f6f6f6;
}
.data-table.display tbody tr.selected:hover:not(.child) {
  background-color: #aab7d1;
}
.data-table tbody td {
  padding: 8px 10px;
}
.data-table tbody .high-row td {
  padding: 22px 10px;
}
.data-table tbody td.show-gap {
  position: relative;
}
.data-table tbody td.show-gap::before {
  content: attr(data-gap);
  width: 130px;
  height: 3px;
  line-height: 3px;
  color: #007bff;
  text-align: center;
  font-size: 16px;
  background-color: white;
  position: absolute;
  left: 50%;
  margin-left: -65px;
  margin-top: -2px;
  top: 0%;
}
.data-table tbody td.pointer {
  cursor: pointer;
}
</style>
