<template>
  <div class="timeline__item">
    <div class="timeline__line"></div>
    <div class="timeline__interval">
      <div class="interval-block">
        <div class="interval-block__label">{{ $i18n.t('Interval') }}:</div>
        <div class="interval-block__value">{{ interval }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'timeline-interval',
  props: {
    interval: {
      type: String,
    },
  },
};
</script>

<style scoped></style>
