<template>
  <div id="last-events-table">
    <a href="?p=wheel" class="wh-back d-none"
      >{{ $t('Back to') }} <span class="fal fa-asterisk"></span> {{ $t('Chart') }}</a
    >
    <div class="wh-page">
      <events-table-lite
        v-if="visibleTable"
        :fields="fields"
        :query="{ search }"
        :url="url"
        :length-menu="lengthMenu"
        ref="eventsTable"
      >
        <template slot="filters"> </template>
      </events-table-lite>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventsTableLite from './DataTable/EventsTableLite';

export default {
  name: 'last-events',
  components: { EventsTableLite },

  data: () => {
    return {
      reportDialogVisible: false,
      visibleTable: false,
      search: {},
      lengthMenu: [5, 10],
    };
  },
  computed: {
    ...mapGetters(['preparedReportsCount']),
    url() {
      return `/projects/${this.$tpws.projectName}/events2`;
    },

    fields() {
      return [
        {
          label: this.$i18n.t('Date & Time'),
          name: 'timeDT',
          data: 'timeDT',
          sortable: true,
        },
        {
          label: this.$i18n.t('Type'),
          name: 'typeDT',
          data: 'typeDT',
          sortable: true,
        },
        {
          label: this.$i18n.t('IED'),
          name: 'iedNameDT',
          data: 'iedNameDT',
          sortable: true,
        },
        {
          label: this.$i18n.t('Control Block Name'),
          name: 'controlBlockDT',
          data: 'controlBlockDT',
          sortable: true,
        },
        {
          label: this.$i18n.t('Description'),
          name: 'descriptionsDT',
          data: 'descriptionsDT',
          sortable: true,
        },
      ];
    },
  },

  mounted() {
    window.addEventListener('loadRecent', (e) => {
      this.search = {
        value: e.detail.name,
        regex: false,
      };
      this.$nextTick(() => {
        this.visibleTable = true;
      });

      console.log('ev:loadRecent', e, this.search);
    });
  },
  methods: {
    showPreparedReports() {
      this.$store.dispatch('showPreparedReports', true);
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.pointer {
  cursor: pointer;
}
</style>
<style lang="scss"></style>
