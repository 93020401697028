<template>
  <el-dialog
    width="50%"
    :modal-append-to-body="true"
    :append-to-body="true"
    :modal="true"
    :title="$t('Prepared reports')"
    :visible="visible"
    @close="hidePreparedReports"
  >
    <el-table :data="preparedReports" style="width: 100%">
      <el-table-column property="filename" :label="$t('Filename')"></el-table-column>
      <el-table-column :label="$t('Actions')" align="center" width="200">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            circle
            @click="handleDelete(scope.$index, scope.row)"
          ></el-button>
          <el-button size="mini" type="success" @click="handleDownload(scope.$index, scope.row)">{{
            $t('Download')
          }}</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  name: 'prepared-reports',
  props: {
    visible: {
      default: false,
    },
  },
  computed: {
    ...mapGetters(['preparedReports']),
    // todo: {
    //     get () { return this.$store.state.preparedReports},
    //     set (value) { this.updateTodo(value) }
    // }
  },
  methods: {
    handleDelete(i, r) {
      console.log(i, r);
      this.$store.dispatch('removePreparedReport', { filename: r.downloadPath });
    },
    handleDownload(i, r) {
      console.log(i, r);
      var link = document.createElement('a');
      link.href = r.downloadPath;
      link.download = r.filename;
      link.click();
    },
    hidePreparedReports() {
      this.$store.dispatch('showPreparedReports', false);
    },
  },
  watch: {
    visible(v, o) {
      if (v) {
        window.dispatchEvent(new Event('viewedPrepared'));
      }
    },
  },
};
</script>

<style scoped>
button:focus,
textarea:focus,
input:focus {
  outline: none;
}
</style>
