<template>
  <div
    id="events-app"
    v-show="isEventsPage || isClustersPage || isClustersTlPage || isClustersEventsPage || isSignalsPage"
    class=""
  >
    <events-page v-if="isEventsPage" :archive="archive"></events-page>
    <clusters-page v-if="isClustersPage || isClustersTlPage" :tl="isClustersTlPage" :archive="archive"></clusters-page>
    <clusters-events-page v-if="isClustersEventsPage" :archive="archive"></clusters-events-page>
    <signals-page v-if="isSignalsPage" :archive="archive"></signals-page>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DateNano from '../utils/format.date.time';
import EventsPage from './EventsPage';
import ClustersPage from './ClustersPage';
import ClustersEventsPage from './ClustersEventsPage';
import SignalsPage from './SignalsPage';

export default {
  name: 'events-app',
  components: { ClustersPage, ClustersEventsPage, EventsPage, SignalsPage },
  data() {
    return {
      // eslint-disable-next-line no-undef
      m: this.$main,
      timestamp: Date.now(),
      offset: 0,
      archive: false,
    };
  },
  computed: {
    isClustersPage() {
      return this.$route.name === 'project' && this.$route.query.p === 'events-exp';
    },
    isClustersTlPage() {
      return this.$route.name === 'clusters-index';
    },
    isClustersEventsPage() {
      return this.$route.name === 'clusters-profile';
    },
    isEventsPage() {
      return this.$route.name === 'project' && this.$route.query.p === 'events';
    },
    isSignalsPage() {
      return this.$route.name === 'project' && this.$route.query.p === 'signals';
    },

    dateNow() {
      return new DateNano(this.timestamp).setTimezoneOffset(this.offset).customFormat();
    },
    doneTodosCount() {
      return this.$store.state.todos.filter((todo) => todo.done).length;
    },
  },

  mounted() {
    // this.$store.commit('increment')
    console.log('process.env.NODE_ENV', process.env.NODE_ENV);
    this.$store.dispatch('loadPreparedReports');
  },
  methods: {},
  watch: {},
};
</script>

<style>
#events-app {
  width: 100%;
}
</style>

<style lang="scss"></style>
<style lang="scss"></style>
