<template>
  <div class="timeline__item" :id="'cl-' + rowData._id">
    <div class="timeline__line"></div>
    <div class="timeline__dot"></div>
    <div class="timeline__item-block">
      <div class="cluster-block">
        <div class="cluster-block__body">
          <div class="row">
            <div class="col">
              <div class="cluster-name" @click="goClusterProfile(rowData.clusterDT)">
                <div class="cluster-name__title">
                  <template v-if="rowData['name']">{{ rowData['name'] }}</template>
                  <template v-else>
                    <template v-if="rowData['nameEv']"
                      ><span class="gen-cluster-name">{{ rowData['nameEv'] }}</span></template
                    >
                    <template v-else
                      ><span class="gen-cluster-name">{{
                        renderData(rowData, getField(fields, 'nameDT'))
                      }}</span></template
                    >
                  </template>
                </div>
                <div class="cluster-name__actions"><TIcon :size="16" icon="link"></TIcon></div>
                <span v-if="rowData['desc']">
                  &nbsp;
                  <popper
                    trigger="hover"
                    :append-to-body="true"
                    :options="{
                      placement: 'top',
                      modifiers: { offset: { offset: '0,10px' } },
                    }"
                  >
                    <div class="popper">
                      <div style="white-space: pre-line">{{ rowData['desc'] }}</div>
                    </div>

                    <span class="desc-info-icon" slot="reference"><i class="fas fa-info"></i></span>
                  </popper>
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <labeled-block :title="$i18n.t('Start')">
                <span v-html="renderData(rowData, getField(fields, 'leftDT'))"></span>
              </labeled-block>
            </div>
            <div class="col-3">
              <labeled-block :title="$i18n.t('Events')">
                {{ renderData(rowData, getField(fields, 'evCount')) }}
              </labeled-block>
            </div>
            <div class="col-3">
              <labeled-block :title="$i18n.t('Duration')">
                {{ len }}
              </labeled-block>
            </div>
            <div class="col-3">
              <labeled-block title=""> </labeled-block>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <labeled-block
                type="list"
                :title="$t('Types')"
                :empty="renderData(rowData, getField(fields, 'evTypes')) === 0"
              >
                <limited-list type="iconed" :items="renderList(rowData, getField(fields, 'evTypes'))"></limited-list>
              </labeled-block>
            </div>
            <div class="col-4">
              <labeled-block
                type="list"
                :title="$t('Devices')"
                :count="renderData(rowData, getField(fields, 'devCount'))"
                :empty="renderData(rowData, getField(fields, 'devCount')) === 0"
              >
                <limited-list
                  type="tagged"
                  :items="renderList(rowData, getField(fields, 'evNames'))"
                  @showHidden="showHiddenDevices"
                ></limited-list>
                <hidden-counter
                  :max-right="maxRight['devices']"
                  :items-hidden="hiddenElements['devices']"
                ></hidden-counter>
              </labeled-block>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <labeled-block :title="$i18n.t('Protocols')"> </labeled-block>
            </div>
            <div class="col-8">
              <labeled-block :title="$i18n.t('Tags')"> </labeled-block>
            </div>
          </div>
          <div
            v-if="!rowData.showDetails"
            class="cluster-corner cluster-corner--closed"
            @click="execAction(getField(fields, 'evCount').action, $event, rowData)"
          >
            <!--
            <div class="cluster-corner-before"></div>
            <div class="cluster-corner__icon">
              <img
                src="https://uploads-ssl.webflow.com/5f91d5a10b8e3e32addb2532/5f9a333e9f5077fcce7b90a2_show-events.svg"
                loading="lazy"
                alt=""
              />
            </div>
-->
            <span class="corner-label"><i class="el-icon-caret-bottom"></i>&nbsp;{{ $t('Show events') }}</span>
          </div>
          <div
            v-else
            class="cluster-corner cluster-corner--open"
            @click="execAction(getField(fields, 'evCount').action, $event, rowData)"
          >
            <!--
            <div class="cluster-corner-before"></div>
            <div class="cluster-corner__icon">
              <img
                src="https://uploads-ssl.webflow.com/5f91d5a10b8e3e32addb2532/5f9a333e89a12d70a5fb6716_hide-events.svg"
                loading="lazy"
                alt=""
              />
            </div>
-->
            <span class="corner-label"><i class="el-icon-caret-top"></i>&nbsp;{{ $t('Hide events') }}</span>
          </div>
        </div>
        <div v-if="rowData.showDetails" class="cluster-block__details">
          <div class="cluster-block__details-before"></div>
          <div class="cluster-block__details-content">
            <slot></slot>
          </div>
        </div>
        <div
          v-if="rowData.showDetails"
          class="cluster-corner"
          @click="execAction(getField(fields, 'evCount').action, $event, rowData)"
        >
          <!--
          <div class="cluster-corner-before"></div>
          <div class="cluster-corner__icon">
            <img
              src="https://uploads-ssl.webflow.com/5f91d5a10b8e3e32addb2532/5f9a333e89a12d70a5fb6716_hide-events.svg"
              loading="lazy"
              alt=""
            />
          </div>
-->
          <span class="corner-label"><i class="el-icon-caret-top"></i>&nbsp;{{ $t('Hide events') }}</span>
        </div>
      </div>
    </div>
  </div>

  <!--
  <div class="tl-row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col text-left" v-html="renderData(rowData, getField(fields, 'timeDT'))"></div>
            <div class="col text-left">
              <template v-if="gap"
                ><span :title="$t('Prior cluster interval')"
                  ><i class="fal fa-arrow-up" aria-hidden="true"></i>&nbsp;{{ gap }}</span
                ></template
              >
            </div>
            <div class="col text-left">
              <template v-if="len"
                ><span :title="$t('Cluster duration')"
                  ><i class="fal fa-arrows-v" aria-hidden="true"></i>&nbsp;{{ len }}</span
                ></template
              >
            </div>
            <div class="col text-right">
              <a class="text-info pointer" @click="execAction(getField(fields, 'nameDT').action, $event, rowData)">{{
                $t('Name') + ': ' + renderData(rowData, getField(fields, 'nameDT'))
              }}</a>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div
              class="col text-left"
              v-html="$t('Types') + ': ' + renderData(rowData, getField(fields, 'evTypes'))"
            ></div>
            <div
              v-if="renderData(rowData, getField(fields, 'devCount')) > 0"
              class="col text-left"
              v-html="
                $t('Devices') +
                ': ' +
                renderData(rowData, getField(fields, 'evNames')) +
                (renderData(rowData, getField(fields, 'devCount')) > 1
                  ? '&nbsp;<span class=\'badge badge-light bot\'>' +
                    renderData(rowData, getField(fields, 'devCount')) +
                    '</span>'
                  : '')
              "
            ></div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col text-left"></div>
            <div class="col text-right">
              <a class="text-info pointer" @click="execAction(getField(fields, 'evCount').action, $event, rowData)">{{
                $t('Events') + ': ' + renderData(rowData, getField(fields, 'evCount'))
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
--></template>

<script>
import LimitedList from './LimitedList';
import LabeledBlock from './LabeledBlock';
import HiddenCounter from './HiddenCounter';
import TIcon from './TIcon';
import Popper from 'vue-popperjs';
// import 'vue-popperjs/dist/vue-popper.css';
import './../../../assets/vue-popper-custom.css';

export default {
  name: 'timeline-element',
  components: {
    LimitedList,
    LabeledBlock,
    HiddenCounter,
    TIcon,
    popper: Popper,
  },
  props: {
    rowData: Object,
    fields: Array,
    className: Array,
    gap: String,
    len: String,
  },
  data: () => {
    return {
      hiddenElements: {
        types: [],
        devices: [],
      },
      maxRight: {
        types: 0,
        devices: 0,
      },
    };
  },
  methods: {
    goClusterProfile(c) {
      const queryString = this.$qs.stringify({
        cluster: c,
      });
      // window.location.href = '?p=cluster-events-profile' + (queryString ? '&' + queryString : '');
      this.$router.push({ name: 'clusters-profile', query: { cluster: c } });
    },
    showHiddenTypes(e) {
      this.hiddenElements['devices'] = e;
    },
    showHiddenDevices(e, w) {
      this.hiddenElements['devices'] = e;
      this.maxRight['devices'] = w;
    },
    getField(fields, name) {
      return fields.find((el) => el.name === name);
    },
    execAction(a, e, d) {
      console.log('execAction', a, e);
      if (a) {
        e.stopImmediatePropagation();
        this.$emit('colAction', a, e, d);
      }

      // if (a) {
      //   e.stopImmediatePropagation();
      //   this[a](e);
      // }
    },
    // showProfile(e) {
    //   e.stopImmediatePropagation();
    //   this.$emit('colAction', e);
    // },
    renderData(row, f) {
      if (f.render && typeof f.render === 'function') {
        return f.render(row[f.data], row, row);
      } else {
        return row[f.data];
      }
    },
    renderList(row, f) {
      if (f.renderList && typeof f.renderList === 'function') {
        return f.renderList(row[f.data], row, row);
      } else {
        return row[f.data];
      }
    },
  },
};
</script>

<style scoped>
/*
.popper {
  background: #1757bd;
  color: white;
  font-weight: bold;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 4px;
  border-color: #1757bd;
}
*/
</style>

<style>
/*
.popper[x-placement^='top'] .popper__arrow {
  border-width: 5px 5px 0 5px;
  border-color: #13499e transparent transparent transparent;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
*/
</style>

<style>
.badge.bot {
  vertical-align: text-bottom;
}
</style>

<style scoped>
.data-table.stripe tbody tr.odd,
.data-table.display tbody tr.odd {
  background-color: #fbfbfb;
}
.data-table.display tbody td {
  border-top: 1px solid #ddd;
}
.data-table.display tbody tr:hover:not(.child) {
  background-color: #f6f6f6;
}
.data-table.display tbody tr.selected:hover:not(.child) {
  background-color: #aab7d1;
}
.data-table tbody td {
  padding: 8px 10px;
}
.data-table tbody .high-row td {
  padding: 22px 10px;
}
.data-table tbody td.show-gap {
  position: relative;
}
.data-table tbody td.show-gap::before {
  content: attr(data-gap);
  width: 130px;
  height: 3px;
  line-height: 3px;
  color: #007bff;
  text-align: center;
  font-size: 16px;
  background-color: white;
  position: absolute;
  left: 50%;
  margin-left: -65px;
  margin-top: -2px;
  top: 0%;
}
.data-table tbody td.pointer {
  cursor: pointer;
}
a.pointer {
  cursor: pointer;
}
</style>
